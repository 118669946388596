import React from 'react';
import { useSelector } from 'react-redux';
import theme from '../../../utilities/theme';
import {
  StyledContentContainer,
  StyledFormContainer,
} from '../../styled/Containers';
import Link from '@material-ui/core/Link';

function Submit() {
  const data = useSelector((store) => store.brand);
  const brandInfo = useSelector((store) => store.brandInfo.brandInfo);
  const brandEmail = brandInfo.config.hardshipEmail;
  const brandTel =
    typeof brandInfo.config.complaintsPhone === 'string'
      ? brandInfo.config.complaintsPhone
      : '';

  return (
    <StyledContentContainer color={theme(data.brand).background_color}>
      <StyledFormContainer>
        <h4>
          You have already submitted your online hardship application for{' '}
          {brandInfo.name}.
        </h4>
        <h4>
          If your circumstances have changed or if you wish to enquire about
          your application, please email us at{' '}
          <a href={`mailto:${brandEmail}`}>{brandEmail}</a> or call us on{' '}
          <a href={`tel:${brandTel.trim().split(' ').join('')}`}>{brandTel}</a>{' '}
          between 8:30am and 7:00pm (AEST) Monday to Friday.
        </h4>
        <h4>
          <Link href="/faq" style={{ textDecoration: 'underline' }}>
            Review the FAQs for information about what will happen next.
          </Link>
        </h4>
      </StyledFormContainer>
    </StyledContentContainer>
  );
}

export default Submit;
