import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_EXPENSES_DATA} from '../../../../state/ducks/user/types';
import {MARITAL_STATUS} from '../constants';
import {numericRegex, setFieldValue} from '../utils';
import {commaFormatted} from '../../../../utilities';
import PriceInput from '../../FormElements/PriceInput';
import Label from '../../FormElements/Label';
import SelectBox from '../../FormElements/SelectBox';
import TextInput from '../../FormElements/TextInput';
import FormGroupButtons from '../../FormGroupButtons';
import {hasStringValue} from "../../../utils";
import ConditionalPriceInput from "../../FormElements/ConditionalPriceInput";
import {useFormGroupsStyles} from "../styles";
import {initialState} from "../../../../state/ducks/user/reducers";

function FG_Expenses(props) {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({...userData.expenses});
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch();
    const classes = useFormGroupsStyles();

    useEffect(() => {
        const payload = {};
        // Only keep expenses that exist in initial state
        Object.keys(initialState.expenses).forEach(key => {
            payload[key] = userData.expenses[key] != null ? userData.expenses[key] : initialState.expenses[key];
        });
        userData.expenses = payload;
    }, [0])

    useEffect(() => {
        setValues({...userData.expenses});
    }, [userData]);

    /**
     * Calculate total amount
     */

    useEffect(() => {
        let expensesUtilities = [
            'food_groceries',
            'home_utilities_communication',
            'personal_pet_care',
            'insurance_excluding_personal_insurance',
            'vehicles_and_transport',

            'education',
            'recreation_travel_entertainment',
            'land_tax_body_corporate_strata',
            'investment_additional_property',
            'private_schooling_child_spousal_support_payments',
            'all_other_expenses',
        ];
        let totalCalculation = ['0'];

        expensesUtilities.forEach((item, index) => {
            if(values[item] && !isNaN(values[item])) {
                totalCalculation.push(values[item]);
            }
        });

        const sum = totalCalculation.reduce(
            (result, number) => Number(result) + Number(number)
        );

        setTotal(commaFormatted(sum));
    }, [values]);

    /**
     * Form fields handlers
     */

    const setNumber = (key, value) => {
        if (numericRegex.test(value)) {
            setValue(key, value);
        }
    }
    const setValue = (key, value) => {
        // default dispatch
        setFieldValue(SET_EXPENSES_DATA, dispatch, key, value);
    };

    /**
     * Validation
     */

    const validateFormGroup = () => {
        let isValid = !!values.marital_status && !!MARITAL_STATUS.find(item => item.id === values.marital_status);

        if (isValid) {
            isValid = hasStringValue(values.number_of_dependents) &&
                hasStringValue(values.food_groceries) && Number(values.food_groceries) > 50 &&
                hasStringValue(values.home_utilities_communication) && Number(values.home_utilities_communication) > 30 &&
                hasStringValue(values.personal_pet_care) && Number(values.personal_pet_care) > 30 &&
                hasStringValue(values.insurance_excluding_personal_insurance) && Number(values.insurance_excluding_personal_insurance) > 50 &&
                hasStringValue(values.vehicles_and_transport) && Number(values.vehicles_and_transport) > 50;
        }

        Object.keys(values).forEach(el => {
            if(isValid && el.endsWith('_checked')){
                const field = el.substr(0, el.indexOf('_checked'))
                isValid = values[el] && !!values[field] || !values[el];
            }
        })

        return isValid;
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };
    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    /**
     * View
     */

    return (
        <>
            <SelectBox
                title="Marital Status "
                id="marital_status"
                items={MARITAL_STATUS}
                value={values.marital_status}
                setValue={setValue}
                required={!values.marital_status}
            />

            <TextInput
                noFormTag={true}
                id="number_of_dependents"
                title="Number of dependants"
                value={values.number_of_dependents}
                type={"number"}
                setValue={setNumber}
                required={!values.number_of_dependents}
            />

            <h5>
                Please provide details of your typical monthly expenses. The more detail
                and accuracy you can provide the easier it will be for us to assess your
                application.
            </h5>

            <PriceInput
                noFormTag={true}
                id="food_groceries"
                title="Food & Groceries"
                type="currency"
                value={values.food_groceries}
                setValue={setValue}
                greaterThanValue="50"
                errorMessage="Value should be greater than $ 50"
                required={!values.food_groceries}
            />

            <PriceInput
                noFormTag={true}
                id="home_utilities_communication"
                title="Home, utilities, communication (includes, Council Rates, Electricity, Gas, Water, Phone and Internet, Subscriptions and Streaming Services)"
                type="currency"
                value={values.home_utilities_communication}
                greaterThanValue="30"
                errorMessage="Value should be greater than $ 30"
                setValue={setValue}
                required={!values.home_utilities_communication}
                longLabel={true}
            />

            <PriceInput
                noFormTag={true}
                id="personal_pet_care"
                title="Personal and Pet care (Buy Now, Pay Later,  Clothing, Footwear and Basic Health Care)"
                type="currency"
                value={values.personal_pet_care}
                setValue={setValue}
                greaterThanValue="30"
                errorMessage="Value should be greater than $ 30"
                required={!values.personal_pet_care}
                longLabel={true}
            />

            <PriceInput
                noFormTag={true}
                id="insurance_excluding_personal_insurance"
                title="Insurance Excluding Personal Insurance (CTP, Vehicle Insurance, Home & Contents, Private/Health Insurance)"
                type="currency"
                value={values.insurance_excluding_personal_insurance}
                setValue={setValue}
                greaterThanValue="50"
                errorMessage="Value should be greater than $ 50"
                required={!values.insurance_excluding_personal_insurance}
                longLabel={true}
            />

            <PriceInput
                noFormTag={true}
                id="vehicles_and_transport"
                title="Vehicles and Transport (Fuel, Rego, Tolls, Maintenance and Public Transport)"
                type="currency"
                value={values.vehicles_and_transport}
                setValue={setValue}
                greaterThanValue="50"
                errorMessage="Value should be greater than $ 50"
                required={!values.vehicles_and_transport}
                longLabel={true}
            />

            <h5>
                Please provide details of the following monthly expenses. You can disable any expenses which are not applicable to you.
            </h5>

            <ConditionalPriceInput setValue={setValue} switchId={'education_checked'}
                                   switchValue={values.education_checked} inputId={'education'}
                                   inputTitle={'Education (Childcare, Nannies, Preschool, Public Primary and Secondary Education Costs)'}
                                   inputValue={values.education}
                                   required={values.education_checked}
                                   longLabel={true}
            />

            <ConditionalPriceInput setValue={setValue} switchId={'recreation_travel_entertainment_checked'}
                                   switchValue={values.recreation_travel_entertainment_checked} inputId={'recreation_travel_entertainment'}
                                   inputTitle={'Recreation, travel and entertainment (Dining Out, Takeaway, Coffee, Alcohol, Domestic Travel, Sports, Tobacco and Gambling)'}
                                   inputValue={values.recreation_travel_entertainment}
                                   required={values.recreation_travel_entertainment_checked}
                                   longLabel={true}
            />

            <ConditionalPriceInput setValue={setValue} switchId={'land_tax_body_corporate_strata_checked'}
                                   switchValue={values.land_tax_body_corporate_strata_checked} inputId={'land_tax_body_corporate_strata'}
                                   inputTitle={'Land Tax, Body Corporate & Strata'}
                                   inputValue={values.land_tax_body_corporate_strata}
                                   required={values.land_tax_body_corporate_strata_checked}
            />

            <ConditionalPriceInput setValue={setValue} switchId={'investment_additional_property_checked'}
                                   switchValue={values.investment_additional_property_checked} inputId={'investment_additional_property'}
                                   inputTitle={'Investment/Additional Property (Utilities, Property Management Fees, Body Corporate/Strata/Community Title Fees, Land Tax, Council Rates, Insurance)'}
                                   inputValue={values.investment_additional_property}
                                   required={values.investment_additional_property_checked}
                                   longLabel={true}
            />

            <ConditionalPriceInput setValue={setValue} switchId={'private_schooling_child_spousal_support_payments_checked'}
                                   switchValue={values.private_schooling_child_spousal_support_payments_checked} inputId={'private_schooling_child_spousal_support_payments'}
                                   inputTitle={'Private Schooling and Child / Spousal Support Payments'}
                                   inputValue={values.private_schooling_child_spousal_support_payments}
                                   required={values.private_schooling_child_spousal_support_payments_checked}
                                   longLabel={true}
            />

            <ConditionalPriceInput setValue={setValue} switchId={'all_other_expenses_checked'}
                                   switchValue={values.all_other_expenses_checked} inputId={'all_other_expenses'}
                                   inputTitle={'All other expenses not listed above'}
                                   inputValue={values.all_other_expenses}
                                   required={values.all_other_expenses_checked}
            />

            {total !== 0 ? (
                <Label title={`Total Amount - $${total}`} textAlign="right"/>
            ) : null}

            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
}

export default FG_Expenses;
