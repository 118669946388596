import React from 'react';
import {MORTGAGE_TYPE} from '../constants';
import {useFormGroupsStyles} from '../styles';
import {StyledButtonGroup} from '../../../styled/Containers';
import TextInput from '../../FormElements/TextInput';
import PriceInput from '../../FormElements/PriceInput';
import SelectBox from '../../FormElements/SelectBox';
import YearInput from '../../FormElements/YearInput';
import Button from '@material-ui/core/Button';

function PropertyType(props) {
    const {componentData, index, setItemValue, deleteBlock} = props;
    const classes = useFormGroupsStyles();

    const setValue = (key, value) => {
        setItemValue(index, key, value);
    };

    const deleteValue = () => {
        deleteBlock();
    };

    return (
        <>

            <SelectBox
                title="Mortgage Type"
                id="mortgage_type"
                items={MORTGAGE_TYPE}
                value={componentData.mortgage_type}
                setValue={setValue}
                required={!componentData.mortgage_type}
            />

            <TextInput
                noFormTag={true}
                title="Institution"
                id="institution"
                setValue={setValue}
                value={componentData.institution}
                required={!componentData.institution}
            />

            <YearInput
                noFormTag={true}
                title="Year Account Opened"
                id="year_account_opened"
                setValue={setValue}
                minYears={50}
                value={componentData.year_account_opened}
                required={!componentData.year_account_opened}
            />

            <PriceInput
                noFormTag={true}
                id="outstanding_balance"
                title="Outstanding Balance"
                type="currency"
                value={componentData.outstanding_balance}
                setValue={setValue}
                required={!componentData.outstanding_balance}
            />

            <PriceInput
                noFormTag={true}
                id="monthly_repayment"
                title="Monthly Repayment"
                type="currency"
                value={componentData.monthly_repayment}
                setValue={setValue}
                required={!componentData.monthly_repayment}
            />

            {index !== 0 ? (
                <StyledButtonGroup style={{marginTop:0, marginBottom:'1rem'}}>
                    <div className="flex_left">
                        <Button
                            variant="contained"
                            className="back"
                            color="secondary"
                            onClick={deleteValue}
                            id="deletePropertyBlock"
                        >
                            Delete
                        </Button>
                    </div>
                </StyledButtonGroup>
            ) : null}
        </>
    );
}

export default PropertyType;
