import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {isValid} from "date-fns";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '10px',
  },
  root: {
    width: '100%',
  },
  textField: {
    width: 200,
  },
}));

const MIN_DATE_MSG = 'Date should not be before the current date (Today)';
const MAX_DATE_MSG = 'Please enter a valid date';
const ID = 'date_picker';

export default function DatePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const classes = useStyles();
  const noFormTag = !!props.noFormTag;
  const instanceId = !!props.testAutomationId
    ? `${ID}-${props.testAutomationId}`
    : ID;

  useEffect(() => {
    setSelectedDate(props.value);
  }, [props.value]);

  /**
   * handlers
   */

  const handleDateChange = async (date) => {
    await setSelectedDate(date);
    props.setValue(props.id, date);
  };

  /**
   * View
   */

  const datePickerContent = (
    <KeyboardDatePicker
      initialFocusedDate={props.initialFocusedDate}
      className={classes.root}
      id={instanceId}
      label={props.title}
      format="dd/MM/yyyy"
      margin="normal"
      openTo={props.openTo}
      minDateMessage={MIN_DATE_MSG}
      maxDateMessage={MAX_DATE_MSG}
      disablePast={props.type === 'future'}
      disableFuture={props.type === 'past'}
      value={selectedDate}
      onChange={handleDateChange}
      views={["year", "month", "date"]}
      KeyboardButtonProps={{
        'aria-label': 'change date',
        'data-testid':instanceId
      }}
    />
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        {noFormTag ? (
          <div className={classes.container}>{datePickerContent}</div>
        ) : (
          <form className={classes.container} noValidate>
            {datePickerContent}
          </form>
        )}

        {props.required && (
            <FormHelperText id="standard-helper-text" data-testid={props.id+"-standard-helper-text"}>*Required</FormHelperText>
        )}
        {props.validate && !props.validate[Object.keys(props.validate)[0]] && (
          <FormHelperText id="standard-helper-text" data-testid={props.id+"-standard-helper-text"}>*Required</FormHelperText>
        )}
      </>
    </MuiPickersUtilsProvider>
  );
}
