import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import theme from '../../../utilities/theme';
import {
  FAQ,
  FAQheader,
  StyledButtonGroup,
  StyledFAQ,
  StyledFAQBannerContainer,
  StyledFAQBannerContent,
  StyledFAQContainer,
  StyledFAQContentContainer,
  StyledFAQBg,
} from '../../styled/Containers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontFamily: 'Toyota Type Bold',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function FAQs() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const brand = useSelector((store) => store.brand.brand);
  const brandInfo = useSelector((store) => store.brandInfo.brandInfo);
  const {
    config: { complaintsPhone },
  } = brandInfo;

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <StyledFAQContentContainer
      color={theme(brand).background_color}
      theme={brand}
    >
      <StyledFAQBannerContainer
        color={theme(brand).background_color}
        theme={brand}
      >
        <StyledFAQBannerContent theme={brand}>
          <StyledFAQ theme={brand}>
            <FAQ theme={brand}>
              <h1>FAQs</h1>
            </FAQ>
          </StyledFAQ>
        </StyledFAQBannerContent>
      </StyledFAQBannerContainer>
      <StyledFAQBg>
        <StyledFAQContainer theme={brand}>
          <h4>
            By completing your hardship application online we can quickly and
            easily review your application and get you a decision faster
          </h4>
          <FAQheader theme={brand} />
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  What is financial hardship assistance?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  To support customers during unexpected, short-term changes to
                  their circumstances, they can apply for a change to their loan
                  agreement with us to help them get back on track. If you
                  apply, this change does not affect your credit rating but may
                  extend the term of your loan and affect the interest that you
                  pay. Refer to the FAQ “What types of assistance can I apply
                  for?” for more information about the common types of support
                  offered.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  How do I apply?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  By entering your email address and clicking submit we will
                  send you an email with a unique code (
                  <b className="bold-text">Note:</b> you may need to check your
                  junk folder). You need this code to be able to access the
                  application form. If you don’t finish the form, you can
                  generate a new code which will return you to your incomplete
                  form. The unique code will only work once and will expire
                  after one hour.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>
                  How long will the application take?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  The online application typically takes 20 minutes to complete,
                  depending on your circumstances and the availability of
                  documentation that you need to provide with your application.
                  You can return to your form at a later date if you need more
                  time, however we encourage you to complete the application as
                  quickly as possible.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>
                  What information is needed in my application?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  We need to identify you and understand your circumstances in
                  order to consider making a change to your loan with us.
                  <ul>
                    <li>
                      You will need to provide either your Account number(s) or
                      vehicle registration number(s) so have them ready.
                    </li>
                    <li>
                      We will ask you for information about who you are so that
                      we can identify you and your loan.
                    </li>
                    <li>
                      We need to know why you are applying and what help you
                      would like
                    </li>
                    <li>
                      We also need to understand your current financial
                      situation (including your income, expenses, assets &
                      liabilities), so you will be asked to provide your monthly
                      income before and after your situation changed. You may
                      then need to provide proof of your current income.
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.heading}>
                  When will I get a decision?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  Once you submit your completed application with all required
                  documents, our hardship team will review your application on a
                  first come first served basis. You should have an answer
                  within 14 days, however, this time will vary during times of
                  increased application volumes.
                  <br />
                  The hardship team may contact you if they require further
                  information. You will have 28 days to provide the required
                  documents. If we don’t hear back from you then your
                  application will be declined. You can apply again if you still
                  need to.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className={classes.heading}>
                  What happens if my application is approved or declined?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If your application is approved, then your loan will be varied
                  based on the agreed proposal and we will notify you before we
                  make the change to your loan contract. If your application is
                  declined, then we will let you know that your application has
                  been declined and what options are available to you.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.heading}>
                  What sort of documents might I need to provide?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If you are required to provide documentation, it may be for
                  one of two reasons:
                  <ol>
                    <li>
                      To verify the reason that you are applying for hardship.
                      This might include a medical certificate if you have been
                      affected medically or information from your employer if
                      you have been affected financially (this could include
                      payslips or a letter from your employer).
                    </li>
                    <li>
                      If you are required to provide income information, we will
                      require documents to verify the income you had entered.
                      This might include payslips, bank statements, a letter
                      from your employer or if you are self-employed, a tax
                      return.
                    </li>
                  </ol>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography className={classes.heading}>
                  What income documents might be required to support my hardship
                  application?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  There are six document categories we might need from you
                  depending on your circumstances.
                  <ol>
                    <li>
                      <b>Payslip or Employer Letter:</b> Provide payslips to
                      show your income now that your circumstances have changed.
                      Or a letter from your employer showing that your
                      employment has ceased or there has been a change to your
                      income.
                    </li>
                    <li>
                      <b>Bank Statements:</b> Provide up to 3 months of bank
                      statements to show that your income has reduced. The bank
                      statements need to be official statements from an
                      Australian financial institution or Bank and show all
                      relevant sources of income.
                    </li>
                    <li>
                      <b>Income Tax Assessment:</b> If you are self-employed or
                      a company, and your Income Tax Assessment covers your
                      circumstances after they changed then you can provide an
                      Individual or Company Income Tax Assessment or audited
                      financial statements.
                    </li>
                    <li>
                      <b>Notice of Assessment:</b> If you are self-employed or a
                      company, and your Notice of Assessment covers your
                      circumstances after they changed then you can provide a
                      Notice of Assessment.
                    </li>
                    <li>
                      <b>Business Activity Statement:</b> If you are
                      self-employed or a company and registered for GST, then
                      you can provide two or more consecutive statements to show
                      that your income has changed.
                    </li>
                    <li>
                      <b>Centrelink Statement:</b> If you receive government
                      benefits then you can provide an official statement to
                      confirm the amount you receive.
                    </li>
                  </ol>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel9'}
              onChange={handleChange('panel9')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography className={classes.heading}>
                  I have a payment due soon, what should I do?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If you can afford to, then you should attempt to pay all or
                  some of the payment. However once you have submitted your
                  application online, you can delay payment until a decision has
                  been made about your application for hardship. If you pay by
                  direct debit and you wish to cancel or suspend your direct
                  debit arrangement, then you can log on to the customer portal
                  and make the change under “Payment Details” or call the
                  customer service centre on {complaintsPhone}.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel10'}
              onChange={handleChange('panel10')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <Typography className={classes.heading}>
                  What if I have more than one loan?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If you have more than one loan with us, then you only need to
                  apply once. If you are applying for hardship on all your
                  loans, then you can provide the other Contract Numbers or
                  registration numbers for those loan in the one application.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel11'}
              onChange={handleChange('panel11')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <Typography className={classes.heading}>
                  What types of assistance can I apply for?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  You can apply for the following types of assistance:
                  <ul>
                    <li>
                      <b>Postpone repayments:</b> You can select to postpone
                      your payments for a certain number of months. For the
                      duration of the postponement you will not be required to
                      make any payments. Any interest will be capitalised (added
                      to the balance of your loan) and paid off over the new
                      term of the loan, this means that you will be paying more
                      interest over the term of your loan than expected on your
                      original loan.
                    </li>
                    <li>
                      <b>Reduce repayments: </b>Similar to postpone payments,
                      you can select a number of months to pay a reduced
                      repayment. If you can still afford to pay something
                      towards your loan then you can tell us how much you can
                      afford and we will change your contractual repayment for
                      the agreed period. Any additional interest will be
                      capitalised (added to the balance of your loan) and paid
                      off over the new term of the loan, this means that you
                      will be paying more interest over the term of your loan
                      than expected on your original loan.
                    </li>
                    <li>
                      <b>Assist in the sale of vehicle: </b>If you no longer
                      want to keep your vehicle, we can help you sell it. Our
                      team of specialists will explain the sale options
                      available to you and then work with you to sell the
                      vehicle. However, it is important to note that if there is
                      money still owing after the vehicle has been sold and the
                      funds used to payout the loan, then you will be liable for
                      the shortfall and we can discuss payment options with you.
                    </li>
                    <li>
                      <b>
                        Continue with normal repayments but assist with missed
                        payments:
                      </b>
                      If you select this option then we will come to an
                      arrangement with you to extend the term of your loan to
                      pay the amounts that have been missed. This means it will
                      take longer to pay off your loan and you will pay more
                      interest than expected on your original loan.
                    </li>
                    <li>
                      <b>Other: </b>If none of these options suite you, you can
                      choose to tell us another way we can help.
                    </li>
                  </ul>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel12'}
              onChange={handleChange('panel12')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
              >
                <Typography className={classes.heading}>
                  I have a Guaranteed Future Value, what will happen if I
                  receive financial hardship assistance?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If your loan includes a Guaranteed Future Value (GFV) and you
                  receive financial hardship assistance, then you will be able
                  to retain the benefits of your GFV.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel13'}
              onChange={handleChange('panel13')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel13bh-content"
                id="panel13bh-header"
              >
                <Typography className={classes.heading}>
                  I have finance insurance products relating to my contract,
                  what happens to the policy?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  Depending on your circumstances you may be eligible to make a
                  claim on certain insurance products to help you make your
                  repayments. You should check the type of insurance and your
                  policy before applying for financial hardship assistance.
                  <br />
                  If you have an insurance product with us directly and you do
                  receive financial hardship assistance, then your policy will
                  continue to cover you until the original end date of your loan
                  contract but it will not cover you for any extension to the
                  term of your loan with us.
                  <br />
                  If you have a policy with another insurer and you do receive
                  financial hardship assistance, then you may need to contact
                  them and let them know that your loan with us has changed.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel14'}
              onChange={handleChange('panel14')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel14bh-content"
                id="panel14bh-header"
              >
                <Typography className={classes.heading}>
                  What if I need support for longer?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  If your application is approved and at the end of the support
                  period your circumstances still mean it is not possible to
                  resume your repayments, you can apply for hardship again.
                  However, you will be required to provide more information and
                  as this support is intended for short periods, the duration of
                  your support may mean you are not eligible for further
                  support.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel15'}
              onChange={handleChange('panel15')}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel15bh-content"
                id="panel15bh-header"
              >
                <Typography className={classes.heading}>
                  What about my privacy and the information I provide in my
                  application?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="sub_faq">
                  We understand that your personal information and credit
                  information is important to you and we value your trust. Our
                  Privacy Policy sets out how we will manage and protect your
                  information. You can access a copy of our Privacy Policy&nbsp;
                  <a href={brandInfo.config.privacyPolicyUrl}>here</a>.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <StyledButtonGroup
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              className="back"
              color="secondary"
              onClick={goBack}
            >
              Back
            </Button>
          </StyledButtonGroup>
        </StyledFAQContainer>
      </StyledFAQBg>
    </StyledFAQContentContainer>
  );
}

export default FAQs;
