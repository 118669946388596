export const MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE';
export const PAYSLIP_BORROWER_1 = 'PAYSLIP_BORROWER_1';
export const BANK_STATEMENT_BORROWER_1 = 'BANK_STATEMENT_BORROWER_1';
export const INCOME_TAX_ASSESSMENT_BORROWER_1 = 'INCOME_TAX_ASSESSMENT_BORROWER_1';
export const NOTICE_OF_ASSESSMENT_BORROWER_1 = 'NOTICE_OF_ASSESSMENT_BORROWER_1';
export const BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_1 = 'BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_1';
export const CENTRELINK_STATEMENT_BORROWER_1 = 'CENTRELINK_STATEMENT_BORROWER_1';
export const OTHER_INCOME_STATEMENT_BORROWER_1 = 'OTHER_INCOME_STATEMENT_BORROWER_1';
export const OTHER_DOCUMENTS_BORROWER_1 = 'OTHER_DOCUMENTS_BORROWER_1';
export const PAYSLIP_BORROWER_2 = 'PAYSLIP_BORROWER_2';
export const BANK_STATEMENT_BORROWER_2 = 'BANK_STATEMENT_BORROWER_2';
export const INCOME_TAX_ASSESSMENT_BORROWER_2 = 'INCOME_TAX_ASSESSMENT_BORROWER_2';
export const NOTICE_OF_ASSESSMENT_BORROWER_2 = 'NOTICE_OF_ASSESSMENT_BORROWER_2';
export const BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_2 = 'BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_2';
export const CENTRELINK_STATEMENT_BORROWER_2 = 'CENTRELINK_STATEMENT_BORROWER_2';
export const OTHER_INCOME_STATEMENT_BORROWER_2 = 'OTHER_INCOME_STATEMENT_BORROWER_2';
export const OTHER_DOCUMENTS_BORROWER_2 = 'OTHER_DOCUMENTS_BORROWER_2';
