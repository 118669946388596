import _ from 'lodash';
import {some} from 'lodash/fp';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_INCOME_DATA} from '../../../../state/ducks/user/types';
import {hasStringValue} from '../../../utils';
import {EMPLOYMENT_EMPLOYED_OPTIONS, EMPLOYMENT_NOT_EMPLOYED_OPTIONS, EMPLOYMENT_OPTIONS,} from '../constants';
import FormGroupButtons from '../../FormGroupButtons';
import divisions from '../../../utils/divisions';
import SelectBox from '../../FormElements/SelectBox';
import TextInput from '../../FormElements/TextInput';
import CodeInput from '../../FormElements/CodeInput';
import AutoComplete from '../../AutoComplete';
import {foundSubDivision} from "../utils";
import Incomes from "./incomes";
import {makeStyles} from "@material-ui/core/styles";
import DivisionAndSubdivision from "../../FormElements/DivisionAndSubdivision";
import {initialState} from "../../../../state/ducks/user/reducers";

const FG_NewIncomeFirst = (props) => {
    const userData = useSelector((store) => store.user);

    const [divisionMenu, setDivisionMenu] = React.useState([]);
    const [subDivisionMenu, setSubDivisionMenu] = React.useState([]);
    const [values, setValues] = React.useState({...userData.income});

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        padding: {
            paddingTop: '10px',
            paddingBottom: '10px'
        }
    }));

    const inputClasses = useStyles();

    useEffect(() => {
        const copy = JSON.parse(JSON.stringify(userData))
        const payload = initialState.income
        _.merge(payload, userData.income);

        userData.income = payload;
        userData.background_hardship.medical_certification_file = copy.background_hardship.medical_certification_file;
        userData.income.other_documents = copy.income.other_documents;
        userData.income.centrelink_statement = copy.income.centrelink_statement;
        userData.income.income_tax_assessment = copy.income.income_tax_assessment;
        userData.income.notice_assessment = copy.income.notice_assessment;
        userData.income.payslip_file = copy.income.payslip_file;
        userData.income.business_activity_assessment = copy.income.business_activity_assessment;
        userData.income.bank_statement = copy.income.bank_statement;
        userData.income.other_income_statement = copy.income.other_income_statement;
    }, [0])

    useEffect(() => {
        setValues({...userData.income});
    }, [userData.income]);

    useEffect(() => {
        let temp = [];
        divisions.forEach((item) => {
            temp.push({title: item.title, id: item.id});
        });
        setDivisionMenu(_.sortBy(temp, 'title'));

        if (values.division) {
            const found = divisions.find(item => item.id === values.division.id);
            if (!found) {
                dispatch({
                    type: SET_INCOME_DATA,
                    payload: {
                        division: '',
                        sub_division: ''
                    }
                });
            } else {
                divisions.forEach((item) => {
                    if (item.id === values.division.id) {
                        setSubDivisionMenu(_.sortBy(item.children, 'title'));
                    }
                });
            }
        } else {
            setSubDivisionMenu([]);
        }
    }, [values.division]);

    /**
     * Form fields handlers
     */

    const setValue = (key, value) => {
        // base data to dispatch
        const data = {
            [key]: value,
        };

        // when 'employment_type' changes
        if (key === 'employment_type') {
            data.employment_status = '';
            data.employer_abn = '';
            data.division = '';
            data.sub_division = '';
            data.recent_primary_employer_name = '';
        }


        // when 'division' is changed
        if (key === 'division') {
            // reset sub division value and validation
            data.sub_division = '';
        }

        dispatch({
            type: SET_INCOME_DATA,
            payload: data,
        });
    };

    const getDivisionTitle = () => {
        if (values.employment_type === 'employed') {
            return <h5>Select the industry division that best describes your most recent Employer's main business
                activity:</h5>
        } else {
            return <h5>Select the industry division that best describes your main business activity:</h5>
        }
    }

    const getDivisionSubTitle = () => {
        if (values.employment_type === 'employed') {
            return <h5>Select the industry subdivision that best describes your most recent Employer's main business
                activity:</h5>;
        } else {
            return <h5>Select the industry subdivision that best describes your main business activity:</h5>;
        }
    };

    /**
     * Validation
     */

    const isOtherIncomePairValidationStatus = (text, price) => {
        const hasText = hasStringValue(text);
        const hasPrice = hasStringValue(price);
        return (hasText && hasPrice) || (!hasText && !hasPrice);
    };

    const isAllCurrencyValidationStatus = () =>
        some((value) => value)([
            values.after_tax_salary,
            values.pension,
            values.family_allowance,
            values.newstart_allowance,
            values.partners_income,
            values.child_support,
            values.other_government_benefits,
            values.other_incom_specify_type_first_price,
            values.other_incom_specify_type_second_price,
        ]);

    const validateFormGroup = () => {
        let isValid = hasStringValue(values.employment_type) && !!EMPLOYMENT_OPTIONS.find(item => item.id === values.employment_type);

        if (isValid) {
            if (values.employment_type === 'employed') {
                isValid = hasStringValue(values.employment_status) && !!EMPLOYMENT_EMPLOYED_OPTIONS.find(item => item.id === values.employment_status);
            } else if (values.employment_type === 'not_employed') {
                isValid = hasStringValue(values.employment_status) && !!EMPLOYMENT_NOT_EMPLOYED_OPTIONS.find(item => item.id === values.employment_status);
            }
        }

        if (isValid && values.employment_type !== 'not_employed') {
            isValid = hasStringValue(values.recent_primary_employer_name);
            if (isValid) {
                isValid = !!values.division && !!values.sub_division;
            }
        }

        if (isValid) {
            isValid = hasStringValue(values.after_tax_income) && isAllCurrencyValidationStatus() && isOtherIncomePairValidationStatus(values.other_incom_specify_type_first_txt, values.other_incom_specify_type_first_price) && isOtherIncomePairValidationStatus(values.other_incom_specify_type_second_txt, values.other_incom_specify_type_second_price)
        }

        return isValid
    };


    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };

    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    /**
     * View
     */

    return (
        <>
            <h4>
                What was your employment status? (before your circumstances changed)
            </h4>
            <SelectBox
                items={EMPLOYMENT_OPTIONS}
                title="Employment type"
                setValue={setValue}
                id="employment_type"
                testAutomationId="income1-employment_type"
                value={values.employment_type}
                required={!EMPLOYMENT_OPTIONS.find(item => item.id === values.employment_type)}
            />
            {values.employment_type === 'employed' && (
                <SelectBox
                    items={EMPLOYMENT_EMPLOYED_OPTIONS}
                    title="Employment Status"
                    setValue={setValue}
                    id="employment_status"
                    testAutomationId="income1-employment_status-employed"
                    value={values.employment_status}
                    required={!EMPLOYMENT_EMPLOYED_OPTIONS.find(item => item.id === values.employment_status)}
                />
            )}
                {values.employment_type === 'not_employed' && (
                    <SelectBox
                        items={EMPLOYMENT_NOT_EMPLOYED_OPTIONS}
                        title="Employment Status"
                        setValue={setValue}
                        id="employment_status"
                        testAutomationId="income1-employment_status-not_employed"
                        value={values.employment_status}
                        required={!EMPLOYMENT_NOT_EMPLOYED_OPTIONS.find(item => item.id === values.employment_status)}
                    />
                )}

            {(values.employment_type === 'employed' || values.employment_type === 'self_employed') &&
            <>
                {values.employment_type === 'employed' ? <h5>Please enter your most recent Employer's Name</h5> :
                    <h5>Please enter your business name</h5>}
                <TextInput
                    noFormTag={true}
                    title="Enter Business Name*"
                    id="recent_primary_employer_name"
                    setValue={setValue}
                    type={"text"}
                    value={values.recent_primary_employer_name}
                    required={!values.recent_primary_employer_name}
                />

                {values.employment_type === 'employed' ?
                    <h5>If you know it, please enter your most recent Employer's ABN</h5> :
                    <h5>If you know it, please enter your ABN</h5>}
                <CodeInput
                    fields={11}
                    id="employer_abn"
                    setValue={setValue}
                    value={values.employer_abn}
                />


                <DivisionAndSubdivision divisionTitle={getDivisionTitle()}
                                        subDivisionTitle={getDivisionSubTitle()}
                                        divisions={divisionMenu}
                                        subDivisions={subDivisionMenu}
                                        division={values.division}
                                        subDivision={values.sub_division}
                                        setValue={setValue}
                                        divisionReqFn={!values.division || !divisions.find(item => item.id === values.division.id)}
                                        subDivisionReqFn={!foundSubDivision(values)}/>


            </>
            }

            <h5>
                Please provide your monthly income after taxes, from all sources before
                your situation changed.
            </h5>
            <Incomes income={values} setValue={setValue}/>

            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
};

export default FG_NewIncomeFirst;
