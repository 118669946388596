import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Forms } from '../../../api';
import { FORM_ID_STORAGE_KEY } from '../../../utilities';
import { getFormGroup, resetFormGroupView } from './utils';
import BackdropSpinner from "../BackdropSpinner/BackdropSpinner";

const INITIAL_FORM_GROUP_DATA = {
  groupId: '',
  component: null,
  metadata: null,
  history: null,
};

const isFirstFormGroupFromHistory = (groupHistory) => {
  let result;
  if (!Array.isArray(groupHistory)) {
    result = false;
  } else {
    result = groupHistory.length < 2;
  }
  return result;
};

const FormGroupControl = ({ setFormHistory, theme }) => {

  const [hasClickedSubmit,setHasClickedSubmit] = useState(false);
  const [canSaveData,setCanSaveData] = useState(true);
  const [isNext,setIsNext] = useState({
    next:null
  });

  const userData = useSelector((store) => store.user);
  const formId = localStorage.getItem(FORM_ID_STORAGE_KEY) || userData.id;
  const [formGroupData, setFormGroupData] = useState(INITIAL_FORM_GROUP_DATA);

  const CurrentFormGroupComponent = formGroupData.component;
  const shouldDisplayFormGroup = CurrentFormGroupComponent !== null;
  const isFirstFormGroup = isFirstFormGroupFromHistory(formGroupData.history);

  const history = useHistory();

  /**
   * loadFormGroup
   * @param {boolean|null} isNext - if requested group is for next step, null requests current group
   */
  function loadFormGroup(isNext = null) {
    const getFormGroupPromise = getFormGroup(formId, isNext);

    getFormGroupPromise
      .then((formGroupData) => {
        const data = _.merge({}, formGroupData);
        setFormHistory(data.history || []);
        setFormGroupData(data);
      })
      .catch(() => {
        setFormHistory([]);
      })
      .finally(() => {
        resetFormGroupView();
        setCanSaveData(true);
      });
  }

  // form control handlers
  const prev = () => {
    setIsNext({
      next:false
    });
    setCanSaveData(false);
  };

  const next = () => {
    setIsNext({
      next:true
    });
    setCanSaveData(false);
  };

  const submit = () => {
    //we only want to submit the form once to do this we use a boolean state value
    //that gets changed on the clicking of the submit button;
    //once clicked the useEffect will be called;
    localStorage.setItem("submitted", "true")
    setHasClickedSubmit(true);
    setCanSaveData(false);
  };

  // load initial group to start with
  useEffect(() => {

    //if not submitted load the formGroup
    if(!hasClickedSubmit) {
      if(isNext && isNext.next !== null){
        Forms.update(userData.id, userData).then(() => {
          loadFormGroup(isNext.next);
        });
      }else {
        loadFormGroup(isNext.next);
      }
    }
  }, [hasClickedSubmit, isNext]);

  //submit the form
  useEffect(() => {
    if(hasClickedSubmit){
      //submit the form
      Forms.submit(userData.id).then(() => {
        localStorage.setItem('@application_form', 'true');
        history.push('/submit');
      }).catch((error) => {
        console.log(error);
        setHasClickedSubmit(false)
      }).finally(() => {
        resetFormGroupView();
        setCanSaveData(true);
      });
    }

  },[hasClickedSubmit])

  return (
    shouldDisplayFormGroup && (
      <>
        <BackdropSpinner canSaveData={canSaveData} />
        <form noValidate autoComplete="off" id="form">
          <CurrentFormGroupComponent
            groupId={formGroupData.groupId}
            metadata={formGroupData.metadata}
            isFirst={isFirstFormGroup}
            theme={theme}
            prev={prev}
            next={next}
            submit={submit}
            hasClickedSubmit={hasClickedSubmit}
            canSaveData={canSaveData}
            setCanSaveData={setCanSaveData}
          />
        </form>
      </>
    )
  );
};

export default FormGroupControl;
