import {withStyles} from "@material-ui/core/styles";
import {Backdrop, CircularProgress} from "@material-ui/core";
import React from "react";

const BackdropZIndex = withStyles({
    root: {
        zIndex: 999
    }
})(Backdrop);

export default function BackdropSpinner({canSaveData}) {
    return (
        <BackdropZIndex open={!canSaveData} invisible={true}>
            <CircularProgress color="inherit" />
        </BackdropZIndex>
    )
}