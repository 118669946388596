import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_BEGIN_DATA} from '../../../../state/ducks/user/types';
import {
    contractNumberRegex,
    contractNumbersRegex,
    nameRegex,
    numericPhoneNumberRegex,
    numericRegex,
    phoneNumberRegex,
    registrationNumberRegex,
    registrationNumbersRegex,
    setFieldValue
} from '../utils';
import {useFormGroupsStyles} from '../styles';
import DatePicker from '../../FormElements/DatePicker';
import TextInput from '../../FormElements/TextInput';
import Loan from './loan';
import FormGroupButtons from '../../FormGroupButtons';
import YesNo from "../../FormElements/YesNo";

function FG_AboutYou(props) {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({...userData.begin});

    const dispatch = useDispatch();
    const classes = useFormGroupsStyles();

    useEffect(() => {
        //clear legacy data
        userData.background_hardship.isRelatedCovid = "false";

        localStorage.setItem('second_borrower', values.isSecondBorrower);
        setValues({...userData.begin});

    }, [userData]);


    /**
     * Form fields handlers
     */
    const handleRadioChange = (event) => {
        dispatch({
            type: SET_BEGIN_DATA,
            payload: {
                isContractNumber: event.target.value,
                registrationNumber: '',
                contractNumber: ''
            },
        });
    };

    const handleIsAllChange = (event) => {
        dispatch({
            type: SET_BEGIN_DATA,
            payload: {
                isGotAll: event.target.value,
                contractNumbers: '',
                registrationNumbers: ''
            },
        });
    };

    const handleSecondChange = (event) => {
        dispatch({
            type: SET_BEGIN_DATA,
            payload: {
                isSecondBorrower: event.target.value,
                secondUserName: '',
                secondAddress: '',
                secondPhoneNumber: '',
                secondDOB: null
            },
        });

        localStorage.setItem('second_borrower', event.target.value);
    };

    const setContractNumber = (key, value) => {
        if (value.length < 9 && numericRegex.test(value)) {
            setValue(key, value);
        }
    }

    const setRegistrationNumber = (key, value) => {
        if (value.length < 8) {
            setValue(key, value);
        }
    }

    const setName = (key, value) => {
        if (nameRegex.test(value) || value.length === 0) {
            setValue(key, value);
        }
    }

    const setPhoneNumber = (key, value) => {
        if (phoneNumberRegex.test(value)) {
            setValue(key, value)
        } else if (value.length === 0 || numericPhoneNumberRegex.test(value)) {
            setValue(key, value)
        }
    }

    const setValue = (key, value) => {
        setFieldValue(SET_BEGIN_DATA, dispatch, key, value);
    };

    /**
     * Validation
     */

    const validateContractNumber = () => {
        return values.contractNumber && contractNumberRegex.test(values.contractNumber)
    }

    const validateRegistrationNumber = () => {
        return values.registrationNumber && registrationNumberRegex.test(values.registrationNumber);
    }

    const validateContractNumbers = () => {
        if (values.registrationNumbers && registrationNumbersRegex.test(values.registrationNumbers) && !values.contractNumbers) {
            return true;
        } else {
            return contractNumbersRegex.test(values.contractNumbers);
        }
    }

    const validateRegistrationNumbers = () => {
        if (values.contractNumbers && contractNumbersRegex.test(values.contractNumbers) && !values.registrationNumbers) {
            return true;
        } else {
            return registrationNumbersRegex.test(values.registrationNumbers);
        }
    }

    const validatePhoneNumber = (phoneNumber) => {
        return phoneNumberRegex.test(phoneNumber);
    }

    const validateFormGroup = () => {
        let isValid = !!values.isContractNumber;

        if (isValid) {
            if (values.contractNumber) {
                isValid = validateContractNumber();
            } else {
                isValid = validateRegistrationNumber();
            }
        }

        if (isValid) {
            isValid = !!values.isGotAll
        }

        if (isValid && values.isGotAll === 'true') {
            if (!values.contractNumbers && !values.registrationNumbers) {
                isValid = false;
            } else {
                if (values.contractNumbers) {
                    isValid = validateContractNumbers();
                }
                if (isValid && values.registrationNumbers) {
                    isValid = validateRegistrationNumbers();
                }
            }
        }

        const startOfDay = new Date();
        startOfDay.setHours(0);
        startOfDay.setMinutes(0);
        startOfDay.setSeconds(0);
        startOfDay.setMilliseconds(0);
        if (isValid) {
            isValid = !!values.userName && !!values.address && validatePhoneNumber(values.phoneNumber) && !!values.address && !!values.DOB && new Date(values.DOB) <= startOfDay;
        }

        if (isValid) {
            isValid = !!values.isSecondBorrower;
        }

        if (isValid && values.isSecondBorrower === 'true') {
            isValid = !!values.secondUserName && !!values.secondAddress && validatePhoneNumber(values.secondPhoneNumber) && !!values.secondDOB && new Date(values.secondDOB) <= startOfDay;
        }

        if (isValid) {
            isValid = !!userData.loan.isLoanCommercial;
        }

        if (isValid && userData.loan.isLoanCommercial === 'true') {
            isValid = !!userData.loan.company_name && !!userData.loan.isCompanyRegistered;
        }

        if (isValid && userData.loan.isCompanyRegistered === 'true') {
            isValid = !!userData.loan.division && !!userData.loan.sub_division
        }

        return isValid;
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };
    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    /**
     * View
     */

    return (
        <>
            <YesNo title={<h4>To get started, do you know your contract number?</h4>} id={"isContractNumber"}
                   value={values.isContractNumber} onChange={handleRadioChange} yesComponent={<>
                <TextInput
                    classes={classes.noPadding}
                    noFormTag={true}
                    title="Enter your contract number*"
                    value={values.contractNumber}
                    setValue={setContractNumber}
                    type={"text"}
                    id="contractNumber"
                    required={!values.contractNumber}
                />
                {!validateContractNumber() && (
                    <h6>
                        Your contract number should be 8 numbers starting from either the
                        numbers 11, 12, 13, 14
                    </h6>
                )}
            </>} noComponent={<>
                <TextInput
                    classes={classes.noPadding}
                    noFormTag={true}
                    title="Enter your vehicle’s registration number*"
                    value={values.registrationNumber}
                    setValue={setRegistrationNumber}
                    type={"text"}
                    id="registrationNumber"
                    required={!values.registrationNumber}
                />
                {!validateRegistrationNumber() && (
                    <h6>Vehicle registration numbers are a maximum of 7 characters</h6>
                )}
            </>}/>

            <YesNo title={<h5>
                If you have more than one loan with us, are you applying for hardship on
                all loans?
            </h5>} id={"isGotAll"} value={values.isGotAll} onChange={handleIsAllChange} yesComponent={<>
                <h5>
                    Please add all your contract / registration number(s) separated
                    by commas
                </h5>
                <TextInput
                    classes={classes.noPadding}
                    noFormTag={true}
                    title={
                        !!values.registerNumbers
                            ? 'Enter contract numbers'
                            : 'Enter contract numbers*'
                    }
                    value={values.contractNumbers}
                    setValue={setValue}
                    id="contractNumbers"
                    required={!values.registrationNumbers && !values.contractNumbers}
                />
                {!validateContractNumbers() && (
                    <h6 data-testid={"contractNumbers-standard-helper-text"}>
                        Contract numbers should be 8 numbers starting from either
                        the numbers 11, 12, 13, 14 and separated by a comma
                    </h6>
                )}

                <TextInput
                    noFormTag={true}
                    title={
                        !!values.contractNumbers
                            ? 'Enter your registration numbers'
                            : 'Enter your registration numbers*'
                    }
                    value={values.registrationNumbers}
                    setValue={setValue}
                    type={"text"}
                    id="registrationNumbers"
                    required={!values.registrationNumbers && !values.contractNumbers}
                />
                {!validateRegistrationNumbers() && (
                    <h6 data-testid={"registrationNumbers-standard-helper-text"}>
                        Registration number should be up to 7 characters and
                        separated by a comma
                    </h6>
                )}
            </>}/>

            <h5>Your personal and contact details</h5>
            <TextInput
                noFormTag={true}
                title="Name (Given name & Surname)*"
                value={values.userName}
                setValue={setName}
                type={"text"}
                id="userName"
                required={!values.userName}
            />
            <TextInput
                noFormTag={true}
                title="Enter your Residential Address*"
                value={values.address}
                setValue={setValue}
                type={"text"}
                id="address"
                required={!values.address}
            />
            <TextInput
                noFormTag={true}
                title="Enter your Phone Number*"
                value={values.phoneNumber}
                setValue={setPhoneNumber}
                type={"tel"}
                id="phoneNumber"
                required={!values.phoneNumber}
            />
            {!validatePhoneNumber(values.phoneNumber) && (
                <h6>
                    Phone number should be Australian starting with 61 or 0. Landlines
                    need an area code. E.g. 0123456789 or 61123456789
                </h6>
            )}
            <DatePicker
                noFormTag={true}
                title="Enter your Date of Birth"
                value={values.DOB}
                setValue={setValue}
                id="DOB"
                openTo="year"
                type="past"
                initialFocusedDate={"01/01/1970"}
                testAutomationId="about_you-dob"
                required={!values.DOB}
            />

            <YesNo title={<h5>Does your loan involve a second borrower/director/guarantor?</h5>}
                   id={"isSecondBorrower"} value={values.isSecondBorrower} onChange={handleSecondChange}
                   yesComponent={<>
                       <h5>Please enter their details below</h5>
                       <TextInput
                           noFormTag={true}
                           title="Name (Given Name & Surname)"
                           value={values.secondUserName}
                           setValue={setName}
                           type={"text"}
                           id="secondUserName"
                           required={!values.secondUserName}
                       />
                       <TextInput
                           noFormTag={true}
                           title="Enter their Residential Address*"
                           value={values.secondAddress}
                           setValue={setValue}
                           type={"text"}
                           id="secondAddress"
                           required={!values.secondAddress}
                       />
                       <TextInput
                           noFormTag={true}
                           title="Enter their Phone Number*"
                           value={values.secondPhoneNumber}
                           setValue={setPhoneNumber}
                           type={"tel"}
                           id="secondPhoneNumber"
                           required={!values.secondPhoneNumber}
                       />
                       {!validatePhoneNumber(values.secondPhoneNumber) && (
                           <h6>
                               Phone number should be Australian starting with 61 or 0.
                               Landlines need an area code. E.g. 0123456789 or 61123456789
                           </h6>
                       )}
                       <DatePicker
                           noFormTag={true}
                           title="Enter their Date of Birth"
                           value={values.secondDOB}
                           setValue={setValue}
                           id="secondDOB"
                           type="past"
                           openTo="year"
                           initialFocusedDate={"01/01/1970"}
                           testAutomationId="about_you-second_dob"
                           required={!values.secondDOB}
                       />
                   </>}
            />

            <Loan/>
            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
}

export default FG_AboutYou;
