import decode from 'jwt-decode';
import * as types from './types';
import createReducer from '../../utils/createReducer';

import {
  getUserType,
  AUTHORISATION_TOKEN_STORAGE_KEY,
} from '../../../utilities';

export const initialState = {
  hydrating: true,
  email: '',
  formSection: '/',
  isContractNumber: '',
  isRegisterNumber: null,
  contractNumber: null,
  registerNumber: null,
  covid: null,
  begin: {
    userName: '',
    phoneNumber: '',
    address: '',
    isContractNumber: '',
    isGotAll: '',
    contractNumber: '',
    contractNumbers: '',
    registrationNumber: '',
    registrationNumbers: '',
    DOB: null,
    secondUserName: '',
    secondPhoneNumber: '',
    secondAddress: '',
    secondEmail: '',
    secondDOB: null,
    isSecondBorrower: '',
  },
  loan: {
    isLoanCommercial: '',
    ABN: '',
    company_name: '',
    isCompanyRegistered: '',
    division: '',
    sub_division: '',
  },
  background_hardship: {
    covid: null,
    isRelatedCovid: 'none',
    why_apply_hardship: '',
    why_apply_hardship_natural_disaster:'',
    applying_hardship_info: '',
    briefly_describe: '',
    eligible_for_complensation: '',
    how_much: '',
    when_anticipate_compensation: null,
    when_difficulties_date: null,
    when_resume_contractual_repayment: null,
    what_address_situation: '',
    what_expect_monthly_salary: '',
    isPayment_Protection_Insurance: '',
    logged_clam: '',
    when_logged_calm: null,
    indicate_reasonable_assistance: '',
    howlong_postpone: '',
    howlong_reduce_selection: '',
    howlong_reduce: '',
    howmuch_able_to_pay: '',
    background_hardship_others: '',
    acknowledge_interest_fees: 'false',
    acknowledge_assist_in_vehicle_sale: '',
    what_you_doing_to_address_your_situation: '',
    circumstances_changed: '',
    why_have_you_not_made_a_claim: '',
    medical_certification_file: {}
  },
  income: {
    employment_type: '',
    employment_status: '',
    recent_primary_employer_name: '',
    employer_abn: '',
    division: null,
    sub_division: null,
    after_tax_income: '',
    after_tax_salary: '',
    pension: '',
    family_allowance: '',
    newstart_allowance: '',
    partners_income: '',
    child_support: '',
    other_government_benefits: '',
    other_incom_specify_type_first_txt: '',
    other_incom_specify_type_first_price: '',
    other_incom_specify_type_second_txt: '',
    other_incom_specify_type_second_price: '',
    payslip_file: {},
    bank_statement: {},
    income_tax_assessment: {},
    notice_assessment: {},
    business_activity_assessment: {},
    centrelink_statement: {},
    other_income_statement: {},
    other_documents:[]
  },
  incomeSecond: {
    after_tax_income: '',
    after_tax_salary: '',
    pension: '',
    family_allowance: '',
    newstart_allowance: '',
    partners_income: '',
    child_support: '',
    other_government_benefits: '',
    other_incom_specify_type_first_txt: '',
    other_incom_specify_type_first_price: '',
    other_incom_specify_type_second_txt: '',
    other_incom_specify_type_second_price: '',
    payslip_file: {},
    bank_statement: {},
    income_tax_assessment: {},
    notice_assessment: {},
    business_activity_assessment: {},
    centrelink_statement: {},
    other_income_statement: {},
    other_documents:[]
  },
  expenses: {
    marital_status: '',
    number_of_dependents: '',

    food_groceries: '',
    home_utilities_communication: '',
    personal_pet_care: '',
    insurance_excluding_personal_insurance: '',
    vehicles_and_transport: '',

    education: '',
    education_checked: true,
    recreation_travel_entertainment: '',
    recreation_travel_entertainment_checked: true,
    land_tax_body_corporate_strata: '',
    land_tax_body_corporate_strata_checked: true,
    investment_additional_property: '',
    investment_additional_property_checked: true,
    private_schooling_child_spousal_support_payments: '',
    private_schooling_child_spousal_support_payments_checked: true,
    all_other_expenses: '',
    all_other_expenses_checked: true,
  },
  assets: {
    haveProperties: '',
    haveMotorVehicles: '',
    property_data: [
      {
        property_type: '',
        property_address: '',
        estimated_property_value: '',
      },
    ],
    vehicle_data: [
      {
        year: '',
        estimated_vehicle_value: '',
      },
    ],
    superannuation: '',
    savings: '',
    household_furniture: '',
    shares: '',
    all_other: '',
  },
  liabilities: {
    haveMortgages:'',
    havePersonalLoans: '',
    haveCreditCards: '',
    monthly_rent_board: '',
    total_other_liabilities: '',
    mortgages_data: [
      {
        mortgage_type: '',
        institution: '',
        year_account_opened: null,
        outstanding_balance: '',
        monthly_repayment: '',
      },
    ],
    personal_loans_data: [
      {
        is_secured_loan: '',
        institution: '',
        year_account_opened: null,
        outstanding_balance: '',
        monthly_repayment: '',
      },
    ],
    credit_cards_data: [
      {
        institution: '',
        year_account_opened: null,
        credit_card_limit: '',
        outstanding_balance: '',
        monthly_repayment: '',
      },
    ]
  },
};

const signInReducer = createReducer(initialState)({

  [types.SIGN_IN]: (state, { formData }) => {
    const { email, userId, brand: providerId } = decode(formData.token);

    return {
      ...initialState,
      ...formData,
      email,
      userId,
      providerId,
    };
  },
  [types.SET_EMAIL]: (state, { payload }) => {
    localStorage.removeItem(AUTHORISATION_TOKEN_STORAGE_KEY);
    return {
      ...state,
      email: payload,
      token: undefined,
    };
  },
  [types.SET_BEGIN_DATA]: (state, { payload }) => {
    return {
      ...state,
      begin: {
        ...state.begin,
        ...payload,
      },
    };
  },
  [types.SET_EXPENSES_DATA]: (state, { payload }) => {
    return {
      ...state,
      expenses: {
        ...state.expenses,
        ...payload,
      },
    };
  },
  [types.SET_LIABILITIES_DATA]: (state, { payload }) => {
    return {
      ...state,
      liabilities: {
        ...state.liabilities,
        ...payload,
      },
    };
  },
  [types.SET_ASSETS_DATA]: (state, { payload }) => {
    return {
      ...state,
      assets: {
        ...state.assets,
        ...payload,
      },
    };
  },
  [types.SET_BACKGROUND_HARDSHIP_DATA]: (state, { payload }) => {
    return {
      ...state,
      background_hardship: {
        ...state.background_hardship,
        ...payload,
      },
    };
  },
  [types.SET_INCOME_DATA]: (state, { payload }) => {
    return {
      ...state,
      income: {
        ...state.income,
        ...payload,
      },
    };
  },
  [types.SET_LOAN_DATA]: (state, { payload }) => {
    return {
      ...state,
      loan: {
        ...state.loan,
        ...payload,
      },
    };
  },
  [types.SET_INCOME_SECOND_DATA]: (state, { payload }) => {
    return {
      ...state,
      incomeSecond: {
        ...state.incomeSecond,
        ...payload,
      },
    };
  },
  [types.SUBMIT_FORM]: (state) => {
    return {
      ...state,
      submitted: true,
    };
  },
});

export default signInReducer;
