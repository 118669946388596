import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
  },
  labelRight: {
    width: '100%',
    textAlign: 'right',
  },
  labelLeft: {
    width: '100%',
    textAlign: 'left',
  },
}));

function Label(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.root}>
        <InputLabel
          className={
            props.textAlign === 'right' ? classes.labelRight : classes.labelLeft
          }
        >
          {props.title}
        </InputLabel>
      </FormControl>
    </div>
  );
}

export default Label;
