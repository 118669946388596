import React from 'react';
import Button from '@material-ui/core/Button';
import {StyledButtonGroup} from '../../styled/Containers';
import {useSelector} from "react-redux";

const FormGroupButtons = ({ isFirst, onPrev, onNext, onSubmit, hasClickedSubmit, canSaveData, isValid }) => {
  const brand = useSelector((store) => store.brand.brand);
  const hasSubmit = typeof onSubmit === 'function'; // applicable for preview group only
  const hasPrev = !isFirst && typeof onPrev === 'function';
  const hasNext = !hasSubmit && typeof onNext === 'function'; // always assume has next group

  const ButtonGroup = () => {
    //create a button array
    let buttons = [];

    //add buttons to the array if the conditions are met
    if(hasPrev){
      buttons.push(
          <Button
              key={"back"}
              variant="contained"
              className="back"
              color="secondary"
              onClick={onPrev}
              id="back"
              disabled={!canSaveData}>
            Back
          </Button>
      )
    } else {
      buttons.push(<div></div>)
    }

    if(hasNext){
      buttons.push(<Button
          key={"next"}
          variant="contained"
          color="secondary"
          className="next"
          onClick={onNext}
          id="next"
          disabled={!(canSaveData && isValid())}
      >
        Next
      </Button>)
    }

    if(hasSubmit && !hasClickedSubmit){
      buttons.push(<Button
          key={"submit-disabled"}
          variant="contained"
          color="secondary"
          className="preview"
          onClick={onSubmit}
          id="submit"
      >
        SUBMIT
      </Button>)
    } else if(hasSubmit && hasClickedSubmit){
      buttons.push(<Button
          key={"submit"}
          variant="contained"
          color="secondary"
          className="preview"
          disabled={true}
          id="submit"
      >
        SUBMIT
      </Button>)
    }

    return (
        buttons
    )
  };

  return (
    <StyledButtonGroup>
      <ButtonGroup/>
    </StyledButtonGroup>
  );
};

export default FormGroupButtons;
