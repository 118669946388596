import {StyledButtonGroup} from "../../../styled/Containers";
import Button from "@material-ui/core/Button";
import React from "react";
import {useFormGroupsStyles} from "../styles";
import YesNo from "../../FormElements/YesNo";

const Liabilities = ({haveItems: haveItems, items:items, question:question, item:Item, id:id, handleRadioChangeEvent: handleRadioChangeEvent,
                       delete:deleteBlock,
                       add: addBlock,
                       defaultItem: defaultItem,
                       setItem:setItem,
                       setItemValue:setItemValue,
                       addTitle: addTitle}) => {
    const classes = useFormGroupsStyles();

    return (
        <YesNo title={<h5>{question}</h5>} id={id} value={haveItems} onChange={handleRadioChangeEvent} yesComponent={
            <>
                {
                    items.map((item, i) =>
                        <Item
                        key={id+'_'+i}
                        itemKey={i}
                        componentData={item}
                        index={i}
                        setItemValue={setItemValue}
                        deleteBlock={() => deleteBlock(items, i, setItem)}
                    />)
                }
                <StyledButtonGroup style={{marginTop:0, marginBottom:'1rem'}}>
                    <div className="flex_left">
                        <Button
                            variant="contained"
                            className="next"
                            color="primary"
                            onClick={() => addBlock(items, defaultItem, setItem)}
                            id="newBlock"
                        >
                            {addTitle}
                        </Button>
                    </div>
                </StyledButtonGroup>
            </>
        }/>
    )
};

export default Liabilities;