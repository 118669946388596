// Roles
export const S_READ = 'user';
export const S_STAFF = 'staff';
export const S_ADMIN = 'admin';

// Brands
export const B_TOYOTA_FINANCE = 'toyota-finance';
export const B_LEXUS_FINANCIAL_SERVICES = 'lexus-financial-services';
export const B_HINO_FINANCIAL_SERVICES = 'hino-financial-services';
export const B_POWERTORQUE_FINANCE = 'powertorque-finance';
export const B_POWER_ALLIANCE_FINANCE = 'power-alliance-finance';
export const B_MAZDA_FINANCE = 'mazda-finance';
export const B_SUZUKI_FINANCIAL_SERVICES = 'suzuki-financial-services'

// LS keys
export const FORM_PERSIST_KEY = 'user';
export const FORM_ID_STORAGE_KEY = 'formId';
export const AUTHORISATION_TOKEN_STORAGE_KEY = 'token';

// Endpoints
export const API_URL = process.env.REACT_APP_API_URL;

export const THROTTLE_TIME = process.env.REACT_APP_THROTTLE_TIME;

/**
 * Determines the brand (AKA provider-id) based off the hostname
 *
 * Each brand has three different hostnames depending on the environment
 * <BRANDNAME>-hardship.verifier.me = LIVE Environment
 * <BRANDNAME>.stgreprise-hardship.verifier.me = STAGING Environment
 * <BRANDNAME>.devreprise-hardship.verifier.me = DEVELOPMENT Environment
 *
 * Note: You can test themes locally by swapping constant value below
 *
 * @param providerHostname hostname of website
 * @returns brand as string
 */
export const brandFromHost = (providerHostname) => {
  const providerNameSplit = providerHostname.split(/[-.]/);

  switch (providerNameSplit[0]) {
    case 'lexusfinancialservices':
      return B_LEXUS_FINANCIAL_SERVICES;

    case 'hinofinancialservices':
      return B_HINO_FINANCIAL_SERVICES;

    case 'powertorquefinance':
      return B_POWERTORQUE_FINANCE;

    case 'mazdafinance':
      return B_MAZDA_FINANCE;

    case 'poweralliancefinance':
      return B_POWER_ALLIANCE_FINANCE;

    case 'toyotafinance':
      return B_TOYOTA_FINANCE;

    case 'suzukifinancialservices':
      return B_SUZUKI_FINANCIAL_SERVICES

    case 'novus':
      return B_SUZUKI_FINANCIAL_SERVICES

    default:
      return B_TOYOTA_FINANCE;
  }
};
