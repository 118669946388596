import React from 'react';
import { useSelector } from 'react-redux';
import theme from '../../../utilities/theme';
import Link from '@material-ui/core/Link';
import {
  StyledContentContainer,
  StyledFormContainer,
} from '../../styled/Containers';

function Submit() {
  const data = useSelector((store) => store.brand);
  const brandInfo = useSelector((store) => store.brandInfo.brandInfo);

  return (
    <StyledContentContainer color={theme(data.brand).background_color}>
      <StyledFormContainer>
        <h4>Thank you for submitting your application for hardship. We will
          review your application as quickly as possible.</h4>
        <h5
          style={{
            fontSize: '24px',
          }}
        >
          Important information
        </h5>
        <h4>
          • {brandInfo.name} will assess your application for hardship, and may
          agree to change your loan contract.
        </h4>
        <h4>
          • It may take up to <b className="bold-text">21 days</b> for&nbsp;
          {brandInfo.name} to respond to your hardship request. {brandInfo.name}
          &nbsp;may contact you to request further information if necessary.
        </h4>
        <h4>
          <Link href="/faq" style={{ marginTop: '20px' }}>
            • <u>Review the FAQs for information about what will happen next</u>
          </Link>
        </h4>
        <h4>
            • If you pay by direct debt and wish to cancel or suspend your direct debit arrangement,
            then you can log on to the customer portal and make the change under “Payment Details”
            or call the customer service centre on  {brandInfo.config.complaintsPhone}.
        </h4>
      </StyledFormContainer>
    </StyledContentContainer>
  );
}

export default Submit;
