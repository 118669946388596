export const Q_ABOUT = {
  isContractNumber: {
    title: 'Do you know your contract number?',
  },
  contractNumber: { title: 'Contract Number:' },
  registrationNumber: { title: 'Registration Number:' },
  isGotAll: {
    title:
      'If you have more than one loan with us, are you applying for hardship on all loans:',
  },
  contractNumbers: { title: 'Other Contract Number(s):' },
  registrationNumbers: { title: 'Other Registration Number(s):' },
  userName: {
    title: 'Name (Given Name & Surname):',
  },
  address: {
    title: 'Your Address:',
  },
  phoneNumber: { title: 'Phone Number:' },
  DOB: { title: 'Date of birth:' },
  isSecondBorrower: {
    title: 'Does your loan involve a second borrower/director/guarantor?',
  },
  secondUserName: { title: 'Their name (Given Name & Surname):' },
  secondAddress: { title: 'Their Address:' },
  secondEmail: { title: 'Email address:' },
  secondPhoneNumber: { title: 'Their Phone number:' },
  secondDOB: { title: 'Date of birth:' },
  isLoanCommercial: { title: 'Is your loan a commercial loan?' },
  ABN: { title: 'ABN:' },
  company_name: { title: 'Company or Business Name:' },
  isCompanyRegistered: {
    title: 'Is the company or business still registered and trading?',
  },
  division: { title: 'Which industry are you in?' },
  sub_division: { title: 'Which industry sub division are you in?' },
  isRelatedCovid: { title: 'Is your application related to COVID-19?' },
  applying_hardship_info: {
    title:
      'Please provide information around why you are applying for hardship:',
    have_personally_medically_diagnosed_covid_19:
      'Illness',
    have_dependent_medically_diagnosed_covid_19:
      'A dependent or person close to me has been medically diagnosed with COVID-19',
    employement_income_has_cased_codiv_19:
      'My employment/income has ceased due to COVID-19 (including due to carer responsibilities)',
    employement_income_has_reduced_codiv_19:
      'My employment/income has reduced due to COVID-19 (including due to carer responsibilities)',
  },
  medical_certification_file: { title: 'Medical Certificate' },
  payslip_file: { title: 'Payslip' },
  termination_letter_file: { title: 'Termination Letter' },
  bank_Statements_file: { title: 'Bank Statements' },
  letter_from_account: { title: 'Letter from account' },
  letter_from_employer: { title: 'Letter from employer' },
  when_difficulties_date: {
    title: 'Approximately what date did these difficulties start?',
  },
  when_resume_contractual_repayment: {
    title:
      'What date do you expect to be able to resume your full contractual repayments?',
  },
  isPayment_Protection_Insurance: {
    title:
      'Do you have Finance Protection Insurance, Payment Protection Insurance or Consumer Credit Insurance for your loan contract(s)?',
  },
  postpone_repayments: { title: 'Postpone repayments' },
  howlong_postpone: {
    title: 'How long would you like to postpone your repayments?',
  },
  reduce_repayments: { title: 'Reduce repayments' },
  howlong_reduce_selection: {
    title: 'How long (in months) do you need to pay a reduced repayment?',
  },
  when_logged_calm: { title: 'When was the claim lodged?' },
  continue_normal_repayments: {
    title: 'Continue with normal repayments but assist with missed payments',
  },
  howmuch_able_to_pay: { title: 'How much can you afford to pay monthly?' },
  background_hardship_other: { title: 'Other' },
  background_hardship_others: {
    title: 'Please tell us what assistance you would like',
  },
  why_apply_hardship: { title: 'Why are you applying for hardship?' },
  why_have_you_not_made_a_claim: { title: 'Why have you not made a claim?' },
  why_select_option: {
    accident: 'Accident',
    death: 'Death',
    illness: 'Illness',
    injury: 'Injury',
    marital_breakdown : 'Marital Breakdown',
    natural_disaster: 'Natural Disaster',
    over_commitment: 'Over Commitment',
    pregnancy: 'Pregnancy',
    unemployed: 'Unemployed',
    unforeseen_business_issues: 'Unforeseen Business Issues',
    reduction_of_income: 'Reduction of income',
    covid_19: 'COVID-19',
  },

  why_select_natural_disaster_options: {
    natural_disaster_flood: 'Flood',
    natural_disaster_bushfire: 'Bushfire',
    natural_disaster_cyclone: 'Cyclone',
    natural_disaster_storm: 'Storm',
    natural_disaster_earthquake: 'Earthquake',
    natural_disaster_drought: 'Drought'
  }
};


export const Q_HARDSHIP = {
  logged_clam: {
    title: 'Have you lodged a claim?',
  },
  briefly_describe: {
    title:
      'Please provide information around why you are applying for hardship. Briefly Describe your circumstances:',
  },
  acknowledge_interest_fees: {
    title:
      'Acknowledgement of interest and fees will continue to accrue on your loan if you are granted Hardship assistance:',
  },
  acknowledge_assist_in_vehicle_sale: {
    title:
      'Acknowledged that you have read, understand, and agree to obtain assistance with the sale of your vehicle and that you will be liable for any shortfall that may result following the sale of your vehicle. ',
  },
  what_you_doing_to_address_your_situation: {
    title: 'What are you doing or able to do to address your situation?',
  },
  eligible_for_complensation: {
    title:
      'If your situation is as a result of an accident, are you eligible for compensation?',
  },
  how_much: {
    title: 'How much?',
  },
  when_anticipate_compensation: {
    title: 'When do you anticipate compensation will be paid?',
  },
  circumstances_changed: {
    title: 'Have your circumstances changed?'
  }
};

export const Q_INCOME = {
  employment_type: { title: 'What was your employment type?' },
  employed: { title: 'Employed' },
  self_employed: { title: 'Self-Employed' },
  not_employed: { title: 'Not Employed' },
  employment_status: { title: 'What was your employment status?' },
  permanent_full_time: { title: 'Permanent Full-time' },
  permanent_part_time: { title: 'Permanent Part-time' },
  casual: { title: 'Casual' },
  seasonal: { title: 'Seasonal' },
  student: { title: 'Student' },
  unemployed: { title: 'Unemployed' },
  retired: { title: 'Retired' },
  recent_primary_employer_name: {
    title: "What is your current or most recent primary employer's name?",
  },
  employer_abn: {
    title: "What is your current or most recent primary employer's ABN?",
  },
  division: {
    title:
      'What is the industry division that best describes the main business activity of your current or most recent primary employer',
  },
  sub_division: {
    title:
      'What is the industry subdivision that best describes the main business activity your current or most recent primary employer',
  },
  after_tax_income: {
    title:
      'Your monthly income after taxes, from all sources before your situation changed:',
  },
  after_tax_salary: {
    title: 'After tax salary per month (including JobKeeper payments):',
  },
  pension: { title: 'Pension:' },
  family_allowance: { title: 'Family allowance:' },
  newstart_allowance: {
    title: 'Newstart allowance (including JobSeeker payments):',
  },
  partners_income: { title: `Partner's income:` },
  child_support: { title: 'Child Support:' },
  other_government_benefits: { title: 'Other government benefits:' },
  other_incom_specify_type_first_txt: { title: 'Other Income Source:' },
  other_incom_specify_type_first_price: { title: 'Amount:' },
  other_incom_specify_type_second_txt: { title: 'Other Income Source:' },
  other_incom_specify_type_second_price: { title: 'Amount:' },
  payslip: { title: 'Payslip' },
  bank_statement: { title: 'Bank statements' },
  itr: { title: 'ITR' },
  noa: { title: 'NOA' },
  bas: { title: 'BAS' },
  centrelink: { title: 'Centrelink' },
  other_incom: { title: 'Other income statement' },
  address: { title: '' },
};

export const Q_INCOME_2 = {
  after_tax_income: {
    title:
      ' monthly income after taxes, from all sources before your situation changed:',
  },
  after_tax_salary: {
    title: 'After tax salary per month (including JobKeeper payments:',
  },
  pension: { title: 'Pension:' },
  family_allowance: { title: 'Family allowance:' },
  newstart_allowance: {
    title: 'Newstart allowance (including JobSeeker payments):',
  },
  partners_income: { title: `Partner's income:` },
  child_support: { title: 'Child Support:' },
  other_government_benefits: { title: 'Other government benefits:' },
  other_incom_specify_type_first_txt: { title: 'Other Income Source:' },
  other_incom_specify_type_first_price: { title: 'Amount:' },
  other_incom_specify_type_second_txt: { title: 'Other Income Source:' },
  other_incom_specify_type_second_price: { title: 'Amount:' },
  payslip: { title: 'Payslip' },
  bank_statement: { title: 'Bank statements' },
  itr: { title: 'ITR' },
  noa: { title: 'NOA' },
  bas: { title: 'BAS' },
  centrelink: { title: 'Centrelink' },
  other_incom: { title: 'Other income statement' },
  address: { title: '' },
};

export const Q_EXPENSES = {
  marital_status: { title: 'Marital Status:' },
  number_of_dependents: { title: 'Number of dependents:' },

  food_groceries: { title: 'Food & Groceries:' },
  home_utilities_communication: {
    title:
      'Home, utilities, communication (includes, Council Rates, Electricity, Gas, Water, Phone and Internet, Subscriptions and Streaming Services)',
  },
  personal_pet_care: {
    title: 'Personal and Pet care (Buy Now, Pay Later,  Clothing, Footwear and Basic Health Care)'
  },
  insurance_excluding_personal_insurance: {
    title: 'Insurance Excluding Personal Insurance (CTP, Vehicle Insurance, Home & Contents, Private/Health Insurance)'
  },
  vehicles_and_transport: {
    title: 'Vehicles and Transport (Fuel, Rego, Tolls, Maintenance and Public Transport)'
  },

  education: {
    title: 'Education (Childcare, Nannies, Preschool, Public Primary and Secondary Education Costs)',
  },
  recreation_travel_entertainment: {
    title: 'Recreation, travel and entertainment (Dining Out, Takeaway, Coffee, Alcohol, Domestic Travel, Sports, Tobacco and Gambling)'
  },
  land_tax_body_corporate_strata: {
    title: 'Land Tax, Body Corporate & Strata'
  },
  investment_additional_property: {
    title: 'Investment/Additional Property (Utilities, Property Management Fees, Body Corporate/Strata/Community Title Fees, Land Tax, Council Rates, Insurance)'
  },
  private_schooling_child_spousal_support_payments: {
    title: 'Private Schooling and Child / Spousal Support Payments'
  },
  all_other_expenses: {
    title: 'All other expenses not listed above'
  },
};

export const Q_ASSETS = {
  otherAssets: { title: 'Other Assets' },
  property_data: { title: 'Properties' },
  vehicle_data: { title: 'Motor Vehicles' },
  superannuation: { title: 'Superannuation:' },
  savings: { title: 'Savings:' },
  household_furniture: { title: 'Household Furniture (Estimate):' },
  shares: { title: 'Shares (Estimate):' },
  all_other: { title: 'All other specific assets:' },
};

export const Q_ASSETS_PROPERTY = {
  property_type: { title: 'Property Type:' },
  property_address: { title: 'Address:' },
  estimated_property_value: { title: 'Estimated Value:' },
};

export const Q_ASSETS_VEHICLE = {
  year: { title: 'Specify Make, Model & Year:' },
  estimated_vehicle_value: { title: 'Estimated Value:' },
};

export const Q_LIABILITIES = {
  mortgages_data: { title: 'Mortgages:' },
  credit_cards_data: { title: 'Credit Cards:' },
  personal_loans_data: { title: 'Personal Loans' },
  monthly_rent_board: { title: 'Monthly Rent/Board:' },
  total_other_liabilities: { title: 'Total other liabilities:' },
};

export const Q_LIABILITIES_MORTGAGE = {
  mortgage_type: { title: 'Mortgage Type:' },
  institution: { title: 'Institution:' },
  year_account_opened: { title: 'Year Account Opened:' },
  outstanding_balance: { title: 'Outstanding Balance:' },
  monthly_repayment: { title: 'Monthly Repayment:' },
};

export const Q_LIABILITIES_LOAN = {
  is_secured_loan: { title: 'Is this a secured loan?' },
  institution: { title: 'Institution:' },
  year_account_opened: { title: 'Year Account Opened:' },
  outstanding_balance: { title: 'Outstanding Balance:' },
  monthly_repayment: { title: 'Monthly Repayment:' },
};

export const Q_LIABILITIES_CC = {
  institution: { title: 'Institution:' },
  year_account_opened: { title: 'Year Account Opened:' },
  credit_card_limit: { title: 'Credit Card Limit:' },
  outstanding_balance: { title: 'Outstanding Balance:' },
  monthly_repayment: { title: 'Monthly Repayment:' },
};

export const A_INDICATE_ASSISTANCE = {
  postpone_repayments: 'Postpone repayments',
  assist_in_vehicle_sale: 'Assist in the sale of your vehicle',
  background_hardship_other: 'Other',
};
