import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {strToInt} from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
  },
  longLabelShrink: {
    marginBottom: '-15px !important',
    position: 'relative',
    zoom: '0.75',
    transform: 'none'
  },
}));

const currencyRegex = /^[0-9]*$/;
// const numericDecimal = /^[0-9]+(.[0-9]{0,2})?$/;

function PriceInput(props) {
  const noFormTag = !!props.noFormTag;
  const classes = useStyles();

  // Error message
  let errorMessage = '*Required';
  if (props.errorMessage && props.value !== '') {
    errorMessage = props.errorMessage;
  }

  /**
   * Handles a form change
   *
   * @param {object} event React event
   * @param {object} event.target Form field
   * @param {string|number} event.target.value Form field value
   */
  const handleChange = ({ target: { value } }) => {
    if (props.type === 'currency') {
      if (currencyRegex.test(value)) {
        props.setValue(props.id, value);
      }
    } else {
      props.setValue(props.id, value);
    }
  };

  const priceInputContent = (
    <FormControl fullWidth className={props.classes ? props.classes : classes.root}>
      <InputLabel
        htmlFor={props.id}
        classes={props.longLabel ? { shrink: classes.longLabelShrink } : {}}
      >
        {props.title}
      </InputLabel>
      <Input
        id={props.id}
        value={props.value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            {props.percent ? '%' : props.years ? 'Years' : '$'}
          </InputAdornment>
        }
      />
      {
        (props.required || Number(props.value) < Number(props.minValue) || Number(props.value) <= Number(props.greaterThanValue)) && (
            <FormHelperText id="standard-helper-text" data-testid={props.id + '_standard-helper-text'}>
              {errorMessage}
            </FormHelperText>
        )
      }
      {
        props.helperText && (
            <FormHelperText className="helper-text">
              {props.helperText}
            </FormHelperText>
        )
      }
    </FormControl>
  );

  return noFormTag ? (
    <div className={props.classes ? props.classes : classes.root}>{priceInputContent}</div>
  ) : (
    <form className={props.classes ? props.classes : classes.root} noValidate autoComplete="off">
      {priceInputContent}
    </form>
  );
}

export default PriceInput;
