import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    },
}));

// Numeric pattern
export const numericRegex = /^([0-9]{0,4}$)/;

/**
 * @typedef {{name: string, value: boolean | undefined}} validate
 *
 * @param {object} props
 * @param {function} props.setValue
 * @param {string} props.id
 * @param {string} props.type
 * @param {string | number} props.value
 * @param {string} props.title
 * @param {boolean} props.noFormTag
 */
const YearInput = (props) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [year, setYear] = useState('');

    const classes = useStyles();
    const noFormTag = !!props.noFormTag;
    const endYear = new Date().getFullYear();
    const startYear = Number(endYear - props.minYears);

    useEffect(() => {
        if (props.value) {
            setYear(props.value);
        } else {
            setYear('');
        }
    }, [props.value])

    useEffect(() => {
        if (year) {
            if (year.length > 0 && year.length <= 4 && numericRegex.test(year)) {
                if (startYear > Number(year) || endYear < Number(year)) {
                    setErrorMessage(`Please enter a year within the last ${props.minYears} years`);
                } else {
                    setErrorMessage('');
                }
            }else{
                setErrorMessage('');
            }
        }else{
            setErrorMessage('');
        }
    }, [year])

    /**
     * Handles a form change
     *
     * @param {object} event React event
     * @param {object} event.target Form field
     * @param {string|number} event.target.value Form field value
     */
    const handleChange = ({target: {value}}) => {
        if (value.length > 0) {
            if (value.length > 0 && value.length <= 4 && numericRegex.test(value)) {
                if (startYear > Number(value) || endYear < Number(value)) {
                    props.setValue(props.id, value);
                } else {
                    props.setValue(props.id, value);
                }
            }
        } else {
            props.setValue(props.id, value);
        }
    };

    /**
     * View
     */

    const textInputContent = (
        <FormControl fullWidth className={classes.root}>
            <TextField
                className={classes.number}
                value={year}
                label={props.title}
                type="text"
                onChange={handleChange}
                id={props.id}
                maxLength={4}
            />
            {props.required && <FormHelperText id="standard-helper-text" data-testid={props.id + "-standard-helper-text"}>*Required</FormHelperText>}
            {errorMessage && <FormHelperText id="standard-helper-text" data-testid={props.id + "-standard-helper-text"}>{errorMessage}</FormHelperText>}
        </FormControl>
    );

    return noFormTag ? (
        <div className={classes.root}>{textInputContent}</div>
    ) : (
        <form className={classes.root} noValidate autoComplete="off">
            {textInputContent}
        </form>
    );
};

export default YearInput;
