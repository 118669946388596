import { makeStyles, withStyles } from '@material-ui/core/styles';

import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { FormControlLabel, Switch } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

const currencyRegex = /^[0-9]*$/;

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        paddingBottom: 2,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: 'lightgray',
                opacity: 1,
                border: 'none',
            },
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 24 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create([ 'background-color', 'border' ]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const ConditionalPriceInput = ({
                                   setValue,
                                   switchId,
                                   switchValue,
                                   inputId,
                                   inputTitle,
                                   inputValue,
                                   required,
                                   minValue,
                                   errorMessage,
                                   longLabel,
                               }) => {

    const [ checked, setChecked ] = useState(true);

    useEffect(() => {
        setChecked(switchValue);
    }, [ switchValue ]);

    const useStyles = makeStyles(() => ({
        root: {
            width: '100%',
            marginTop: '10px',
            paddingTop: 3,
            marginBottom: '10px',
        },
        longLabelShrink: {
            marginBottom: '-15px !important',
            position: 'relative',
            zoom: '0.75',
            transform: 'none',
        },
    }));

    const classes = useStyles();

    const getErrorMessage = () => {
        let message = '';

        if (required && !inputValue) {
            message = '*Required';
        } else if (required && Number(inputValue) < Number(minValue)) {
            message = errorMessage;
        }

        if (required) {
            return (
                <span id='standard-helper-text' data-testid={inputId + '_standard-helper-text'}>
                    {message}
                </span>
            );
        }
    };

    const handleChange = ({ target: { value } }) => {
        if (currencyRegex.test(value)) {
            setValue(inputId, value);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <FormControlLabel
                control={
                    <IOSSwitch data-testid={switchId} checked={checked} onChange={(evt, value) => {
                        setValue(switchId, value);
                        setChecked(value);
                        setValue(inputId, value ? '' : 'N/A');
                    }} />
                }
                label=''
            />

            <FormControl fullWidth className={classes.root}>
                <InputLabel
                    htmlFor={inputId}
                    classes={longLabel ? { shrink: classes.longLabelShrink } : {}}
                    disabled={!required}
                >
                    {inputTitle}
                </InputLabel>

                <Input
                    className={classes.root}
                    id={inputId}
                    value={required ? inputValue : 'Not applicable'}
                    onChange={handleChange}
                    disabled={!required}
                    startAdornment={
                        required && (<InputAdornment position='start'>$</InputAdornment>)
                    }
                />

                {getErrorMessage()}
            </FormControl>
        </div>
    );
};

export default ConditionalPriceInput;