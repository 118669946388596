import FileUpload from "../../FormElements/FileUpload";
import React from "react";

const Documents = ({documentUiValidate: documentUiValidate,
                       postfix:postfix,
                       metadata:metadata,
                       incomeValues:incomeValues,
                       setValue:setValue,
                       type:type,
                       setCanSaveData:setCanSaveData}) => {
    return (
        <>
            {documentUiValidate(`PAYSLIP_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload most recent payslip or letter from employer (eg termination letter)"
                        type={type}
                        setValue={setValue}
                        id="payslip_file"
                        testAutomationId={`borrower${postfix}-payslip_file`}
                        documentType={`PAYSLIP_BORROWER_${postfix}`}
                        value={incomeValues.payslip_file}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`BANK_STATEMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload most recent Bank Statement"
                        type={type}
                        setValue={setValue}
                        id="bank_statement"
                        testAutomationId={`borrower${postfix}-bank_statement`}
                        documentType={`BANK_STATEMENT_BORROWER_${postfix}`}
                        value={incomeValues.bank_statement}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`INCOME_TAX_ASSESSMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload Income Tax Assessment"
                        type={type}
                        setValue={setValue}
                        id="income_tax_assessment"
                        testAutomationId={`borrower${postfix}-income_tax_assessment`}
                        documentType={`INCOME_TAX_ASSESSMENT_BORROWER_${postfix}`}
                        value={incomeValues.income_tax_assessment}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`NOTICE_OF_ASSESSMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload Notice of Assessment"
                        type={type}
                        setValue={setValue}
                        id="notice_assessment"
                        testAutomationId={`borrower${postfix}-notice_assessment`}
                        documentType={`NOTICE_OF_ASSESSMENT_BORROWER_${postfix}`}
                        value={incomeValues.notice_assessment}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload Business Activity Assessment"
                        type={type}
                        setValue={setValue}
                        id="business_activity_assessment"
                        testAutomationId={`borrower${postfix}-business_activity_assessment`}
                        documentType={`BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_${postfix}`}
                        value={incomeValues.business_activity_assessment}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`CENTRELINK_STATEMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload Centrelink Statement"
                        type={type}
                        setValue={setValue}
                        id="centrelink_statement"
                        testAutomationId={`borrower${postfix}-centrelink_statement`}
                        documentType={`CENTRELINK_STATEMENT_BORROWER_${postfix}`}
                        value={incomeValues.centrelink_statement}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`OTHER_INCOME_STATEMENT_BORROWER_${postfix}`, metadata) && (
                <>
                    <FileUpload
                        title="Upload Other income Statements"
                        type={type}
                        setValue={setValue}
                        id="other_income_statement"
                        testAutomationId={`borrower${postfix}-other_income_statement`}
                        documentType={`OTHER_INCOME_STATEMENT_BORROWER_${postfix}`}
                        value={incomeValues.other_income_statement}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
            {documentUiValidate(`OTHER_DOCUMENTS_BORROWER_${postfix}`, metadata) && (
                <>
                    <h5>Any other documents</h5>
                    <FileUpload
                        title="Please upload any other document(s) which you think will help us make a better assessment of your current circumstances"
                        type={type}
                        multiple={true}
                        setValue={setValue}
                        id="other_documents"
                        testAutomationId={`borrower${postfix}-other_documents`}
                        documentType={`OTHER_DOCUMENTS_BORROWER_${postfix}`}
                        value={incomeValues.other_documents}
                        setCanSaveData={setCanSaveData}
                    />
                </>
            )}
        </>
    )
}

export default Documents;