import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  StyledContentContainer,
  StyledFormContainer,
} from '../../styled/Containers';
import theme from '../../../utilities/theme';
import FormProgress from '../../components/FormProgress';
import FormGroupControl from '../../components/FormGroupControl';

function ApplicationForm() {
  localStorage.setItem('@application_form', 'false');

  const brand = useSelector((store) => store.brand.brand);
  const [formHistory, setFormHistory] = useState([]);


  return (
    <StyledContentContainer color={theme(brand).background_color} theme={brand}>

        <>
          <FormProgress formHistory={formHistory} />
          <StyledFormContainer theme={brand}>
            <FormGroupControl
              setFormHistory={setFormHistory}
              theme={brand}
            />
          </StyledFormContainer>
        </>
    </StyledContentContainer>
  );
}

export default ApplicationForm;
