import React from 'react';
import { PROPERTY_TYPE } from '../constants';
import { useFormGroupsStyles } from '../styles';
import { StyledButtonGroup } from '../../../styled/Containers';
import TextInput from '../../FormElements/TextInput';
import PriceInput from '../../FormElements/PriceInput';
import SelectBox from '../../FormElements/SelectBox';
import Button from '@material-ui/core/Button';

function PropertyType(props) {
  const { componentData, index, setPropertyValue, deleteBlock, items, setItems } = props;
  const classes = useFormGroupsStyles();

  const setValue = (key, value) => {
    setPropertyValue(index, key, value);
  };

  return (
    <>
        <SelectBox
          title="Property Type"
          id="property_type"
          items={PROPERTY_TYPE}
          value={componentData.property_type}
          setValue={setValue}
          required={!componentData.property_type}
        />

        <TextInput
          noFormTag={true}
          title="Address"
          id="property_address"
          setValue={setValue}
          value={componentData.property_address}
          required={!componentData.property_address}
        />
        <PriceInput
          noFormTag={true}
          id="estimated_property_value"
          title="Estimated Value"
          type="currency"
          value={componentData.estimated_property_value}
          setValue={setValue}
          required={!componentData.estimated_property_value}
        />
        {index !== 0 ? (
          <StyledButtonGroup style={{marginTop:0, marginBottom:'1rem'}}>
            <div className="flex_left">
              <Button
                variant="contained"
                className="back"
                color="secondary"
                onClick={() => deleteBlock(items, index, setItems)}
                id="deletePropertyBlock"
              >
                Delete
              </Button>
            </div>
          </StyledButtonGroup>
        ) : null}
    </>
  );
}

export default PropertyType;
