import React, {useEffect} from 'react';
import {useFormGroupsStyles} from '../styles';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import FormGroupButtons from '../../FormGroupButtons';
import TextInput from '../../FormElements/TextInput';
import PriceInput from '../../FormElements/PriceInput';
import DatePicker from '../../FormElements/DatePicker';
import AcknowledgeCheckBox from '../../FormElements/Acknowledge';
import SelectBox from "../../FormElements/SelectBox";
import {NATURAL_DISASTER_OPTIONS, WHY_APPLY_HARDSHIP_OPTIONS} from "../constants";
import {SET_BACKGROUND_HARDSHIP_DATA} from "../../../../state/ducks/user/types";
import {useDispatch, useSelector} from "react-redux";
import {hasStringValue, strToInt} from "../../../utils";
import {monthsRegex} from "../utils";
import YesNo from "../../FormElements/YesNo";
import _ from "lodash";
import {initialState} from "../../../../state/ducks/user/reducers";


const FG_BackgroundHardship = (props) => {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({
        ...userData.background_hardship,
    });

    const dispatch = useDispatch();
    const groupId = props.groupId;
    const classes = useFormGroupsStyles();

    useEffect(() => {
        const copy = JSON.parse(JSON.stringify(userData.background_hardship))
        const payload = initialState.background_hardship;
        _.merge(payload, userData.background_hardship);
        userData.background_hardship = payload;
        userData.background_hardship.medical_certification_file = copy.medical_certification_file ? copy.medical_certification_file : {};
    }, [0])

    useEffect(() => {
        setValues({...userData.background_hardship})
    }, [userData.background_hardship])

    const userDataBgHardship = userData.background_hardship;
    const howMuchAbleToPay = strToInt(userDataBgHardship.howmuch_able_to_pay);
    const shouldDisplayReducedPaymentGroup = howMuchAbleToPay > 0;
    const shouldDisplayIndicateReasonableAssistanceGroup = howMuchAbleToPay === 0 && userDataBgHardship.howmuch_able_to_pay !== '';


    /**
     * Form fields handlers
     */
    const handleEligibleForCompensationChange = (e) => {
        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: {
                eligible_for_complensation: e.target.value,
                how_much: '',
                when_anticipate_compensation: null
            },
        });
    };

    const handleCircumstancesChanged = (e) => {
        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: {
                circumstances_changed: e.target.value
            },
        });
    };

    const handleHowLongReduceSelectionChange = (e) => {
        const value = e.target.value;
        const payloadData = {};

        if (value !== 'other') {
            // pass selection value to 'howlong_reduce' fields
            payloadData.howlong_reduce = value;
        } else {
            // reset 'howlong_reduce' fields
            payloadData.howlong_reduce = '';
        }

        // populate selection fields
        payloadData.howlong_reduce_selection = value;

        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: payloadData,
        });
    };

    const handlePaymentProtectionInsuranceChange = (e) => {
        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: {
                isPayment_Protection_Insurance: e.target.value,
                logged_clam: '',
                when_logged_calm: null,
                why_have_you_not_made_a_claim: ''
            },
        });
    };

    const handleLoggedClaimChange = (e) => {
        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: {
                logged_clam: e.target.value,
                when_logged_calm: null,
                why_have_you_not_made_a_claim: ''
            }
        });
    };

    const handleIndicateReasonableChange = (e) => {
        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: {
                indicate_reasonable_assistance: e.target.value,
                howlong_postpone: '',
                acknowledge_assist_in_vehicle_sale: 'false',
                background_hardship_others: ''
            }
        });
    };

    const setMonths = (key, value) => {
        if (value.length === 0) {
            setValue(key, value);
        } else if (monthsRegex.test(value)) {
            setValue(key, value);
        }
    }

    const setValue = (key, value) => {
        // base data to dispatch

        const data = {
            [key]: value
        };

        // when 'howmuch_able_to_pay' is changed
        if (key === 'howmuch_able_to_pay') {
            if (strToInt(value) <= 0) {
                // reset related question fields
                data.howlong_reduce_selection = '';
                data.howlong_reduce = '';
            } else {
                // reset related question fields
                data.indicate_reasonable_assistance = '';
                data.howlong_postpone = '';
                data.acknowledge_assist_in_vehicle_sale = '';
                data.background_hardship_others = '';
            }
        } else if (key === 'why_apply_hardship') {
            if (value !== 'natural_disaster') {
                data.why_apply_hardship_natural_disaster = '';
            }
        }

        dispatch({
            type: SET_BACKGROUND_HARDSHIP_DATA,
            payload: data,
        });
    };


    /**
     * Validation
     */

    const validateHowMuchAbleToPay = (isValid) => {
        isValid = hasStringValue(values.howmuch_able_to_pay);

        if (isValid) {
            if (strToInt(values.howmuch_able_to_pay) > 0) {
                isValid = hasStringValue(values.howlong_reduce_selection);
                if (isValid && values.howlong_reduce_selection === 'other') {
                    isValid = hasStringValue(values.howlong_reduce);
                }
            } else if (strToInt(values.howmuch_able_to_pay) <= 0) {
                isValid = hasStringValue(values.indicate_reasonable_assistance);
                if (isValid) {
                    switch (values.indicate_reasonable_assistance) {
                        case 'postpone_repayments':
                            isValid = hasStringValue(values.howlong_postpone);
                            break;
                        case 'assist_in_vehicle_sale':
                            isValid = hasStringValue(values.acknowledge_assist_in_vehicle_sale) && values.acknowledge_assist_in_vehicle_sale === 'true';
                            break;
                        case 'background_hardship_other':
                            isValid = hasStringValue(values.background_hardship_others);
                            break;
                        default:
                    }
                }
            }
        }

        return isValid;
    }

    const validateFormGroup = () => {
        const startOfDay = new Date();
        startOfDay.setHours(0);
        startOfDay.setMinutes(0);
        startOfDay.setSeconds(0);
        startOfDay.setMilliseconds(0);

        const endOfDay = new Date();
        endOfDay.setHours(23);
        endOfDay.setMinutes(59);
        endOfDay.setSeconds(59);
        endOfDay.setMilliseconds(59);

        let isValid = hasStringValue(values.acknowledge_interest_fees) && values.acknowledge_interest_fees === 'true';

        if (isValid) {
            if (groupId.indexOf('RETURNING') !== -1) {
                isValid = hasStringValue(values.circumstances_changed);
            }
        }

        if (isValid) {
            isValid = validateHowMuchAbleToPay(isValid);
        }

        if (isValid) {
            isValid = !!values.when_resume_contractual_repayment && new Date(values.when_resume_contractual_repayment) >= startOfDay;
        }

        if (isValid) {
            isValid = hasStringValue(values.why_apply_hardship) && !!WHY_APPLY_HARDSHIP_OPTIONS.find(item => item.id === values.why_apply_hardship);
            if (isValid) {
                if (values.why_apply_hardship === 'natural_disaster') {
                    isValid = hasStringValue(values.why_apply_hardship_natural_disaster) && !!NATURAL_DISASTER_OPTIONS.find(item => item.id === values.why_apply_hardship_natural_disaster);
                }
            }
        }

        if (isValid) {
            isValid = hasStringValue(values.briefly_describe);
        }

        if (isValid) {
            isValid = hasStringValue(values.what_you_doing_to_address_your_situation);
        }

        if (isValid) {
            isValid = !!values.when_difficulties_date && new Date(values.when_difficulties_date) <= endOfDay;
        }

        if (isValid) {
            isValid = !!values.eligible_for_complensation;
            if (isValid) {
                if (values.eligible_for_complensation === 'true') {
                    isValid = !!values.how_much;
                    if (isValid) {
                        isValid = !!values.when_anticipate_compensation && new Date(values.when_anticipate_compensation) >= startOfDay;
                    }
                }
            }
        }

        if (isValid) {
            isValid = hasStringValue(values.isPayment_Protection_Insurance);
            if (isValid) {
                if (values.isPayment_Protection_Insurance === 'true') {
                    isValid = hasStringValue(values.logged_clam);
                    if (isValid) {
                        if (values.logged_clam === 'true') {
                            isValid = !!values.when_logged_calm && new Date(values.when_logged_calm) <= endOfDay;
                        } else {
                            isValid = hasStringValue(values.why_have_you_not_made_a_claim);
                        }
                    }
                }
            }
        }

        return isValid;
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };

    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    /**
     * View
     */

    return (
        <>
            {(groupId.indexOf('RETURNING') !== -1) &&
            <>
                <h4>
                    Have your circumstances changed since your last application for
                    hardship?
                </h4>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                    style={{marginBottom: '50px'}}
                >
                    {!values.circumstances_changed && <h6>*Required</h6>}
                    <RadioGroup
                        id="hardship-circumstances_changed"
                        aria-label="quiz"
                        name="quiz"
                        value={values.circumstances_changed}
                        onChange={handleCircumstancesChanged}
                    >
                        <FormControlLabel
                            control={<Radio/>}
                            className={classes.option}
                            label="No"
                            value="no"
                        />
                        <FormControlLabel
                            control={<Radio/>}
                            className={classes.option}
                            label="Yes, they have improved"
                            value="yes_better"
                        />
                        <FormControlLabel
                            control={<Radio/>}
                            className={classes.option}
                            label="Yes, they have gotten worse"
                            value="yes_worse"
                        />
                    </RadioGroup>
                </FormControl>
            </>
            }
            <AcknowledgeCheckBox
                title="Please acknowledge that interest and fees will continue to accrue on your loan if you are granted Hardship assistance."
                id="acknowledge_interest_fees"
                value={values.acknowledge_interest_fees}
                setValue={setValue}
                required={!values.acknowledge_interest_fees || values.acknowledge_interest_fees === 'false'}
            />

            <h5>How much can you afford to pay monthly?</h5>
            <PriceInput
                noFormTag={true}
                id="howmuch_able_to_pay"
                title="AUD"
                type="currency"
                minValue="0"
                value={values.howmuch_able_to_pay}
                setValue={setValue}
                required={!values.howmuch_able_to_pay}
            />
            {shouldDisplayReducedPaymentGroup && (
                <>
                    <h4>
                        And how long (in months) do you need to pay a reduced repayment?
                    </h4>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {!values.howlong_reduce_selection &&
                        <h6 data-testid={"howlong_reduce_selection_required"}>*Required</h6>}
                        <RadioGroup
                            id="hardship-howlong_reduce_selection"
                            aria-label="quiz"
                            name="quiz"
                            value={values.howlong_reduce_selection}
                            onChange={handleHowLongReduceSelectionChange}
                        >
                            <FormControlLabel
                                control={<Radio/>}
                                className={classes.option}
                                label="1 month"
                                value="1"
                                id={"howlong_reduce_selection_1"}
                            />
                            <FormControlLabel
                                control={<Radio/>}
                                className={classes.option}
                                label="2 months"
                                value="2"
                                id={"howlong_reduce_selection_2"}
                            />
                            <FormControlLabel
                                control={<Radio/>}
                                className={classes.option}
                                label="3 months"
                                value="3"
                                id={"howlong_reduce_selection_3"}
                            />
                            {(groupId.indexOf('RETURNING') === -1) &&
                            <>
                                <FormControlLabel
                                    control={<Radio/>}
                                    className={classes.option}
                                    label="4 months"
                                    value="4"
                                    id={"howlong_reduce_selection_4"}
                                />
                                <FormControlLabel
                                    control={<Radio/>}
                                    className={classes.option}
                                    label="5 months"
                                    value="5"
                                    id={"howlong_reduce_selection_5"}
                                />
                            </>
                            }

                            <FormControlLabel
                                control={<Radio/>}
                                className={classes.option}
                                label="other"
                                value="other"
                                id={"howlong_reduce_selection_other"}
                            />
                            {values.howlong_reduce_selection === 'other' && (
                                <>
                                    <h4>Specify number of months</h4>
                                    <TextInput
                                        noFormTag={true}
                                        id="howlong_reduce"
                                        title="Specify number of months"
                                        value={values.howlong_reduce}
                                        type={"text"}
                                        setValue={setMonths}
                                        required={!values.howlong_reduce}
                                    />
                                </>
                            )}

                        </RadioGroup>
                    </FormControl>
                </>
            )}
            <h5>
                What date do you expect to be able to resume your full contractual
                repayments?
            </h5>
            <DatePicker
                noFormTag={true}
                title="Date"
                id="when_resume_contractual_repayment"
                testAutomationId="hardship-when_resume_contractual_repayment"
                setValue={setValue}
                type="future"
                value={values.when_resume_contractual_repayment}
                required={!values.when_resume_contractual_repayment}
            />

            <h5>
                Why are you applying for hardship
            </h5>
            <SelectBox
                title="Select a reason"
                id="why_apply_hardship"
                items={WHY_APPLY_HARDSHIP_OPTIONS}
                value={values.why_apply_hardship}
                setValue={setValue}
                required={!WHY_APPLY_HARDSHIP_OPTIONS.find(item => item.id === values.why_apply_hardship)}
            />
            {userData.background_hardship.why_apply_hardship === 'natural_disaster' &&
            <SelectBox
                title="Select a reason"
                id="why_apply_hardship_natural_disaster"
                items={NATURAL_DISASTER_OPTIONS}
                value={values.why_apply_hardship_natural_disaster}
                setValue={setValue}
                required={!NATURAL_DISASTER_OPTIONS.find(item => item.id === values.why_apply_hardship_natural_disaster)}
            />
            }

            <h5>
                Please provide information around why you are applying for hardship.
                Briefly describe your circumstances:
            </h5>
            <TextInput
                noFormTag={true}
                id="briefly_describe"
                title="Explain here"
                value={values.briefly_describe}
                type={"text"}
                setValue={setValue}
                required={!values.briefly_describe}
            />

            <h5>Approximately what date did these difficulties start?</h5>
            <DatePicker
                noFormTag={true}
                title="Date"
                id="when_difficulties_date"
                testAutomationId="hardship-when_difficulties_date"
                setValue={setValue}
                type="past"
                value={values.when_difficulties_date}
                required={!values.when_difficulties_date}
            />


            <h5>What are you doing or able to do to address your situation?</h5>
            <TextInput
                noFormTag={true}
                id="what_you_doing_to_address_your_situation"
                value={values.what_you_doing_to_address_your_situation}
                title="Explain here"
                type={"text"}
                setValue={setValue}
                required={!values.what_you_doing_to_address_your_situation}
            />

            <YesNo
                title={<h5>If your situation is as a result of an accident, are you eligible for compensation?</h5>}
                id={"eligible_for_complensation"}
                value={values.eligible_for_complensation} onChange={handleEligibleForCompensationChange}
                yesComponent={<>
                    <h4>How much?</h4>
                    <PriceInput
                        noFormTag={true}
                        id="how_much"
                        title="AUD"
                        type="currency"
                        minValue="0"
                        value={values.how_much}
                        setValue={setValue}
                        required={!values.how_much}
                    />
                    <h4>When do you anticipate compensation will be paid?</h4>
                    <DatePicker
                        noFormTag={true}
                        type="future"
                        id="when_anticipate_compensation"
                        testAutomationId="hardship-when_anticipate_compensation"
                        title="Select Date"
                        value={values.when_anticipate_compensation}
                        setValue={setValue}
                        required={!values.when_anticipate_compensation}
                    />
                </>}/>

            <YesNo
                title={<h5>Do you have Finance Protection Insurance, Payment Protection Insurance or Consumer Credit
                    Insurance for your loan contract(s)?</h5>}
                id={"isPayment_Protection_Insurance"} value={values.isPayment_Protection_Insurance}
                onChange={handlePaymentProtectionInsuranceChange}
                yesComponent={<>
                    <YesNo title={<h5>If yes, have you lodged a claim?</h5>} id={"logged_clam"}
                           value={values.logged_clam}
                           onChange={handleLoggedClaimChange} yesComponent={<>
                        <h4>When was the claim lodged?</h4>
                        <DatePicker
                            noFormTag={true}
                            id="when_logged_calm"
                            type="past"
                            testAutomationId="hardship-when_logged_calm"
                            title="Select Date"
                            value={values.when_logged_calm}
                            setValue={setValue}
                            required={!values.when_logged_calm}
                        />
                    </>} noComponent={<>
                        <h5>Making a claim on your policy may assist in covering some or all of your repayments. Why
                            have you
                            not made a claim?</h5>
                        <TextInput
                            noFormTag={true}
                            id="why_have_you_not_made_a_claim"
                            title="Explain here"
                            value={values.why_have_you_not_made_a_claim}
                            type={"text"}
                            setValue={setValue}
                            required={!values.why_have_you_not_made_a_claim}
                        />
                    </>}/>
                </>}/>

            {shouldDisplayIndicateReasonableAssistanceGroup && (
                <>
                    <h5>
                        Please indicate what reasonable assistance we could provide to you
                        that would help you through this difficult time and allow you to
                        meet your future monthly repayments to us.
                        <div
                            style={{
                                marginTop: '15px',
                                marginBottom: '30px',
                                fontSize: '14px',
                            }}
                        >
                            <Link href="/faq">How will the change affect my loan?</Link>
                        </div>
                    </h5>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {!values.indicate_reasonable_assistance && (
                            <h6>*Required</h6>
                        )}
                        <RadioGroup
                            id="hardship-indicate_reasonable_assistance"
                            aria-label="quiz"
                            name="quiz"
                            value={values.indicate_reasonable_assistance}
                            onChange={handleIndicateReasonableChange}
                        >
                            <FormControlLabel
                                className={classes.option}
                                control={<Radio/>}
                                label="Postpone repayments"
                                value="postpone_repayments"
                                id={"postpone_repayments"}
                            />
                            {values.indicate_reasonable_assistance ===
                            'postpone_repayments' && (
                                <>
                                    <h4>
                                        How long (in months) would you like to postpone your
                                        repayments?
                                    </h4>
                                    <TextInput
                                        noFormTag={true}
                                        id="howlong_postpone"
                                        title="Number of Months"
                                        value={values.howlong_postpone}
                                        type={"text"}
                                        setValue={setMonths}
                                        required={!values.howlong_postpone}
                                    />
                                </>
                            )}
                            <FormControlLabel
                                className={classes.option}
                                control={<Radio/>}
                                label="Assist in the sale of your vehicle"
                                value="assist_in_vehicle_sale"
                                id={"assist_in_vehicle_sale"}
                            />
                            {values.indicate_reasonable_assistance ===
                            'assist_in_vehicle_sale' && (
                                <>
                                    <p>
                                        If you can no longer afford to make repayments on your loan
                                        with us and you wish to sell your vehicle, then our team of
                                        specialists can assist you.
                                    </p>
                                    <AcknowledgeCheckBox
                                        title="By checking this box, you acknowledge that you have read, understand, and agree to obtain assistance with the sale of your vehicle and that you will be liable for any shortfall that may result following the sale of your vehicle. Once you complete and submit this form, one of our specialists will be in contact with you to explain  the sale process and options available including voluntary surrender or private sale. We will advise you on timeframes and estimated costs and can also enter into payments arrangements if a shortfall is anticipated post the sale of the vehicle."
                                        id="acknowledge_assist_in_vehicle_sale"
                                        value={values.acknowledge_assist_in_vehicle_sale}
                                        setValue={setValue}
                                        required={!values.acknowledge_assist_in_vehicle_sale || values.acknowledge_assist_in_vehicle_sale === 'false'}
                                    />
                                    <p>
                                        If you do not wish to proceed with this option, please
                                        select a different type of assistance.
                                    </p>
                                </>
                            )}
                            <FormControlLabel
                                className={classes.option}
                                control={<Radio/>}
                                label="Other"
                                value="background_hardship_other"
                                id={"background_hardship_other"}
                            />
                            {values.indicate_reasonable_assistance ===
                            'background_hardship_other' && (
                                <>
                                    <h4>
                                        Please explain what type of assistance you would like and
                                        for how many months you need the assistance.
                                    </h4>
                                    <TextInput
                                        noFormTag={true}
                                        id="background_hardship_others"
                                        title="Explain here"
                                        value={values.background_hardship_others}
                                        type={"text"}
                                        setValue={setValue}
                                        required={!values.background_hardship_others}
                                    />
                                </>
                            )}
                        </RadioGroup>
                    </FormControl>
                </>
            )}
            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
};

export default FG_BackgroundHardship;
