import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { SET_BRAND } from '../state/ducks/brands/types';
import { SET_BRAND_INFO } from '../state/ducks/brandInfo/types';
import Footer from './components/Footer';
import Header from './components/Header';
import history from './history';
import Route from './routes';
import { Container } from './styled/Containers';
import { brandFromHost } from '../utilities';
import { Session } from '../api/session';
import Favicon from 'react-favicon';
import theme from '../utilities/theme';
import MetaTags from 'react-meta-tags';

export const LoadingView = () => (
  <div className="app-loading-view">Loading, please wait...</div>
);

function App() {
  const [metaDescription, setMetaDescription] = React.useState('');

  // check if application is already submitted based on status
  const submitted = useSelector(
    (store) =>
      store.user.submissionStatus !== undefined &&
      store.user.submissionStatus !== 'DRAFT'
  );


  const brand = useSelector((store) => store.brand.brand);
  const { name } = useSelector((store) => store.brandInfo.brandInfo);

  useEffect(() => {
    const currentPath = history.location.pathname;
    const shouldGoToSubmitted =
      currentPath !== '/submit' && currentPath !== '/faq';

    // Only redirect if:
    // - application status is present, and it isn't 'DRAFT'
    // - not trying to go to one of the excluded routes
    if (submitted && shouldGoToSubmitted) {
      history.push('/submitted');
    }

    // update DOM header stuff
    document.title = `${name} Hardship Application`;
    setMetaDescription(`${name} Hardship Application`);
  }, [submitted, history.location.pathname, name]);

  localStorage.setItem('step', '0');

  return (
    <Container>
      <MetaTags>
        <meta name="description" content={metaDescription} />
      </MetaTags>
      <Favicon url={theme(brand).favLogo} />

        <Header />
        <Router history={history}>
          <Route />
        </Router>
        <Footer />

    </Container>
  );
}

/**
 * Sets the theme based off the sub domain.
 * Also sends a request to the server to get all  brand information, for the selected brand domain.
 */
export default () => {
  const { hostname } = window.location;
  const brandName = brandFromHost(hostname);

  const dispatch = useDispatch();
  dispatch({ type: SET_BRAND, brand: brandName });

  // Due to it being in the default export, a callback is being used instead of an await.
  Session.requestBrandInfo(brandName).then(({ data }) =>
    dispatch({ type: SET_BRAND_INFO, brandInfo: data })
  );

  return <App />;
};
