import React from 'react';
import { useFormGroupsStyles } from '../../FormGroups/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

export default function AcknowledgeCheckBox(props) {
  const classes = useFormGroupsStyles();
  const isChecked =
    typeof props.value === 'string'
      ? props.value === 'true'
      : (!!props.value).toString().toLowerCase();

  /**
   * handlers
   */

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const isCheckedText = isChecked ? 'true' : 'false';

    props.setValue(props.id, isCheckedText);
  };

  /**
   * View
   */

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        className={classes.acknowledgeCheckbox}
        label={props.title}
        control={
          <Checkbox
            id={props.id}
            value={isChecked ? 'on' : 'off'}
            checked={isChecked}
            onChange={handleChange}
            name={`ack-checkbox-${props.id}`}
            size="medium"
          />
        }
      />
      {props.required && (
          <FormHelperText className="standard-helper-text" data-testid={props.id + '_standard_helper_text'}>
            *Acknowledgement required
          </FormHelperText>
      )}
    </FormControl>
  );
}
