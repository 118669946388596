import React, {useEffect} from 'react';
import TextInput from '../../FormElements/TextInput';
import PriceInput from "../../FormElements/PriceInput";
import {isAllCurrencyValidationStatus} from "../utils";

function Incomes({income:income, setValue:setValue}) {
    const [values, setValues] = React.useState();

    useEffect(() => {
        if(income){
            setValues(income)
        }
    },[income])
    /**
     * View
     */

    return (
        values ? <>
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="AUD"
                type="currency"
                id="after_tax_income"
                value={values.after_tax_income}
                required={!values.after_tax_income}
            />
            <h1>
                Please provide details of all sources of your current and ongoing
                monthly income. If you foresee that this income will change in the next
                three months, please provide the amount after the change.
            </h1>
            {!isAllCurrencyValidationStatus(values) && (
                <h6 data-testid={"income_sources_required"}>Please fill out details of at least one income source</h6>
            )}
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="After tax salary per month"
                id="after_tax_salary"
                helperText="Include JobKeeper payments"
                type="currency"
                value={values.after_tax_salary}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Pension"
                id="pension"
                type="currency"
                value={values.pension}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Family allowance"
                id="family_allowance"
                type="currency"
                value={values.family_allowance}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Newstart allowance"
                id="newstart_allowance"
                helperText="Include JobSeeker payments"
                type="currency"
                value={values.newstart_allowance}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Partner's income"
                id="partners_income"
                type="currency"
                value={values.partners_income}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Child Support"
                id="child_support"
                type="currency"
                value={values.child_support}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Other government benefits"
                id="other_government_benefits"
                type="currency"
                value={values.other_government_benefits}
            />
            <h5 style={{ marginTop: '30px' }}>Other Income (e.g. rental income)</h5>
            <TextInput
                noFormTag={true}
                setValue={setValue}
                title="Enter other income source"
                id="other_incom_specify_type_first_txt"
                value={values.other_incom_specify_type_first_txt}
                required={values.other_incom_specify_type_first_price && !values.other_incom_specify_type_first_txt}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Amount"
                id="other_incom_specify_type_first_price"
                type="currency"
                value={values.other_incom_specify_type_first_price}
                required={values.other_incom_specify_type_first_txt && !values.other_incom_specify_type_first_price}
            />
            <TextInput
                noFormTag={true}
                setValue={setValue}
                title="Enter other income source"
                id="other_incom_specify_type_second_txt"
                value={values.other_incom_specify_type_second_txt}
                required={values.other_incom_specify_type_second_price && !values.other_incom_specify_type_second_txt}
            />
            <PriceInput
                noFormTag={true}
                setValue={setValue}
                title="Amount"
                id="other_incom_specify_type_second_price"
                type="currency"
                value={values.other_incom_specify_type_second_price}
                required={values.other_incom_specify_type_second_txt && !values.other_incom_specify_type_second_price}
            />
        </> : <span/>
    );
}

export default Incomes;
