import {FormFlowApi} from '../../../api/formFlow';
import {FORM_GROUPS} from './constants';
// examples form groups
// form groups
import FG_AboutYou from '../FormGroups/aboutYou';
import FG_Expenses from '../FormGroups/expenses';
import FG_Assets from '../FormGroups/assets';
import FG_Review from '../FormGroups/review';
import FG_Liabilities from '../FormGroups/liabilities';
import FG_NewIncomeFirst from '../FormGroups/income/income.new.first';
import FG_NewIncomeSecond from '../FormGroups/income/income.new.second';
import FG_DocumentWallet from '../FormGroups/documentWallet';
import FG_BackgroundHardship from "../FormGroups/backgroundHardship/backgroundHardship";

/**
 * resetFormGroupView - resets form group view after loading new group
 */
export const resetFormGroupView = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1);
};

/**
 * getFormGroup
 * @param {string} formId - current application record form id
 * @param {boolean|null} isNext - if return next group, previous group, or current
 * @returns {object} form group data
 */
export const getFormGroup = async (formId = '', isNext = null) => {
  // get API data
  const apiMethod =
    isNext === null
      ? FormFlowApi.getCurrent
      : isNext
      ? FormFlowApi.getNext
      : FormFlowApi.getPrev;

  const data = await apiMethod(formId);

  // check if data has compulsory fields
  const hasFormGroupId =
    typeof data.groupId === 'string' && !!data.groupId.length;
  const hasHistory = Array.isArray(data.history);
  if (!hasFormGroupId || !hasHistory) {
    return null;
  }

  // update data with matched form group component
  data.component = getFormGroupComponentFromId(data.groupId);

  return data;
};

/**
 * getFormGroupComponentFromId
 * @param {string} groupId - form group id value from API
 * @returns {*} form group component mapped from id
 */
export const getFormGroupComponentFromId = (groupId = '') => {
  let component;

  switch (groupId) {
    case FORM_GROUPS.ABOUT_YOU:
      component = FG_AboutYou;
      break;
    case FORM_GROUPS.NEW_BACKGROUND_TO_HARDSHIP:
    case FORM_GROUPS.RETURNING_BACKGROUND_TO_HARDSHIP:
      component = FG_BackgroundHardship
      break;
    case FORM_GROUPS.INCOME_BORROWER_1:
      component = FG_NewIncomeFirst;
      break;
    case FORM_GROUPS.INCOME_BORROWER_2:
      component = FG_NewIncomeSecond;
      break;
    case FORM_GROUPS.EXPENSES:
      component = FG_Expenses;
      break;
    case FORM_GROUPS.ASSETS:
      component = FG_Assets;
      break;
    case FORM_GROUPS.LIABILITIES:
      component = FG_Liabilities;
      break;
    case FORM_GROUPS.DOCUMENT_WALLET:
      component = FG_DocumentWallet;
      break;
    case FORM_GROUPS.REVIEW:
      component = FG_Review;
      break;
    default:
      component = null;
  }
  return component;
};
