import _ from 'lodash';

/**
 * getSecondBorrowerData - get 'second_borrower' data value
 * @param {object} userData fallback user data object
 * @returns {string} result of the 'second_borrower' flag value
 */
export function getSecondBorrowerData(userData) {
  let result;

  // first try retrieve from local storage
  result = localStorage.getItem('second_borrower');

  // try user data if no local storage
  // e.g. user switched browser/computer
  if (_.isNil(result)) {
    const isSecondBorrower = _.get(userData, 'begin.isSecondBorrower', 'false');
    if (typeof isSecondBorrower === 'boolean') {
      result = '' + isSecondBorrower;
    } else if (typeof isSecondBorrower === 'string') {
      result = isSecondBorrower;
    }

    // set to local storage
    localStorage.setItem('second_borrower', result);
  }

  return result;
}

/**
 * getCovidData - get 'covid' data value
 * @param {object} userData fallback user data object
 * @returns {string} result of the 'covid' flag value
 */
export function getCovidData(userData) {
  let result;

  // first try retrieve from local storage
  result = localStorage.getItem('covid');

  // try user data if no local storage
  // e.g. user switched browser/computer
  if (_.isNil(result)) {
    const isCovid = _.get(userData, 'background_hardship.covid', 'false');
    if (typeof isCovid === 'boolean') {
      result = '' + isCovid;
    } else if (typeof isCovid === 'string') {
      result = isCovid;
    }

    // set to local storage
    localStorage.setItem('covid', result);
  }

  return result;
}

/**
 * hasStringValue
 * @param input - value to be checked
 * @returns {boolean}
 */
export function hasStringValue(input) {
  return typeof input === 'string' && !!input.trim().length;
}

/**
 * strToInt
 * @param str - string value for integer
 * @param fallbackInt - fallback number when integer is NaN
 * @returns {number}
 */
export function strToInt(str = '', fallbackInt = 0) {
  const int = parseInt(str);
  return isNaN(int) ? fallbackInt : int;
}
