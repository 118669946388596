import React from 'react';
import { useSelector } from 'react-redux';
import { StyledNavContainer } from './style';
import { StyledNavContent } from '../../styled/Containers';

function FormProgress({ formHistory }) {
  const brand = useSelector((store) => store.brand.brand);

  return (
    <StyledNavContainer theme={brand}>
      <StyledNavContent theme={brand}>
        {formHistory.map((stepTitle, index) => {
          const isPreviousStep = index < formHistory.length - 1;
          const stepClass = isPreviousStep ? 'active' : '';

          return (
            <h2 data-testid={'view_'+index} key={index} className={`${stepClass}`}>
              {stepTitle}
            </h2>
          );
        })}
      </StyledNavContent>
    </StyledNavContainer>
  );
}

export default FormProgress;
