import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Session } from '../../../api';
import { userLoggedIn } from '../../../state/ducks/user/actions';
import {
  AUTHORISATION_TOKEN_STORAGE_KEY,
  FORM_ID_STORAGE_KEY,
} from '../../../utilities';
import { formDataFromStorage } from '../../../utilities/storage';
import { StyledButton } from '../../styled/Containers';
import theme from '../../../utilities/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    marginTop: '30px',
  },
  textField: {
    width: '100%',
  },
  width: {
    width: '100%',
    minWidth: '200px',
  },
  button: {
    marginTop: '80px',
    width: '100%',
    '& .MuiButtonBase-root': {
      width: '100%',
      borderRadius: '20px',
    },
  },
  color: {
    backgroundColor: '#eb0a1e',
    '& span': {
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#D1091B',
    },
  },
}));

export default function InputAdornments() {
  const brand = useSelector((store) => store.brand.brand);
  const data = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const classes = useStyles(data);
  const history = useHistory();
  const [values, setValues] = React.useState({
    code: '',
    sendEmail: false,
    emailValid: false,
  });
  const [codeIsValid, setCodeIsValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);

  useEffect(() => {
    if (data.token) {
      const path = data.formSection;
      history.push(path && path !== '/' ? path : '/application_form');
    }
  });

  const handleChange = () => async (event) => {
    setTouched(true);
    validateField(event.target.value);
  };

  const validateField = (value) => {
    const validated =
      typeof value === 'string' &&
      value.trim().length > 0 &&
      value.match(/^([\w.%+-]+)$/i);

    if (validated) {
      setValues({ ...values, emailValid: false, code: value });
    } else {
      setValues({ ...values, emailValid: true });
    }

    setCodeIsValid(validated);
  };

  const next = async () => {
    setTouched(true);
    validateField(values.code);

    if (codeIsValid && !makingRequest) {
      // try get auth token with email and email auth code
      setMakingRequest(true);

      const { token } = await Session.requestToken(
        brand,
        data.email,
        values.code
      ).catch((err) => {
        alert(
          `Incorrect authentication code: [${values.code}]. Your code may have expired or been entered incorrectly. You will be redirected back to the email screen to generate a new code. If you did not receive an email then please check you have entered the correct email address.`
        );
        // redirecting back to email so user can get token again
        history.replace('/');
        return {};
      });

      setMakingRequest(false);

      // exit on no token
      if (!token) {
        console.error('Error getting auth token');
        return;
      }

      // otherwise store token
      localStorage.setItem(AUTHORISATION_TOKEN_STORAGE_KEY, token);
      // get form data
      localStorage.removeItem(FORM_ID_STORAGE_KEY);
      const formData = await formDataFromStorage();
      // log in with form data
      userLoggedIn(formData, dispatch);
      localStorage.setItem('@authentication', 'true');
    }
  };

  const showCodeInputError = touched && !codeIsValid;

  return (
    <div className={classes.root}>
      <h1 data-testid={"code-heading"}>Did you get the verification code?</h1>
      <p>
        Check your email for the unique code (you may need to check your junk
        folder)
      </p>
      <h6>Please enter your code:</h6>
      <FormControl
        error={showCodeInputError}
        className={clsx(classes.margin, classes.textField, classes.width)}
        variant="outlined"
      >
        <InputLabel error={showCodeInputError} htmlFor="filled-start-adornment">
          Code
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-code"
          value={values.email}
          type={values.sendEmail ? 'text' : 'email'}
          onChange={handleChange('code')}
          labelWidth={70}
          onKeyUp={(event) => event.key === 'Enter' ? next() : null}
        />
        {showCodeInputError && (
          <p style={{ color: theme(brand).color.warn }}>
            Please enter the authentication code from your email.
          </p>
        )}
      </FormControl>
      <div className={classes.button}>
        <StyledButton id={"code-next"} theme={brand} variant="contained" onClick={() => next()}>
          Next
        </StyledButton>
      </div>
    </div>
  );
}
