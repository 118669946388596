import { get, post, put } from './base.js';
import _ from 'lodash';

export const Forms = {
  listAll: () => get('/current-applicant/forms'),
  getById: async (formId) =>
    get(`/current-applicant/forms/${formId}`).then(({ data }) => data),
  getLatest: async () =>
    get('/current-applicant/forms/latest').then(({ data }) => data),
  create: () => post('/current-applicant/forms').then(({ data }) => data),
  update: (id, formData) => {
    const submissionStatus = formData && formData.submissionStatus;
    if (submissionStatus !== 'DRAFT') {
      return;
    }

    return put(`/current-applicant/forms/${id}`, {
      formData: tagVersion(normaliseFormData(formData)),
    });
  },
  submit: (id) => post(`/current-applicant/forms/${id}/submit`),
};

// Form data custom normaliser
// this is to used to normalise form data being updated to back-end api
const normaliseFormData = (formData) => {
  // make sure we mutate on cloned data
  const data = _.cloneDeep(formData);

  // make sure invalid income divisions are null, so they can be sorted properly
  const { income } = data;
  if (income.division && income.division.id === 'na') {
    income.division = income.sub_division = '';
  }

  // return cloned, mutated form data
  return data;
};

const tagVersion = (formData) => {
  formData.frontendVersion = 3
  return formData;
}
