import decode from 'jwt-decode';
import { Forms } from '../api';
import { AUTHORISATION_TOKEN_STORAGE_KEY, brandFromHost, FORM_ID_STORAGE_KEY, FORM_PERSIST_KEY } from '../utilities';

export const formDataFromStorage = async () => {
  // try get auth token
  const token = await localStorage.getItem(AUTHORISATION_TOKEN_STORAGE_KEY);
  // return data without form data when no token
  if (!token) {
    return {
      hydrating: false,
    };
  }

  const hostBrand = brandFromHost(window.location.hostname);
  let form;

  // First: get form data from api with form id
  const formId = await localStorage.getItem(FORM_ID_STORAGE_KEY);
  if (formId) {
    // @todo check for 404 error
    const fetchedForm = await Forms.getById(formId).catch(() => undefined);
    if (decode(fetchedForm.formData.token).brand === hostBrand) {
      form = fetchedForm;
    }
  }

  if (!form) {
    form = await Forms.getLatest()
      .catch((reason) => {
          console.log(reason);
        },
      );
  }

  if (!form) {
    form = await Forms.create();
  }

  const data = { token, hydrating: false };

  if (form.id) {
    localStorage.setItem(FORM_ID_STORAGE_KEY, form.id);
    const { formData, submissionStatus } = form;
    return {
      ...data,
      ...formData,
      submissionStatus,
      id: form.id,
    };
  } else {
    return data;
  }
};

export const storage = {
  async getItem() {
    const formData = await formDataFromStorage();

    return formData
      ? JSON.stringify({ [FORM_PERSIST_KEY]: JSON.stringify(formData) })
      : null;
  },

  async setItem(key, state) {
    const { user } = JSON.parse(state);
    const token = await localStorage.getItem(AUTHORISATION_TOKEN_STORAGE_KEY);
    const formId = await localStorage.getItem(FORM_ID_STORAGE_KEY);
    const hasBeenSubmitted = await localStorage.getItem("submitted")
    return user && token && formId && !hasBeenSubmitted && Forms.update(formId, JSON.parse(user));
  },

  async removeItem(key) {
    console.error('Cannot remove form');
  },
};
