import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_INCOME_SECOND_DATA} from '../../../../state/ducks/user/types';
import {hasStringValue} from '../../../utils';
import FormGroupButtons from '../../FormGroupButtons';
import Incomes from "./incomes";
import {isAllCurrencyValidationStatus, isOtherIncomePairValidationStatus} from "../utils";
import {initialState} from "../../../../state/ducks/user/reducers";

const FG_NewIncomeSecond = (props) => {
  const userData = useSelector((store) => store.user);
  const [values, setValues] = React.useState({ ...userData.incomeSecond });
  const dispatch = useDispatch();

  const secondUserName = _.get(userData, 'begin.secondUserName', '');
  const commercialLoan = _.get(userData, 'loan.isLoanCommercial', 'false');
  const isCommercialLoan = commercialLoan === 'true';
  const secondUserTitle = isCommercialLoan
    ? 'Additional Director/Guarantor:'
    : 'Additional Borrower:';


  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(userData))
    const payload = initialState.incomeSecond;

    _.merge(payload, userData.incomeSecond);
    userData.incomeSecond = payload;
    userData.background_hardship.medical_certification_file = copy.background_hardship.medical_certification_file;
    userData.incomeSecond.other_documents = copy.incomeSecond.other_documents;
    userData.incomeSecond.centrelink_statement = copy.incomeSecond.centrelink_statement;
    userData.incomeSecond.income_tax_assessment = copy.incomeSecond.income_tax_assessment;
    userData.incomeSecond.notice_assessment = copy.incomeSecond.notice_assessment;
    userData.incomeSecond.payslip_file = copy.incomeSecond.payslip_file;
    userData.incomeSecond.business_activity_assessment = copy.incomeSecond.business_activity_assessment;
    userData.incomeSecond.bank_statement = copy.incomeSecond.bank_statement;
    userData.incomeSecond.other_income_statement = copy.incomeSecond.other_income_statement;
  }, [0])

  useEffect(() => {
    setValues({ ...userData.incomeSecond });
  }, [userData.incomeSecond]);

  /**
   * Form fields handlers
   */

  const setValue = (key, value) => {
    // base data to dispatch
    dispatch({
      type: SET_INCOME_SECOND_DATA,
      payload: {
          [key]: value
      }
    });
  };

  /**
   * Validation
   */

  const validateFormGroup = () => {
      return hasStringValue(values.after_tax_income) && isAllCurrencyValidationStatus(values) && isOtherIncomePairValidationStatus(values.other_incom_specify_type_first_txt, values.other_incom_specify_type_first_price) && isOtherIncomePairValidationStatus(values.other_incom_specify_type_second_txt, values.other_incom_specify_type_second_price);
  };

  /**
   * Navigation handlers
   */

  const onPrev = (e) => {
    if (typeof props.prev === 'function') {
      props.prev(e);
    }
  };

  const onNext = (e) => {
    if (validateFormGroup() && typeof props.next === 'function') {
      props.next(e);
    }
  };

  /**
   * View
   */

  return (
    <>
      <h1>
        {secondUserTitle}{' '}
        {hasStringValue(secondUserName) ? `${secondUserName}'s` : ''} total
        monthly income after taxes, from all sources before your situation
        changed
      </h1>
      <Incomes income={values} setValue={setValue}/>
      <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
    </>
  );
};

export default FG_NewIncomeSecond;
