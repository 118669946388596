import styled from 'styled-components';

export const HorizontalDataRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .question {
    max-width: calc(100% - 120px);
  }
`;

export const HorizontalDataTitleRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
  ​.question-title {
    max-width: calc(100% - 120px);
  }
`;

export const SectionDivider = styled.hr`
  display: block;
  width: 100%;
  height: 0;
  line-height: 0;
  margin: 24px auto;
  overflow: hidden;
  border: none;
  border-top: 3px solid #ccc;
`;

export const SubSectionDivider = styled.hr`
  display: block;
  width: 100%;
  height: 0;
  line-height: 0;
  margin: 12px auto;
  overflow: hidden;
  border: none;
  border-top: 1px solid #ccc;
`;
