export const WHY_APPLY_HARDSHIP_OPTIONS = [
  { value: 'Accident', id: 'accident' },
  { value: 'Death', id: 'death' },
  { value: 'Illness', id: 'illness' },
  { value: 'Injury', id: 'injury' },
  { value: 'Marital Breakdown', id: 'marital_breakdown' },
  { value: 'Natural Disaster', id: 'natural_disaster'},
  { value: 'Over Commitment', id: 'over_commitment'},
  { value: 'Pregnancy', id: 'pregnancy'},
  { value: 'Unemployed', id: 'unemployed'},
  { value: 'Unforeseen Business Issues', id: 'unforeseen_business_issues'},
  { value: 'Reduction of income', id: 'reduction_of_income'},
  { value: 'COVID-19', id: 'covid_19'}
];

export const NATURAL_DISASTER_OPTIONS = [
  {value: 'Flood', id: 'natural_disaster_flood'},
  {value: 'Bushfire', id: 'natural_disaster_bushfire'},
  {value: 'Cyclone', id: 'natural_disaster_cyclone'},
  {value: 'Storm', id: 'natural_disaster_storm'},
  {value: 'Earthquake', id: 'natural_disaster_earthquake'},
  {value: 'Drought', id: 'natural_disaster_drought'}
]

export const EMPLOYMENT_OPTIONS = [
  { value: 'Employed', id: 'employed' },
  { value: 'Self-Employed', id: 'self_employed' },
  { value: 'Not Employed', id: 'not_employed' },
];

export const EMPLOYMENT_EMPLOYED_OPTIONS = [
  { value: 'Permanent Full-time', id: 'permanent_full_time' },
  { value: 'Permanent Part-time', id: 'permanent_part_time' },
  { value: 'Casual', id: 'casual' },
  { value: 'Seasonal', id: 'seasonal' },
];

export const EMPLOYMENT_NOT_EMPLOYED_OPTIONS = [
  { value: 'Student', id: 'student' },
  { value: 'Unemployed', id: 'unemployed' },
  { value: 'Retired', id: 'retired' },
];

export const MARITAL_STATUS = [
  { value: 'Married', id: 'married' },
  { value: 'Single', id: 'single' },
  { value: 'De-facto', id: 'de_facto' },
];

export const PROPERTY_TYPE = [
  { value: 'Select Property Type', id: '*' },
  { value: 'Primary Residence', id: 'primary_residence' },
  { value: 'Other Residence', id: 'other_residence' },
  { value: 'Investment', id: 'investment' },
  { value: 'Commercial', id: 'commercial' },
  { value: 'Farm', id: 'farm' },
];

export const MORTGAGE_TYPE = [
  { value: 'Select Mortgage Type', id: '' },
  { value: 'Primary Residence', id: 'primary_residence' },
  { value: 'Other Residence', id: 'other_residence' },
  { value: 'Investment', id: 'investment' },
  { value: 'Commercial', id: 'commercial' },
  { value: 'Farm', id: 'farm' },
];

export const SECURED_LOAN = [
  { value: 'Select Personal Loan Type', id: '' },
  { value: 'Yes', id: 'true' },
  { value: 'No', id: 'false' },
];
