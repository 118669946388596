import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {some} from 'lodash/fp';
import {hasStringValue} from '../../../utils';
import {useFormGroupsStyles} from '../styles';
import FileUpload from '../../FormElements/FileUpload';
import FormGroupButtons from '../../FormGroupButtons';
import Link from '@material-ui/core/Link';
import {
    SET_BACKGROUND_HARDSHIP_DATA,
    SET_INCOME_DATA,
    SET_INCOME_SECOND_DATA,
} from '../../../../state/ducks/user/types';
import {MEDICAL_CERTIFICATE,} from '../../../../utilities/documentTypes';
import Documents from "./documents";


function FG_DocumentWallet(props) {
    const {metadata} = props;
    const userData = useSelector((store) => store.user);

    const brandInfo = useSelector((store) => store.brandInfo.brandInfo);
    const {
        config: {hardshipEmail},
    } = brandInfo;

    const [backgroundValues, setBackgroundValues] = React.useState({
        ...userData.background_hardship,
    });

    const secondUserName = _.get(userData, 'begin.secondUserName', '');

    const [incomeValues, setIncomeValues] = React.useState({
        ...userData.income,
    });

    const [incomeSecondValues, setIncomeSecondValues] = React.useState({
        ...userData.incomeSecond,
    });

    const [borrowerOne, setBorrowerOne] = React.useState([]);
    const [borrowerTwo, setBorrowerTwo] = React.useState([]);

    useEffect(() => {
        setBackgroundValues({...userData.background_hardship});

        //set other_documents if not present
        if (!userData.income.hasOwnProperty('other_documents')) {
            userData.income.other_documents = [];
        }
        if (userData.begin.isSecondBorrower === 'true' && !userData.incomeSecond.hasOwnProperty('other_documents')) {
            userData.incomeSecond.other_documents = [];
        }

        setIncomeValues({...userData.income});
        setIncomeSecondValues({...userData.incomeSecond});
        validateBorrowerData({...userData.income}, setBorrowerOne);
        validateBorrowerData({...userData.incomeSecond}, setBorrowerTwo)
    }, [userData.background_hardship, userData.income, userData.incomeSecond]);

    const dispatch = useDispatch();

    const validateBorrowerData = (incomeValues, setStateCallback) => {
        let data = [];
        let metaArray = metadata.documents;
        metaArray.forEach((value) => {
            if (_.includes(value, 'PAYSLIP')) {
                data.push(incomeValues.payslip_file);
            } else if (_.includes(value, 'BANK_STATEMENT')) {
                data.push(incomeValues.bank_statement);
            } else if (_.includes(value, 'INCOME_TAX')) {
                data.push(incomeValues.income_tax_assessment);
            } else if (_.includes(value, 'NOTICE_OF_ASSESSMENT')) {
                data.push(incomeValues.notice_assessment);
            } else if (_.includes(value, 'BUSINESS_ACTIVITY')) {
                data.push(incomeValues.business_activity_assessment);
            } else if (_.includes(value, 'CENTRELINK_STATEMENT')) {
                data.push(incomeValues.centrelink_statement);
            } else if (_.includes(value, 'OTHER_INCOME_STATEMENT')) {
                data.push(incomeValues.other_income_statement);
            }
        });

        setStateCallback(data);
    };

    // dispatch
    const dispatchData = (data, type) => {
        dispatch({
            type: type,
            payload: data,
        });
    };

    const setValue = (key, value, type) => {
        const data = {
            [key]: value,
        };

        dispatchData(data, type);
    };

    const documentUiValidate = (key, payload) => {
        return !!(payload &&
            payload.documents &&
            payload.documents.includes(key));
    };

    const documentUiTitleBorrowerValidate = (payload, postfix) => {
        return !!(payload &&
            payload.documents &&
            (payload.documents.includes(`PAYSLIP_BORROWER_${postfix}`) ||
                payload.documents.includes(`BANK_STATEMENT_BORROWER_${postfix}`) ||
                payload.documents.includes(`BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_${postfix}`) ||
                payload.documents.includes(`CENTRELINK_STATEMENT_BORROWER_${postfix}`) ||
                payload.documents.includes(`INCOME_TAX_ASSESSMENT_BORROWER_${postfix}`) ||
                payload.documents.includes(`NOTICE_OF_ASSESSMENT_BORROWER_${postfix}`) ||
                payload.documents.includes(`OTHER_INCOME_STATEMENT_BORROWER_${postfix}`)));
    };

    const hasBorrowerUploadedAFile = (borrower) =>
        some((value) => {
            return (
                (value && value.filename) ||
                (Array.isArray(value) && value[0] && value[0].filename)
            );
        })(borrower);

    const hasBorrowerUploadedAMedicalFile = (backgroundValues) => {
        let isValid = true;
        if(backgroundValues){
            const medicalFile = backgroundValues.medical_certification_file;

            if(medicalFile) {
                if (Array.isArray(medicalFile)) {
                    if (medicalFile.length === 0) {
                        isValid = false;
                    }
                } else if (!medicalFile.hasOwnProperty("filename") || !hasStringValue(medicalFile.filename)) {
                    isValid = false;
                }
            }
        } else{
            isValid = false;
        }

        return isValid;
    }

    // validation
    const validationUploadGroup = () => {
        let isValid = true;

        // new condition for validate each upload group
        if (!hasBorrowerUploadedAMedicalFile(backgroundValues) && documentUiValidate(MEDICAL_CERTIFICATE, metadata)) {
            isValid = false;
        }

        if (isValid && !hasBorrowerUploadedAFile(borrowerOne) && documentUiTitleBorrowerValidate(metadata, '1')) {
            isValid = false;
        }

        if (isValid && !hasBorrowerUploadedAFile(borrowerTwo) && documentUiTitleBorrowerValidate(metadata, '2')) {
            isValid = false;
        }

        return isValid;
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };
    const onNext = (e) => {
        if (validationUploadGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    return (
        <>
            <h4>
                Please provide the following documents to assist in assessing your
                application for hardship.
            </h4>
            <p>
                Please ensure that you have attached all the required supporting
                documentation before submitting your application. Once you have
                submitted your application you will be unable to change or attach new
                documents to your application via this portal. Any missing or additional
                documentation will need to be emailed to us at{' '}
                <a
                    href={`mailto:${hardshipEmail}`}
                    style={{fontSize: 'inherit', paddingBottom: 'initial'}}
                >
                    {hardshipEmail}
                </a>
                , and may delay our ability to assess you application.
            </p>
            <p>
                <Link href="/faq" style={{marginTop: '20px'}}>
                    Information about the documents you need to provide
                </Link>
            </p>

            {documentUiValidate(MEDICAL_CERTIFICATE, metadata) && (
                <>
                    <h5>Your Medical Documents</h5>
                    <FileUpload
                        id="medical_certification_file"
                        testAutomationId="general-medical_certification_file"
                        title="Upload Medical Certificate or supporting documentation"
                        type={SET_BACKGROUND_HARDSHIP_DATA}
                        required={true}
                        documentType={MEDICAL_CERTIFICATE}
                        value={backgroundValues.medical_certification_file}
                        setValue={setValue}
                        setCanSaveData={props.setCanSaveData}
                    />
                </>
            )}
            {/* Borrower One */}
            {documentUiTitleBorrowerValidate(metadata, '1') && (
                <>
                    <h5>Your Income Documents</h5>
                    <p>
                        We need evidence to verify the income you have told us. By providing
                        this documentation we can review your application faster. Please
                        provide documents in the following categories that will best support
                        your application.
                    </p>
                </>
            )}
            {!hasBorrowerUploadedAFile(borrowerOne) && documentUiTitleBorrowerValidate(metadata, '1') && (
                <h6>Please provide at least one of the following documents</h6>
            )}
            <Documents setValue={setValue}
                       type={SET_INCOME_DATA}
                       documentUiValidate={documentUiValidate}
                       incomeValues={incomeValues}
                       metadata={metadata} postfix={"1"}
                       setCanSaveData={props.setCanSaveData}/>

            {/* Borrower Two */}
            {documentUiTitleBorrowerValidate(metadata, '2') && (
                <>
                    <h5>
                        {hasStringValue(secondUserName) ? `${secondUserName}'s` : ''} Income
                        Documents
                    </h5>
                    <p>
                        We need evidence to verify the income you have told us. By providing
                        this documentation we can review your application faster. Please
                        provide documents in the following categories that will best support
                        your application.
                    </p>
                </>
            )}
            {!hasBorrowerUploadedAFile(borrowerTwo) && documentUiTitleBorrowerValidate(metadata, '2') && (
                <h6>Please provide at least one of the following documents</h6>
            )}
            <Documents setValue={setValue}
                       type={SET_INCOME_SECOND_DATA}
                       documentUiValidate={documentUiValidate}
                       incomeValues={incomeSecondValues}
                       metadata={metadata} postfix={"2"}
                       setCanSaveData={props.setCanSaveData}/>

            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validationUploadGroup}{...props} />
        </>
    );
}

export default FG_DocumentWallet;
