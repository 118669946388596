import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: '10px',
    //marginBottom: '10px',
    minWidth: '100%',
    maxWidth: '415px',
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);
  const labelId = `select-${props.id}-label`;
  const instanceId = props.testAutomationId || 'simple-select';

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.setValue(props.id, event.target.value);
  };

  return (

      <FormControl className={classes.formControl}>
        <InputLabel id={labelId}>{props.title}</InputLabel>
        <Select
          labelId={labelId}
          id={instanceId}
          value={value}
          onChange={handleChange}
          data-testid={props.id}
        >
          {props.items.map((item, index) => {
            return (
              <MenuItem value={item.id} key={index}>
                {item.value}
              </MenuItem>
            );
          })}
        </Select>

        {props.required && <FormHelperText id="standard-helper-text" data-testid={props.id + '_standard-helper-text'}>*Required</FormHelperText>}
      </FormControl>
  );
}
