/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function Playground(props) {
  const defaultProps = {
    options: props.data,
    getOptionLabel: (option) => option.title ? option.title : '',
  };

  const [value, setValue] = React.useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event, value) => {
    props.setValue(props.id, value);
  };

  return (
    <div style={{ width: '100%'}}>
      <Autocomplete
        {...defaultProps}
        id={props.id}
        value={value}
        data-testid={props.id}
        onBlur={(evt) => {
          const found = defaultProps.options.find(option => option.title === evt.target.value);
          if(!found){
            props.setValue(props.id, '');
          }}
        }
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={props.title} margin="normal" id={'input-'+props.id} />
        )}
      />
      {props.required &&
        <FormHelperText id="standard-helper-text" data-testid={props.id+'-standard-helper-text'}>*Required</FormHelperText>
      }
    </div>
  );
}
