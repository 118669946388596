/**
 * Form group id constants
 */
const ABOUT_YOU = 'ABOUT_YOU';
const NEW_BACKGROUND_TO_HARDSHIP = 'NEW_BACKGROUND_TO_HARDSHIP';
const RETURNING_BACKGROUND_TO_HARDSHIP = 'RETURNING_BACKGROUND_TO_HARDSHIP';

const INCOME_BORROWER_1 = 'INCOME_BORROWER_1';
const INCOME_BORROWER_2 = 'INCOME_BORROWER_2';
const EXPENSES = 'EXPENSES';
const ASSETS = 'ASSETS';
const LIABILITIES = 'LIABILITIES';
const DOCUMENT_WALLET = 'DOCUMENT_WALLET';
const REVIEW = 'REVIEW';


/**
 * This is a constant object containing all form group id from back-end api
 */
export const FORM_GROUPS = {
    // about you
    ABOUT_YOU,

    // background hardship
    NEW_BACKGROUND_TO_HARDSHIP,
    RETURNING_BACKGROUND_TO_HARDSHIP,

    // income
    INCOME_BORROWER_1,
    INCOME_BORROWER_2,

    // expenses
    EXPENSES,

    // assets/liabilities
    ASSETS,
    LIABILITIES,

    // utils
    DOCUMENT_WALLET,

    // preview
    REVIEW,
};
