import { makeStyles } from '@material-ui/core/styles';

export const useFormGroupsStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  formRegisterControl: {
    width: '100%',
  },
  root: {
    '& .MuiTextField-root': {
      marginTop: '32px',
      maxWidth: '415px',
      width: '415px',
      minWidth: '200px',
    },
  },
  button: {
    width: '100%',
    '& .MuiButtonBase-root': {
      width: '100%',
      borderRadius: '20px',
    },
  },
  color: {
    backgroundColor: '#eb0a1e',
  },
  option: {
    marginTop: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
    height: '60px',
    backgroundColor: '#ebebec',
    borderRadius: '3px;',
    '&:hover': {
      background: '#fff',
      boxShadow: '1px 2px 6px 0 rgba(34,34,34,.15)',
    },
  },
  acknowledgeCheckbox: {
    'label&': {
      alignItems: 'start',
      height: 'auto',
      '& span.MuiCheckbox-root': {
        paddingTop: '0',
        paddingLeft: '0',
      },
    },
  },
  padding: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },

  noPadding:{
    padding: '0'
  },

  marginTop: {
    marginTop: '10px'
  },

  marginBottom: {
    marginBottom: '10px'
  },

  paddingBottom:{
    paddingBottom:'10px'
  },

  paddingTop:{
    paddingTop:'10px'
  }
}));
