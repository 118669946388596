import * as types from './types';

export const userLoggedIn = (formData, dispatch) =>
  dispatch({
    type: types.SIGN_IN,
    formData,
  });

export const setEmail = (email, dispatch) =>
  dispatch({
    type: types.SET_EMAIL,
    payload: email,
  });
