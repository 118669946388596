import { post } from './base';

const FORM_FLOW_BASE_ENDPOINT = '/current-applicant/form-flow/';

/**
 * callFormFlowApi
 * @param targetGroup - target group endpoint
 * @param formId - form record id
 * @returns API call Promise
 */
const callFormFlowApi = (targetGroup = 'current', formId = '') => {
  const requestUrl = `${FORM_FLOW_BASE_ENDPOINT}${targetGroup}`;
  const data = {
    submissionId: formId,
  };
  return post(requestUrl, { ...data }).then(({ data }) => data).catch((error) => {
    const status = error.response.status
    if (status === 400) {
      const message = error.response.data
      
      if (message === "Application has already been submitted.") {
        window.location.href = window.location.origin + '/submitted'
      }
    }
  });
};

export const FormFlowApi = {
  getCurrent: (formId) => {
    return callFormFlowApi('current', formId);
  },
  getPrev: (formId) => {
    return callFormFlowApi('back', formId);
  },
  getNext: (formId) => {
    return callFormFlowApi('next', formId);
  },
};
