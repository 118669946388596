import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_LOAN_DATA} from '../../../../state/ducks/user/types';
import {foundSubDivision, setFieldValue} from '../utils';
import {useFormGroupsStyles} from '../styles';
import FormControl from '@material-ui/core/FormControl';
import divisions from '../../../utils/divisions';
import AutoComplete from '../../AutoComplete';
import CodeInput from '../../FormElements/CodeInput';
import TextInput from '../../FormElements/TextInput';
import YesNo from "../../FormElements/YesNo";
import DivisionAndSubdivision from "../../FormElements/DivisionAndSubdivision";

function Loan() {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({...userData.loan});
    const [divisionMenu, setDivisionMenu] = React.useState([]);
    const [subDivisionMenu, setSubDivisionMenu] = React.useState([]);

    const classes = useFormGroupsStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        let temp = [];
        divisions.map((item) => {
            temp.push({title: item.title, id: item.id});
        });
        setDivisionMenu(_.sortBy(temp, 'title'));
        if (values.division) {
            //handle the case of invalid data set on the back end
            const found = divisions.find(item => item.id === values.division.id);
            if (!found) {
                dispatch({
                    type: SET_LOAN_DATA,
                    payload: {
                        division: '',
                        sub_division: ''
                    }
                });
            } else {
                divisions.map((item) => {
                    if (item.id === values.division.id) {
                        setSubDivisionMenu(_.sortBy(item.children, 'title'));
                    }
                });
            }
        } else {
            setSubDivisionMenu([]);
        }
    }, [values.division]);

    useEffect(() => {
        setValues({...userData.loan});
    }, [userData.loan]);


    /**
     * Form fields handlers
     */

    const handleRadioChange = (event) => {
        dispatch({
            type: SET_LOAN_DATA,
            payload: {
                isLoanCommercial: event.target.value,
                company_name: '',
                ABN: '',
                isCompanyRegistered: '',
                division: '',
                sub_division: ''
            }
        });
    };

    const handleRegisterRadioChange = (event) => {
        dispatch({
            type: SET_LOAN_DATA,
            payload: {
                isCompanyRegistered: event.target.value,
                division: '',
                sub_division: ''
            }
        });
    };

    const setValue = (key, value) => {
        if (key === 'division') {
            dispatch({
                type: SET_LOAN_DATA,
                payload: {
                    division: value,
                    sub_division: ''
                }
            });
        } else {
            setFieldValue(SET_LOAN_DATA, dispatch, key, value);
        }
    };

    /**
     * View
     */

    return (
        <>
            <YesNo title={<h5>Is your loan a commercial loan?</h5>} id={"isLoanCommercial"}
                   value={values.isLoanCommercial}
                   onChange={handleRadioChange} yesComponent={
                <>
                    <div className={classes.padding}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <h1>ABN:</h1>
                            <CodeInput
                                autoFocus={false}
                                fields={11}
                                id="ABN"
                                setValue={setValue}
                                value={values.ABN}
                            />
                        </FormControl>
                    </div>
                    <TextInput
                        noFormTag={true}
                        title="Company or Business Name"
                        id="company_name"
                        setValue={setValue}
                        type={"text"}
                        value={values.company_name}
                        required={!values.company_name}
                    />
                    <YesNo title={<h5>Is the company or business still registered and trading?</h5>}
                           id={"isCompanyRegistered"}
                           value={values.isCompanyRegistered}
                           onChange={handleRegisterRadioChange}
                           yesComponent={
                               <DivisionAndSubdivision divisionTitle={<h5>Which industry are you in?</h5>}
                                                       subDivisionTitle={<h5>Which industry sub division are you in?</h5>}
                                                       divisions={divisionMenu}
                                                       subDivisions={subDivisionMenu}
                                                       division={values.division}
                                                       subDivision={values.sub_division}
                                                       setValue={setValue}
                                                       divisionReqFn={!values.division || !divisions.find(item => item.id === values.division.id)}
                                                       subDivisionReqFn={!foundSubDivision(values)}/>

                           }
                    />
                </>
            }/>
        </>
    );
};

export default Loan;
