import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HorizontalDataRow, HorizontalDataTitleRow, SectionDivider, SubSectionDivider } from './style';
import { MARITAL_STATUS, MORTGAGE_TYPE, PROPERTY_TYPE } from '../constants';
import {
  A_INDICATE_ASSISTANCE,
  Q_ABOUT,
  Q_ASSETS,
  Q_ASSETS_PROPERTY,
  Q_ASSETS_VEHICLE,
  Q_EXPENSES,
  Q_HARDSHIP,
  Q_INCOME,
  Q_INCOME_2,
  Q_LIABILITIES,
  Q_LIABILITIES_CC,
  Q_LIABILITIES_LOAN,
  Q_LIABILITIES_MORTGAGE,
} from './questions';
import { convertDate } from '../../../../utilities';
import { convertBoolean, findValueFromId, isAnswerDefined, renderUploadedFileAnswers } from './utils';
import FormGroupButtons from '../../FormGroupButtons';
import {
  BANK_STATEMENT_BORROWER_1,
  BANK_STATEMENT_BORROWER_2,
  BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_1,
  BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_2,
  CENTRELINK_STATEMENT_BORROWER_1,
  CENTRELINK_STATEMENT_BORROWER_2,
  INCOME_TAX_ASSESSMENT_BORROWER_1,
  INCOME_TAX_ASSESSMENT_BORROWER_2,
  MEDICAL_CERTIFICATE,
  NOTICE_OF_ASSESSMENT_BORROWER_1,
  NOTICE_OF_ASSESSMENT_BORROWER_2, OTHER_DOCUMENTS_BORROWER_1, OTHER_DOCUMENTS_BORROWER_2,
  OTHER_INCOME_STATEMENT_BORROWER_1,
  OTHER_INCOME_STATEMENT_BORROWER_2,
  PAYSLIP_BORROWER_1,
  PAYSLIP_BORROWER_2,
} from '../../../../utilities/documentTypes';

const FG_Review = (props) => {
  const brandInfo = useSelector((store) => store.brandInfo.brandInfo);
  const {
    config: { hardshipEmail, complaintsPhone, privacyPolicyUrl },
    name: hardshipName,
  } = brandInfo;

  // uses form data returned by API
  // with sanitization and business logic applied
  console.log(props)
  const formData = _.get(props, 'metadata.formData', {});
  const requiredDocuments = _.get(props, 'metadata.documents', []);

  const FORM_ABOUT = formData.begin || null;
  const FORM_LOAN = formData.loan || null;
  const FORM_HARDSHIP = formData.background_hardship || null;
  const FORM_INCOME = formData.income || null;

  const FORM_INCOME_2 = formData.incomeSecond || null;
  console.log('FORM_INCOME_2', formData.incomeSecond)

  const FORM_EXPENSES = formData.expenses || null;
  const FORM_ASSETS = formData.assets || null;
  const FORM_LIABILITIES = formData.liabilities || null;


  // display flags
  const displayAboutYou = FORM_ABOUT !== null && FORM_LOAN !== null;
  const displayHardship = FORM_HARDSHIP !== null && FORM_LOAN !== null;
  const displayIncome = FORM_INCOME !== null;
  const displayIncome2 =
    FORM_INCOME_2 !== null &&
    FORM_ABOUT !== null &&
    FORM_LOAN !== null &&
    FORM_ABOUT.isSecondBorrower === 'true';
  const displayExpenses = FORM_EXPENSES !== null;
  const displayAssets = FORM_ASSETS !== null;
  const displayLiabilities = FORM_LIABILITIES !== null;

  useEffect(() => {
    localStorage.setItem('@review_submit', 'false');
  }, []);

  /**
   * Navigation handlers
   */

  const onPrev = (e) => {
    if (typeof props.prev === 'function') {
      props.prev(e);
    }
  };
  const onSubmit = (e) => {
    if (typeof props.submit === 'function') {
      props.submit();
      localStorage.setItem('@review_submit', 'true');
    }
  };

  /**
   * View
   */

  const QuestionAnswerGroups = (props) => {
    const {
      title,
      items,
      questionsKeys,
      questionTitles,
      noDataAnswer = 'NO DATA',
      showNilAnswer = false,
      answerTemplates,
    } = props;
    const hasData = Array.isArray(items) && !!items.length;

    return (
      <>
        <h4 className="question-title">{title}</h4>
        {hasData ? (
          items.map((item, i) => {
            return (
              <div className="question-answer-group" key={i}>
                <>
                  {questionsKeys.map((questionKey, j) => {
                    const answer = item[questionKey];
                    const hasAnswer = isAnswerDefined(answer);
                    const showAnswer = hasAnswer || showNilAnswer;
                    const hasAnswerTemplate =
                      !!answerTemplates && !!answerTemplates[questionKey];

                    return showAnswer ? (
                      <HorizontalDataRow key={j}>
                        <h4 className="question">
                          {questionTitles[questionKey]}
                        </h4>
                        <h4 className="answer">
                          {hasAnswer
                            ? hasAnswerTemplate
                              ? answerTemplates[questionKey](answer)
                              : answer
                            : '--'}
                        </h4>
                      </HorizontalDataRow>
                    ) : null;
                  })}
                  <SubSectionDivider/>
                </>
              </div>
            );
          })
        ) : (
          <h4 className="answer">{noDataAnswer}</h4>
        )}
      </>
    );
  };

  function renderDocument(documentName, documentType, value) {
    return requiredDocuments.includes(documentType) && (
      <>
        <h4 className="question">{documentName}:</h4>
        {renderUploadedFileAnswers(value)}
      </>
    );
  }

  return (
    <>
      <h1>
        Please take a moment to review the information that you have provided to
        us. The more accurate and detailed the information that you provide, the
        easier and quicker we will be able to assess your application.
      </h1>

      {displayAboutYou && (
        <>
          <h5 className="red_title">About you, your loan and your situation</h5>
          <SectionDivider/>
          {FORM_ABOUT.isContractNumber === 'true' &&
          isAnswerDefined(FORM_ABOUT.contractNumber) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.contractNumber.title}</h4>
              <h4 className="answer">{FORM_ABOUT.contractNumber}</h4>
            </HorizontalDataRow>
          )}
          {FORM_ABOUT.isContractNumber === 'false' &&
          isAnswerDefined(FORM_ABOUT.registrationNumber) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.registrationNumber.title}</h4>
              <h4 className="answer">{FORM_ABOUT.registrationNumber}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ABOUT.isGotAll) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.isGotAll.title}</h4>
              <h4 className="answer">{convertBoolean(FORM_ABOUT.isGotAll)}</h4>
            </HorizontalDataRow>
          )}
          {FORM_ABOUT.isGotAll === 'true' && (
            <>
              {isAnswerDefined(FORM_ABOUT.contractNumbers) && (
                <HorizontalDataRow>
                  <h4 className="question">{Q_ABOUT.contractNumbers.title}</h4>
                  <h4 className="answer">{FORM_ABOUT.contractNumbers}</h4>
                </HorizontalDataRow>
              )}
              {isAnswerDefined(FORM_ABOUT.registrationNumbers) && (
                <HorizontalDataRow>
                  <h4 className="question">
                    {Q_ABOUT.registrationNumbers.title}
                  </h4>
                  <h4 className="answer">{FORM_ABOUT.registrationNumbers}</h4>
                </HorizontalDataRow>
              )}
            </>
          )}
          {isAnswerDefined(FORM_ABOUT.userName) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.userName.title}</h4>
              <h4 className="answer">{FORM_ABOUT.userName}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ABOUT.address) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.address.title}</h4>
              <h4 className="answer">{FORM_ABOUT.address}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ABOUT.phoneNumber) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.phoneNumber.title}</h4>
              <h4 className="answer">{FORM_ABOUT.phoneNumber}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ABOUT.DOB) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.DOB.title}</h4>
              <h4 className="answer">{convertDate(FORM_ABOUT.DOB)}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ABOUT.isSecondBorrower) && (
            <HorizontalDataTitleRow>
              <h4 className="question-title">
                {Q_ABOUT.isSecondBorrower.title}
              </h4>
              <h4 className="answer-title">
                {convertBoolean(FORM_ABOUT.isSecondBorrower)}
              </h4>
            </HorizontalDataTitleRow>
          )}
          {FORM_ABOUT.isSecondBorrower === 'true' && (
            <>
              <HorizontalDataRow>
                <h4 className="question">{Q_ABOUT.secondUserName.title}</h4>
                <h4 className="answer">{FORM_ABOUT.secondUserName}</h4>
              </HorizontalDataRow>
              <HorizontalDataRow>
                <h4 className="question">{Q_ABOUT.secondAddress.title}</h4>
                <h4 className="answer">{FORM_ABOUT.secondAddress}</h4>
              </HorizontalDataRow>
              <HorizontalDataRow>
                <h4 className="question">{Q_ABOUT.secondPhoneNumber.title}</h4>
                <h4 className="answer">{FORM_ABOUT.secondPhoneNumber}</h4>
              </HorizontalDataRow>
              <HorizontalDataRow>
                <h4 className="question">{Q_ABOUT.secondDOB.title}</h4>
                <h4 className="answer">{convertDate(FORM_ABOUT.secondDOB)}</h4>
              </HorizontalDataRow>
            </>
          )}
          {isAnswerDefined(FORM_LOAN.isLoanCommercial) && (
            <HorizontalDataTitleRow>
              <h4 className="question-title">
                {Q_ABOUT.isLoanCommercial.title}
              </h4>
              <h4 className="answer-title">
                {convertBoolean(FORM_LOAN.isLoanCommercial)}
              </h4>
            </HorizontalDataTitleRow>
          )}
          {FORM_LOAN.isLoanCommercial === 'true' &&
          isAnswerDefined(FORM_LOAN.ABN) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.ABN.title}</h4>
              <h4 className="answer">{FORM_LOAN.ABN}</h4>
            </HorizontalDataRow>
          )}
          {FORM_LOAN.isLoanCommercial === 'true' && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.company_name.title}</h4>
              <h4 className="answer">{FORM_LOAN.company_name}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_LOAN.isCompanyRegistered) && (
            <HorizontalDataTitleRow>
              <h4 className="question-title">
                {Q_ABOUT.isCompanyRegistered.title}
              </h4>
              <h4 className="answer-title">
                {convertBoolean(FORM_LOAN.isCompanyRegistered)}
              </h4>
            </HorizontalDataTitleRow>
          )}
          {FORM_LOAN.isCompanyRegistered === 'true' && (
            <>
              {isAnswerDefined(FORM_LOAN.division) && (
                <>
                  <h4 className="question">{Q_ABOUT.division.title}</h4>
                  <h4 className="answer">{FORM_LOAN.division.title}</h4>
                </>
              )}
              {isAnswerDefined(FORM_LOAN.sub_division) && (
                <>
                  <h4 className="question">{Q_ABOUT.sub_division.title}</h4>
                  <h4 className="answer">{FORM_LOAN.sub_division.title}</h4>
                </>
              )}
            </>
          )}
        </>
      )}

      {displayHardship && (
        <>
          <h5 className="red_title">Background to Hardship</h5>
          <SectionDivider/>
          {
            isAnswerDefined(FORM_HARDSHIP.circumstances_changed) && (
                <>
                  <h4 className="question">
                    {Q_HARDSHIP.circumstances_changed.title}
                  </h4>
                  <h4 className="answer">
                    {FORM_HARDSHIP.circumstances_changed}
                  </h4>
                </>
            )
          }
          {isAnswerDefined(FORM_HARDSHIP.acknowledge_interest_fees) && (
            <>
              <h4 className="question">
                {Q_HARDSHIP.acknowledge_interest_fees.title}
              </h4>
              <h4 className="answer">
                {convertBoolean(
                  FORM_HARDSHIP.acknowledge_interest_fees,
                  'Conditions acknowledged',
                  'Conditions not acknowledged',
                )}
              </h4>
            </>
          )}
          {isAnswerDefined(FORM_HARDSHIP.howmuch_able_to_pay) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.howmuch_able_to_pay.title}</h4>
              <h4 className="answer">
                {FORM_HARDSHIP.howmuch_able_to_pay} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {(isAnswerDefined(FORM_HARDSHIP.howlong_reduce_selection) && FORM_HARDSHIP.howlong_reduce_selection === 'other')  && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ABOUT.howlong_reduce_selection.title}</h4>
              <h4 className="answer">
                {FORM_HARDSHIP.howlong_reduce} month(s)
              </h4>
            </HorizontalDataRow>
          )}
          {(isAnswerDefined(FORM_HARDSHIP.howlong_reduce_selection) && FORM_HARDSHIP.howlong_reduce_selection !== 'other') && (
              <HorizontalDataRow>
                <h4 className="question">{Q_ABOUT.howlong_reduce_selection.title}</h4>
                <h4 className="answer">
                  {FORM_HARDSHIP.howlong_reduce_selection} month(s)
                </h4>
              </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_HARDSHIP.when_resume_contractual_repayment) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_ABOUT.when_resume_contractual_repayment.title}
              </h4>
              <h4 className="answer">
                {convertDate(FORM_HARDSHIP.when_resume_contractual_repayment)}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_HARDSHIP.why_apply_hardship) && (
            <>
              <h4 className="question">{Q_ABOUT.why_apply_hardship.title}</h4>
              <h4 className="answer">
                {Q_ABOUT.why_select_option[FORM_HARDSHIP.why_apply_hardship]}
              </h4>
            </>
          )}
          {isAnswerDefined(FORM_HARDSHIP.why_apply_hardship_natural_disaster) && (
              <>
                <h4 className="answer">
                  {Q_ABOUT.why_select_natural_disaster_options[FORM_HARDSHIP.why_apply_hardship_natural_disaster]}
                </h4>
              </>
          )}

          {isAnswerDefined(FORM_HARDSHIP.briefly_describe) && (
              <>
                <h4 className="question">{Q_HARDSHIP.briefly_describe.title}</h4>
                <h4 className="answer">{FORM_HARDSHIP.briefly_describe}</h4>
              </>
          )}

          {isAnswerDefined(FORM_HARDSHIP.when_difficulties_date) && (
              <HorizontalDataRow>
                <h4 className="question">
                  {Q_ABOUT.when_difficulties_date.title}
                </h4>
                <h4 className="answer">
                  {convertDate(FORM_HARDSHIP.when_difficulties_date)}
                </h4>
              </HorizontalDataRow>
          )}

          {isAnswerDefined(FORM_HARDSHIP.what_you_doing_to_address_your_situation) && (
              <>
                <h4 className="question">{Q_HARDSHIP.what_you_doing_to_address_your_situation.title}</h4>
                <h4 className="answer">{FORM_HARDSHIP.what_you_doing_to_address_your_situation}</h4>
              </>
          )}

          {isAnswerDefined(FORM_HARDSHIP.eligible_for_complensation) && (
              <HorizontalDataTitleRow>
                <h4 className="question-title">
                  {Q_HARDSHIP.eligible_for_complensation.title}
                </h4>
                <h4 className="answer-title">
                  {convertBoolean(FORM_HARDSHIP.eligible_for_complensation)}
                </h4>
              </HorizontalDataTitleRow>
          )}
          {FORM_HARDSHIP.eligible_for_complensation === 'true' && (
              <>
                <HorizontalDataRow>
                  {isAnswerDefined(FORM_HARDSHIP.how_much) && (
                      <>
                        <h4 className="question">{Q_HARDSHIP.how_much.title}</h4>
                        <h4 className="answer">{FORM_HARDSHIP.how_much} AUD</h4>
                      </>
                  )}
                </HorizontalDataRow>
                <HorizontalDataRow>
                  {isAnswerDefined(
                      FORM_HARDSHIP.when_anticipate_compensation,
                  ) && (
                      <>
                        <h4 className="question">
                          {Q_HARDSHIP.when_anticipate_compensation.title}
                        </h4>
                        <h4 className="answer">
                          {convertDate(FORM_HARDSHIP.when_anticipate_compensation)}
                        </h4>
                      </>
                  )}
                </HorizontalDataRow>
              </>
          )}

          {
            (FORM_HARDSHIP.why_apply_hardship === 'illness' || FORM_HARDSHIP.why_apply_hardship === 'injury' || FORM_HARDSHIP.why_apply_hardship === 'covid_19') && renderDocument('Medical Certificate', MEDICAL_CERTIFICATE, FORM_HARDSHIP.medical_certification_file)
          }

          {isAnswerDefined(FORM_HARDSHIP.isPayment_Protection_Insurance) && (
            <HorizontalDataTitleRow>
              <h4 className="question-title">
                {Q_ABOUT.isPayment_Protection_Insurance.title}
              </h4>
              <h4 className="answer-title">
                {convertBoolean(FORM_HARDSHIP.isPayment_Protection_Insurance)}
              </h4>
            </HorizontalDataTitleRow>
          )}

          {FORM_HARDSHIP.isPayment_Protection_Insurance === 'true' && (
            <>
              {isAnswerDefined(FORM_HARDSHIP.logged_clam) && (
                <HorizontalDataRow>
                  <h4 className="question">{Q_HARDSHIP.logged_clam.title}</h4>
                  <h4 className="answer">
                    {convertBoolean(FORM_HARDSHIP.logged_clam)}
                  </h4>
                </HorizontalDataRow>
              )}
              {isAnswerDefined(FORM_HARDSHIP.when_logged_calm) && (
                <HorizontalDataRow>
                  <h4 className="question">{Q_ABOUT.when_logged_calm.title}</h4>
                  <h4 className="answer">
                    {convertDate(FORM_HARDSHIP.when_logged_calm)}
                  </h4>
                </HorizontalDataRow>
              )}
            </>
          )}
          {FORM_HARDSHIP.isPayment_Protection_Insurance === 'true' && FORM_HARDSHIP.logged_clam === 'false' &&
          isAnswerDefined(FORM_HARDSHIP.why_have_you_not_made_a_claim) && (
              <HorizontalDataRow>
                <h4 className="question">
                  {Q_ABOUT.why_have_you_not_made_a_claim.title}
                </h4>
                <h4 className="answer">
                  {FORM_HARDSHIP.why_have_you_not_made_a_claim}
                </h4>
              </HorizontalDataRow>
          )
          }
          {isAnswerDefined(FORM_HARDSHIP.indicate_reasonable_assistance) && (
            <>
              <>
                <h4 className="question-title">
                  Please indicate what reasonable assistance we could provided
                  to you that would help you through this difficult time and
                  allow you to meet your future monthly repayments to us:
                </h4>
                <HorizontalDataTitleRow>
                  <h4 className="answer">
                    {
                      A_INDICATE_ASSISTANCE[
                        FORM_HARDSHIP.indicate_reasonable_assistance
                        ]
                    }
                  </h4>
                </HorizontalDataTitleRow>
              </>
              {FORM_HARDSHIP.indicate_reasonable_assistance ===
              'postpone_repayments' &&
              isAnswerDefined(FORM_HARDSHIP.howlong_postpone) && (
                <HorizontalDataRow>
                  <h4 className="question">
                    {Q_ABOUT.postpone_repayments.title}
                  </h4>
                  <h4 className="answer">
                    {FORM_HARDSHIP.howlong_postpone} Month(s)
                  </h4>
                </HorizontalDataRow>
              )}
              {FORM_HARDSHIP.indicate_reasonable_assistance ===
              'assist_in_vehicle_sale' &&
              isAnswerDefined(
                FORM_HARDSHIP.acknowledge_assist_in_vehicle_sale,
              ) && (
                <>
                  <h4 className="question">
                    {Q_HARDSHIP.acknowledge_assist_in_vehicle_sale.title}
                  </h4>
                  <h4 className="answer">
                    {convertBoolean(
                      FORM_HARDSHIP.acknowledge_assist_in_vehicle_sale,
                      'Conditions acknowledged',
                      'Conditions not acknowledged',
                    )}
                  </h4>
                </>
              )}
              {FORM_HARDSHIP.indicate_reasonable_assistance ===
              'background_hardship_other' &&
              isAnswerDefined(FORM_HARDSHIP.background_hardship_others) && (
                <HorizontalDataRow>
                  <h4 className="question">
                    {Q_ABOUT.background_hardship_other.title}
                  </h4>
                  <h4 className="answer">
                    {FORM_HARDSHIP.background_hardship_others}
                  </h4>
                </HorizontalDataRow>
              )}
            </>
          )}
        </>
      )}

      {displayIncome && (
        <>
          <h5 className="red_title">Income - 1st Borrower</h5>
          <SectionDivider/>
          {isAnswerDefined(FORM_INCOME.employment_type) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.employment_type.title}</h4>
              <h4 className="answer">
                {Q_INCOME[FORM_INCOME.employment_type].title}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.employment_status) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.employment_status.title}</h4>
              <h4 className="answer">
                {Q_INCOME[FORM_INCOME.employment_status].title}
              </h4>
            </HorizontalDataRow>
          )}
          {FORM_INCOME.employment_type === 'employed' &&
          isAnswerDefined(FORM_INCOME.recent_primary_employer_name) && (
            <>
              <h4 className="question">
                {Q_INCOME.recent_primary_employer_name.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.recent_primary_employer_name}
              </h4>
            </>
          )}
          {FORM_INCOME.employment_type === 'employed' &&
          isAnswerDefined(FORM_INCOME.employer_abn) && (
            <>
              <h4 className="question">{Q_INCOME.employer_abn.title}</h4>
              <h4 className="answer">{FORM_INCOME.employer_abn}</h4>
            </>
          )}
          {FORM_INCOME.employment_type === 'employed' &&
          isAnswerDefined(FORM_INCOME.division) && (
            <>
              <>
                <h4 className="question">{Q_INCOME.division.title}</h4>
                <h4 className="answer">{FORM_INCOME.division.title}</h4>
              </>
              {isAnswerDefined(FORM_INCOME.sub_division) && (
                <>
                  <h4 className="question">{Q_INCOME.sub_division.title}</h4>
                  <h4 className="answer">{FORM_INCOME.sub_division.title}</h4>
                </>
              )}
            </>
          )}
          {isAnswerDefined(FORM_INCOME.after_tax_income) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.after_tax_income.title}</h4>
              <h4 className="answer">{FORM_INCOME.after_tax_income} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.after_tax_salary) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.after_tax_salary.title}</h4>
              <h4 className="answer">{FORM_INCOME.after_tax_salary} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.pension) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.pension.title}</h4>
              <h4 className="answer">{FORM_INCOME.pension} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.family_allowance) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.family_allowance.title}</h4>
              <h4 className="answer">{FORM_INCOME.family_allowance} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.newstart_allowance) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.newstart_allowance.title}</h4>
              <h4 className="answer">{FORM_INCOME.newstart_allowance} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.partners_income) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.partners_income.title}</h4>
              <h4 className="answer">{FORM_INCOME.partners_income} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.child_support) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME.child_support.title}</h4>
              <h4 className="answer">{FORM_INCOME.child_support} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.other_government_benefits) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME.other_government_benefits.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.other_government_benefits} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.other_incom_specify_type_first_txt) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME.other_incom_specify_type_first_txt.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.other_incom_specify_type_first_txt}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME.other_incom_specify_type_first_price,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME.other_incom_specify_type_first_price.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.other_incom_specify_type_first_price} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME.other_incom_specify_type_second_txt) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME.other_incom_specify_type_second_txt.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.other_incom_specify_type_second_txt}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME.other_incom_specify_type_second_price,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME.other_incom_specify_type_second_price.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME.other_incom_specify_type_second_price} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {renderDocument('Payslip', PAYSLIP_BORROWER_1, FORM_INCOME.payslip_file)}
          {renderDocument('Bank statement', BANK_STATEMENT_BORROWER_1, FORM_INCOME.bank_statement)}
          {renderDocument('Income Tax Assessment', INCOME_TAX_ASSESSMENT_BORROWER_1, FORM_INCOME.income_tax_assessment)}
          {renderDocument('Notice of Assessment', NOTICE_OF_ASSESSMENT_BORROWER_1, FORM_INCOME.notice_assessment)}
          {renderDocument('Business Activity Assessment', BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_1, FORM_INCOME.business_activity_assessment)}
          {renderDocument('Centrelink Statement', CENTRELINK_STATEMENT_BORROWER_1, FORM_INCOME.centrelink_statement)}
          {renderDocument('Other income Statements', OTHER_INCOME_STATEMENT_BORROWER_1, FORM_INCOME.other_income_statement)}
          {renderDocument('Other Documents', OTHER_DOCUMENTS_BORROWER_1, FORM_INCOME.other_documents)}
        </>
      )}

      {displayIncome2 && (
        <>
          <h5 className="red_title">
            {FORM_LOAN.isLoanCommercial === 'true'
              ? 'Additional Director/Guarantor'
              : 'Additional Borrower'}
          </h5>
          <SectionDivider/>
          {isAnswerDefined(FORM_INCOME_2.after_tax_income) && (
            <HorizontalDataRow>
              <h4 className="question">
                {FORM_ABOUT.secondUserName +
                '\'s ' +
                Q_INCOME_2.after_tax_income.title}
              </h4>
              <h4 className="answer">{FORM_INCOME_2.after_tax_income} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.after_tax_salary) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME_2.after_tax_salary.title}</h4>
              <h4 className="answer">{FORM_INCOME_2.after_tax_salary} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.pension) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME_2.pension.title}</h4>
              <h4 className="answer">{FORM_INCOME_2.pension} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.family_allowance) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME_2.family_allowance.title}</h4>
              <h4 className="answer">{FORM_INCOME_2.family_allowance} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.newstart_allowance) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.newstart_allowance.title}
              </h4>
              <h4 className="answer">{FORM_INCOME_2.newstart_allowance} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.partners_income) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME_2.partners_income.title}</h4>
              <h4 className="answer">{FORM_INCOME_2.partners_income} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.child_support) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_INCOME_2.child_support.title}</h4>
              <h4 className="answer">{FORM_INCOME_2.child_support} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_INCOME_2.other_government_benefits) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.other_government_benefits.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME_2.other_government_benefits} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME_2.other_incom_specify_type_first_txt,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.other_incom_specify_type_first_txt.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME_2.other_incom_specify_type_first_txt}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME_2.other_incom_specify_type_first_price,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.other_incom_specify_type_first_price.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME_2.other_incom_specify_type_first_price} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME_2.other_incom_specify_type_second_txt,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.other_incom_specify_type_second_txt.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME_2.other_incom_specify_type_second_txt}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(
            FORM_INCOME_2.other_incom_specify_type_second_price,
          ) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_INCOME_2.other_incom_specify_type_second_price.title}
              </h4>
              <h4 className="answer">
                {FORM_INCOME_2.other_incom_specify_type_second_price}
                AUD
              </h4>
            </HorizontalDataRow>
          )}
          {renderDocument('Payslip', PAYSLIP_BORROWER_2, FORM_INCOME_2.payslip_file)}
          {renderDocument('Bank statement', BANK_STATEMENT_BORROWER_2, FORM_INCOME_2.bank_statement)}
          {renderDocument('Income Tax Assessment', INCOME_TAX_ASSESSMENT_BORROWER_2, FORM_INCOME_2.income_tax_assessment)}
          {renderDocument('Notice of Assessment', NOTICE_OF_ASSESSMENT_BORROWER_2, FORM_INCOME_2.notice_assessment)}
          {renderDocument('Business Activity Assessment', BUSINESS_ACTIVITY_ASSESSMENT_BORROWER_2, FORM_INCOME_2.business_activity_assessment)}
          {renderDocument('Centrelink Statement', CENTRELINK_STATEMENT_BORROWER_2, FORM_INCOME_2.centrelink_statement)}
          {renderDocument('Other income Statements', OTHER_INCOME_STATEMENT_BORROWER_2, FORM_INCOME_2.other_income_statement)}
          {renderDocument('Other Documents', OTHER_DOCUMENTS_BORROWER_2, FORM_INCOME_2.other_documents)}
        </>
      )}

      {displayExpenses && (
        <>
          <h5 className="red_title">Expenses</h5>
          <SectionDivider/>
          {isAnswerDefined(FORM_EXPENSES.marital_status) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.marital_status.title}</h4>
              <h4 className="answer">
                {findValueFromId(MARITAL_STATUS, FORM_EXPENSES.marital_status)}
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.number_of_dependents) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_EXPENSES.number_of_dependents.title}
              </h4>
              <h4 className="answer">{FORM_EXPENSES.number_of_dependents}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.food_groceries) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.food_groceries.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.food_groceries} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.home_utilities_communication) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_EXPENSES.home_utilities_communication.title}
              </h4>
              <h4 className="answer">
                {FORM_EXPENSES.home_utilities_communication} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.personal_pet_care) && (
              <HorizontalDataRow>
                <h4 className="question">
                  {Q_EXPENSES.personal_pet_care.title}
                </h4>
                <h4 className="answer">
                  {FORM_EXPENSES.personal_pet_care} AUD
                </h4>
              </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.insurance_excluding_personal_insurance) && (
              <HorizontalDataRow>
                <h4 className="question">
                  {Q_EXPENSES.insurance_excluding_personal_insurance.title}
                </h4>
                <h4 className="answer">
                  {FORM_EXPENSES.insurance_excluding_personal_insurance} AUD
                </h4>
              </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.vehicles_and_transport) && (
              <HorizontalDataRow>
                <h4 className="question">
                  {Q_EXPENSES.vehicles_and_transport.title}
                </h4>
                <h4 className="answer">
                  {FORM_EXPENSES.vehicles_and_transport} AUD
                </h4>
              </HorizontalDataRow>
          )}

          {isAnswerDefined(FORM_EXPENSES.education) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.education.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.education !== 'N/A' ? FORM_EXPENSES.education + ' AUD' : FORM_EXPENSES.education}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.recreation_travel_entertainment) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.recreation_travel_entertainment.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.recreation_travel_entertainment !== 'N/A' ? FORM_EXPENSES.recreation_travel_entertainment + ' AUD' : FORM_EXPENSES.recreation_travel_entertainment}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.land_tax_body_corporate_strata) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.land_tax_body_corporate_strata.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.land_tax_body_corporate_strata !== 'N/A' ? FORM_EXPENSES.land_tax_body_corporate_strata + ' AUD' : FORM_EXPENSES.land_tax_body_corporate_strata}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.investment_additional_property) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.investment_additional_property.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.investment_additional_property !== 'N/A' ? FORM_EXPENSES.investment_additional_property + ' AUD' : FORM_EXPENSES.investment_additional_property}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.private_schooling_child_spousal_support_payments) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.private_schooling_child_spousal_support_payments.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.private_schooling_child_spousal_support_payments !== 'N/A' ? FORM_EXPENSES.private_schooling_child_spousal_support_payments + ' AUD' : FORM_EXPENSES.private_schooling_child_spousal_support_payments}</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_EXPENSES.all_other_expenses) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_EXPENSES.all_other_expenses.title}</h4>
              <h4 className="answer">{FORM_EXPENSES.all_other_expenses !== 'N/A' ? FORM_EXPENSES.all_other_expenses + ' AUD' : FORM_EXPENSES.all_other_expenses}</h4>
            </HorizontalDataRow>
          )}
        </>
      )}

      {displayAssets && (
        <>
          <h5 className="red_title">Assets</h5>
          <SectionDivider/>
          {FORM_ASSETS.haveProperties === 'true' ?  <QuestionAnswerGroups
              title={Q_ASSETS.property_data.title}
              items={FORM_ASSETS.property_data}
              noDataAnswer="NO PROPERTY"
              showNilAnswer={true}
              questionsKeys={[
                'property_type',
                'property_address',
                'estimated_property_value',
              ]}
              questionTitles={{
                property_type: Q_ASSETS_PROPERTY.property_type.title,
                property_address: Q_ASSETS_PROPERTY.property_address.title,
                estimated_property_value:
                Q_ASSETS_PROPERTY.estimated_property_value.title,
              }}
              answerTemplates={{
                property_type: (answer) => findValueFromId(PROPERTY_TYPE, answer),
                estimated_property_value: (answer) => `${answer} AUD`,
              }}
          /> : <h5 className="red_title" style={{marginTop: 0}}>No properties specified</h5>
          }

          {FORM_ASSETS.haveMotorVehicles === 'true' ? <QuestionAnswerGroups
              title={Q_ASSETS.vehicle_data.title}
              items={FORM_ASSETS.vehicle_data}
              noDataAnswer="NO VEHICLE"
              showNilAnswer={true}
              questionsKeys={['year', 'estimated_vehicle_value']}
              questionTitles={{
                year: Q_ASSETS_VEHICLE.year.title,
                estimated_vehicle_value:
                Q_ASSETS_VEHICLE.estimated_vehicle_value.title,
              }}
              answerTemplates={{
                estimated_vehicle_value: (answer) => `${answer} AUD`,
              }}
          /> : <h5 className="red_title" style={{marginTop: 0}}>No motor vehicles specified</h5>
          }

          <h4 className="question-title">{Q_ASSETS.otherAssets.title}</h4>
          {isAnswerDefined(FORM_ASSETS.superannuation) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ASSETS.superannuation.title}</h4>
              <h4 className="answer">{FORM_ASSETS.superannuation} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ASSETS.savings) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ASSETS.savings.title}</h4>
              <h4 className="answer">{FORM_ASSETS.savings} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ASSETS.household_furniture) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ASSETS.household_furniture.title}</h4>
              <h4 className="answer">{FORM_ASSETS.household_furniture} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ASSETS.shares) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ASSETS.shares.title}</h4>
              <h4 className="answer">{FORM_ASSETS.shares} AUD</h4>
            </HorizontalDataRow>
          )}
          {isAnswerDefined(FORM_ASSETS.all_other) && (
            <HorizontalDataRow>
              <h4 className="question">{Q_ASSETS.all_other.title}</h4>
              <h4 className="answer">{FORM_ASSETS.all_other} AUD</h4>
            </HorizontalDataRow>
          )}
        </>
      )}

      {displayLiabilities && (
        <>
          <h5 className="red_title">Liabilities</h5>
          <SectionDivider/>
          {isAnswerDefined(FORM_LIABILITIES.monthly_rent_board) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_LIABILITIES.monthly_rent_board.title}
              </h4>
              <h4 className="answer">
                {FORM_LIABILITIES.monthly_rent_board} AUD
              </h4>
            </HorizontalDataRow>
          )}
          {FORM_LIABILITIES.haveMortgages === 'true' ?
              <QuestionAnswerGroups
                  title={Q_LIABILITIES.mortgages_data.title}
                  items={FORM_LIABILITIES.mortgages_data}
                  noDataAnswer="NO MORTGAGE"
                  showNilAnswer={true}
                  questionsKeys={[
                    'mortgage_type',
                    'institution',
                    'year_account_opened',
                    'outstanding_balance',
                    'monthly_repayment',
                  ]}
                  questionTitles={{
                    mortgage_type: Q_LIABILITIES_MORTGAGE.mortgage_type.title,
                    institution: Q_LIABILITIES_MORTGAGE.institution.title,
                    year_account_opened:
                    Q_LIABILITIES_MORTGAGE.year_account_opened.title,
                    outstanding_balance:
                    Q_LIABILITIES_MORTGAGE.outstanding_balance.title,
                    monthly_repayment: Q_LIABILITIES_MORTGAGE.monthly_repayment.title,
                  }}
                  answerTemplates={{
                    mortgage_type: (answer) => findValueFromId(MORTGAGE_TYPE, answer),
                    outstanding_balance: (answer) => `${answer} AUD`,
                    monthly_repayment: (answer) => `${answer} AUD`,
                  }}
              /> : <h5 className="red_title" style={{marginTop: 0}}>No mortgages specified</h5>
          }

          {FORM_LIABILITIES.havePersonalLoans === 'true' ? <QuestionAnswerGroups
            title={Q_LIABILITIES.personal_loans_data.title}
            items={FORM_LIABILITIES.personal_loans_data}
            noDataAnswer="NO PERSONAL LOAN"
            showNilAnswer={true}
            questionsKeys={[
              'is_secured_loan',
              'institution',
              'year_account_opened',
              'outstanding_balance',
              'monthly_repayment',
            ]}
            questionTitles={{
              is_secured_loan: Q_LIABILITIES_LOAN.is_secured_loan.title,
              institution: Q_LIABILITIES_LOAN.institution.title,
              year_account_opened: Q_LIABILITIES_LOAN.year_account_opened.title,
              outstanding_balance: Q_LIABILITIES_LOAN.outstanding_balance.title,
              monthly_repayment: Q_LIABILITIES_LOAN.monthly_repayment.title,
            }}
            answerTemplates={{
              is_secured_loan: (answer) => convertBoolean(answer),
              outstanding_balance: (answer) => `${answer} AUD`,
              monthly_repayment: (answer) => `${answer} AUD`,
            }}
          />:<h5 className="red_title" style={{marginTop: 0}}>No personal loans specified</h5>}

          {FORM_LIABILITIES.haveCreditCards === 'true' ?
              <QuestionAnswerGroups
                  title={Q_LIABILITIES.credit_cards_data.title}
                  items={FORM_LIABILITIES.credit_cards_data}
                  noDataAnswer="NO CREDIT CARD"
                  showNilAnswer={true}
                  questionsKeys={[
                    'institution',
                    'year_account_opened',
                    'credit_card_limit',
                    'outstanding_balance',
                    'monthly_repayment',
                  ]}
                  questionTitles={{
                    institution: Q_LIABILITIES_CC.institution.title,
                    year_account_opened: Q_LIABILITIES_CC.year_account_opened.title,
                    credit_card_limit: Q_LIABILITIES_CC.credit_card_limit.title,
                    outstanding_balance: Q_LIABILITIES_CC.outstanding_balance.title,
                    monthly_repayment: Q_LIABILITIES_CC.monthly_repayment.title,
                  }}
                  answerTemplates={{
                    credit_card_limit: (answer) => `${answer} AUD`,
                    outstanding_balance: (answer) => `${answer} AUD`,
                    monthly_repayment: (answer) => `${answer} AUD`,
                  }}
              /> : <h5 className="red_title" style={{marginTop: 0}}>No credit cards specified</h5>
          }

          {isAnswerDefined(FORM_LIABILITIES.total_other_liabilities) && (
            <HorizontalDataRow>
              <h4 className="question">
                {Q_LIABILITIES.total_other_liabilities.title}
              </h4>
              <h4 className="answer">
                {FORM_LIABILITIES.total_other_liabilities} AUD
              </h4>
            </HorizontalDataRow>
          )}
        </>
      )}

      <>
        <SectionDivider/>
        <div className="question">
          <p>
            Once you submit the application you will not be able to make any
            further changes including uploading all the requested documentation.
            If your circumstances change then you will need to email us at{' '}
            <a
              href={`mailto:${hardshipEmail}`}
              style={{ fontSize: 'inherit', paddingBottom: 'initial' }}
            >
              {hardshipEmail}
            </a>{' '}
            or call us on{' '}
            <a
              href={`tel:${complaintsPhone}`}
              style={{ fontSize: 'inherit', paddingBottom: 'initial' }}
            >
              {complaintsPhone}
            </a>{' '}
            between 8:30am and 7:00pm (AEST) Monday to Friday.
          </p>
          <h4>Privacy consent</h4>
          <p>
            By clicking submit, you warrant that to the best of your knowledge,
            the information you have provided is true and correct and you have
            the authority to act on behalf of all parties to the contract. You
            consent to its collection so that {hardshipName} can:
          </p>
          <ul style={{ fontWeight: 600 }}>
            <li>Assess your application for hardship; and</li>
            <li>
              Contact you in relation to your application for hardship if
              necessary.
            </li>
          </ul>
          <p>
            Your personal information is collected and used in accordance with
            our Privacy Policy, which is available at{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={privacyPolicyUrl}
              style={{ fontSize: 'inherit', paddingBottom: 'initial' }}
            >
              {privacyPolicyUrl}
            </a>
            .
          </p>
        </div>
      </>

      <FormGroupButtons onPrev={onPrev} onSubmit={onSubmit} {...props} />
    </>
  );
};

export default FG_Review;
