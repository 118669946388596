import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Files } from '../../../../api';
import { Box, Card, CardContent, Chip, LinearProgress, Typography } from '@material-ui/core';

const FILE_NOT_ACCEPTED =
  'File Not Accepted! Please check if you have uploaded the correct file indicated, and try again.';
const FILE_UPLOAD_FAILURE = 'File Upload Failed! Please try again.';
const UNSUPPORTED_FILE_FORMAT = 'This file format is not supported.';

const FileUpload = ({
                      id,
                      title,
                      type,
                      value,
                      documentType,
                      setValue,
                      multiple = false,
                      required,
                      testAutomationId,
                      setCanSaveData
                    }) => {
  const submission = useSelector((store) => store.user);
  const maxSize = 10000000; // 10 Megabytes
  const [uploadPercent, setUploadPercent] = useState();
  const [files, setFiles] = useState([]);
  const [uploadFailMsg, setUploadFailMsg] = useState('');
  const instanceId = testAutomationId || 'simple-file-upload';

  useEffect(() => {
    if (files.length === 0) {
      const savedFiles = (Array.isArray(value) ? value : value && value.hasOwnProperty("id") ? [value] : []).filter(
        (file) => !!file,
      );
      if (savedFiles.length > 0) {
        setFiles(savedFiles);
      }
    }
  }, [value]);

  /**
   * to avoid updatedFiles[0] return as undefine
   */

  const validateSingleElement = (updatedFile) => {
    return updatedFile ? updatedFile : [];
  };

  const updateFileList = (updatedFiles) => {
    setFiles(updatedFiles);
    setValue(
      id,
      multiple ? updatedFiles : validateSingleElement(updatedFiles[0]),
      type
    );
    if (updatedFiles.length === 0) {
      setUploadPercent(undefined);
    }
  };

  const handleDelete = (deleteId) => () => {
    updateFileList(files.filter(({ id }) => id !== deleteId));
  };

  const onDrop = useCallback(
    (filesToUpload) => {
      setCanSaveData(false)
      if (filesToUpload.length > 0) {
        setUploadPercent(0);
        const formData = new FormData();
        filesToUpload.forEach((file) => {
          formData.append('file', file);
        });
        Files.upload(submission.id, documentType, formData, (progressEvent) => {
          setUploadPercent((progressEvent.loaded / progressEvent.total) * 100);
        })
          .then((uploadData) => {
            setUploadFailMsg('');
            const newFiles = uploadData.map(({ id, filename }) => ({
              id,
              filename,
            }));
            updateFileList(multiple ? [...files, ...newFiles] : newFiles);
          })
          .catch((uploadError) => {
            const errMsg = fileUploadError(uploadError.response.status);
            setUploadFailMsg(errMsg);
            setUploadPercent(undefined);
          })
          .finally(() => {
            setCanSaveData(true)
          });
      }
    },
    [files],
  );

  const fileUploadError = (status) => {
    switch(status) {
      case 406:
        return FILE_NOT_ACCEPTED;
      case 415:
        return UNSUPPORTED_FILE_FORMAT;
      default:
        return FILE_UPLOAD_FAILURE;
    }
  };

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    multiple: !!multiple,
    accept: ['image/*', 'application/pdf'],
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <Box mt={4}>
      <Card>
        <CardContent {...getRootProps()}>
          <Box>
            <input id={instanceId} {...getInputProps()} />
            <Typography
              align={'center'}
              gutterBottom
              style={{ cursor: 'pointer' }}
            >
              {!isDragActive &&
              title + ': Click here or drop a file to upload!'}
              {isDragActive && !isDragReject && 'Drop the file to upload!'}
              {isDragReject && 'File type not accepted, sorry!'}
            </Typography>
            {isFileTooLarge && (
              <Typography color={'error'} align={'center'}>
                File is too large.
              </Typography>
            )}
          </Box>
          <Box align={'center'}>
            {files.map((file) => (
              <Chip
                className="file-upload-chip"
                label={file.filename}
                onDelete={handleDelete(file.id)}
                key={file.id}
              />
            ))}
          </Box>
          {required && files.length === 0 && (
            <p className="field-error" style={{ textAlign: 'center' }}>
              {multiple
                ? 'At least one document must be provided.'
                : 'You are required to provide this document as a minimum to proceed with the form.'}
            </p>
          )}
          {!!uploadFailMsg && (
            <p className="field-error" style={{ textAlign: 'center' }}>
              {uploadFailMsg}
            </p>
          )}
        </CardContent>
        {uploadPercent !== undefined && (
          <LinearProgress variant="determinate" value={uploadPercent}/>
        )}
      </Card>
    </Box>
  );
};

export default FileUpload;
