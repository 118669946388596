import divisions from "../../utils/divisions";
import {hasStringValue} from "../../utils";
import {some} from "lodash/fp";


// Alphanumeric up to 7 characters
export const registrationNumberRegex = /^[A-z0-9]{1,7}$/;
// Alphanumeric up to 7 characters pattern separated by commas
export const registrationNumbersRegex = /^([A-z0-9]{1,7},)*[A-z0-9]{1,7}$/;
// Numeric starting with 11, 12, 13, 14 and is 8 digits
export const contractNumberRegex = /^1{1}(1|2|3|4)[0-9]{6}$/;
// Numeric starting with 11, 12, 13, 14 and is 8 digits pattern separated by commas
export const contractNumbersRegex = /^(1{1}(1|2|3|4)[0-9]{6},)*(1{1}(1|2|3|4)[0-9]{6})$/;
// Alpha with symbols used in names
export const nameRegex = /^[A-z-' ]+$/;
// Numeric starting with 61 or 0 with a max length of 10 or 11 depending on the start
export const phoneNumberRegex = /^(0|61)[0-9]{9}$/;
// Number phone number max length pattern
export const numericPhoneNumberRegex = /^(0[0-9]{0,9}|6[0-9]{0,9}|61[0-9]{0,9})$/;
// Alphanumeric pattern
export const alphanumericRegex = /^[A-z0-9]*$/;
// Numeric comma pattern
export const numericCommaRegex = /^[0-9,]*$/;
// Alphanumeric comma pattern
export const alphanumericCommaRegex = /^[A-z0-9,]*$/;
// Numeric not starting with 0
export const monthsRegex = /^[1-9][0-9]*$/
// Numeric pattern
export const numericRegex = /^[0-9]*$/;


/**
 * Add new block
 */
export const addNewBlock = (currentBlocks, newBlock, setStateCallback) => {
    setStateCallback([...currentBlocks, newBlock]);
};

/**
 * Delete field Block
 */
export const deleteBlock = (currentBlocks, index, setStateCallback) => {
    setStateCallback((currentBlocks) =>
        currentBlocks.filter((item, i) => i !== index)
    );
};

/**
 * setFieldValue
 * @param actionType - redux action type
 * @param dispatch - redux dispatch fn
 * @param key - payload key for field value
 * @param value - field value
 * @param validate - validation object
 */
export const setFieldValue = (
  actionType = '',
  dispatch,
  key,
  value,
  validate
) => {
  if (actionType === '' || typeof dispatch !== 'function') {
    return;
  }

  dispatch({
    type: actionType,
    payload: {
      [key]: value,
      ...validate,
    },
  });
};

export const isOtherIncomePairValidationStatus = (text, price) => {
  const hasText = hasStringValue(text);
  const hasPrice = hasStringValue(price);
  return (hasText && hasPrice) || (!hasText && !hasPrice);
};

export const isAllCurrencyValidationStatus = (values) =>
    some((value) => value)([
      values.after_tax_salary,
      values.pension,
      values.family_allowance,
      values.newstart_allowance,
      values.partners_income,
      values.child_support,
      values.other_government_benefits,
      values.other_incom_specify_type_first_price,
      values.other_incom_specify_type_second_price,
    ]);

export const foundSubDivision = (values) => {
  const division = values.division && divisions.find(item => item.id === values.division.id);
  if(division && values.sub_division){
    return division.children.find(child => child.id === values.sub_division.id)
  }else{
    return false;
  }
}

/**
 * setPayloadData
 * @param actionType - redux action type
 * @param dispatch - redux dispatch fn
 * @param payload - data to be dispatched
 */
export const setPayloadData = (actionType = '', dispatch, payload) => {
  if (actionType === '' || typeof dispatch !== 'function') {
    return;
  }

  dispatch({
    type: actionType,
    payload: {
      ...payload,
    },
  });
};
