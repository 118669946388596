import React from 'react';

/**
 * renderFileNameAnswer
 * @param fileData - file data
 * @param index - array index from array.map
 * @returns react element
 */
export const renderFileNameAnswer = (fileData, index) => (
  <h4 className="answer" key={`answer-${index}`}>
    {fileData.filename}
  </h4>
);

/**
 * renderUploadedFileAnswers
 * @param fileData - file data obj, or list
 * @returns array of react elements
 */
export const renderUploadedFileAnswers = (fileData) => {
  if (!fileData || fileData.length === 0) {
    return <h4 className="answer">NOT UPLOADED</h4>;
  }

  return Array.isArray(fileData)
    ? fileData.map(renderFileNameAnswer)
    : [renderFileNameAnswer(fileData, 0)];
};

/**
 * isAnswerDefined
 * @param answer
 * @returns {boolean}
 */
export const isAnswerDefined = (answer) => {
  return (
    answer !== undefined &&
    answer !== null &&
    answer !== void 0 &&
    answer !== ''
  );
};

/**
 * convertBoolean
 * @param answer
 * @param trueText
 * @param falseText
 * @returns {string}
 */
export const convertBoolean = (answer, trueText = 'Yes', falseText = 'No') => {
  if (typeof answer !== 'string') {
    return 'N/A';
  }
  const answerText = answer.trim().toLowerCase();
  return answerText === 'true' || answerText === 'yes' ? trueText : falseText;
};

/**
 * findValueFromId
 * @param data
 * @param id
 * @returns {*}
 */
export const findValueFromId = (data = [], id = '') => {
  const matchedItem = data.filter((item) => {
    return item.id === id;
  })[0];

  return !!matchedItem ? matchedItem.value || '' : '';
};
