export const SIGN_IN = 'SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_BEGIN_DATA = 'SET_BEGIN_DATA';
export const SET_LOAN_DATA = 'SET_LOAN_DATA';
export const SET_BACKGROUND_HARDSHIP_DATA = 'SET_BACKGROUND_HARDSHIP_DATA';
export const SET_INCOME_DATA = 'SET_INCOME_DATA';
export const SET_INCOME_SECOND_DATA = 'SET_INCOME_SECOND_DATA';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SET_EXPENSES_DATA = 'SET_EXPENSES_DATA';
export const SET_ASSETS_DATA = 'SET_ASSETS_DATA';
export const SET_LIABILITIES_DATA = 'SET_LIABILITIES_DATA';
