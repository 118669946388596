import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_LIABILITIES_DATA} from '../../../../state/ducks/user/types';
import {addNewBlock, deleteBlock, setFieldValue} from '../utils';
import {commaFormatted} from '../../../../utilities';
import {useFormGroupsStyles} from '../styles';
import PriceInput from '../../FormElements/PriceInput';
import Label from '../../FormElements/Label';
import FormGroupButtons from '../../FormGroupButtons';
import MortgageType from './MortgageType';
import PersonalLoans from './PersonalLoans';
import CreditCards from './CreditCards';
import Liabilities from "./Liabilities";
import {hasStringValue} from "../../../utils";
import {numericRegex} from "../../FormElements/YearInput";
import {initialState} from "../../../../state/ducks/user/reducers";
import _ from "lodash";

function FG_Liabilities(props) {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({...userData.liabilities});
    const [mortgages, setMortgages] = React.useState(
        userData.liabilities.mortgages_data
    );
    const [personalLoans, setPersonalLoans] = React.useState(
        userData.liabilities.personal_loans_data
    );
    const [creditCards, setCreditCards] = React.useState(
        userData.liabilities.credit_cards_data
    );
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch();
    const classes = useFormGroupsStyles();

    const defaultMortgage = {
        mortgage_type: '',
        institution: '',
        year_account_opened: null,
        outstanding_balance: '',
        monthly_repayment: ''
    };
    const defaultPersonalLoans = {
        is_secured_loan: '',
        institution: '',
        year_account_opened: null,
        outstanding_balance: '',
        monthly_repayment: ''
    };
    const defaultCreditCard = {
        institution: '',
        year_account_opened: null,
        credit_card_limit: '',
        outstanding_balance: '',
        monthly_repayment: ''
    };

    useEffect(() => {
        let payload = initialState.liabilities;
        _.merge(payload, userData.liabilities);

        dispatch({
            type: SET_LIABILITIES_DATA,
            payload: payload
        });
    }, [0])

    useEffect(() => {
        setValues({...userData.liabilities});
        setMortgages(userData.liabilities.mortgages_data);
        setPersonalLoans(userData.liabilities.personal_loans_data);
        setCreditCards(userData.liabilities.credit_cards_data);
    }, [userData]);

    useEffect(() => {
        let liabilitiesUtilities = ['total_other_liabilities'];
        let totalCalculation = [];
        liabilitiesUtilities.forEach((item, index) => {
            totalCalculation.push(values[item]);
        });
        // calculate total
        const sum = totalCalculation.reduce(
            (result, number) => Number(result) + Number(number)
        );

        const sumMortgage = values.mortgages_data.reduce(function (amount, item) {
            return Number(amount) + Number(item.outstanding_balance);
        }, 0);

        const sumPersonaLoan = values.personal_loans_data.reduce(function (
            amount,
            item
            ) {
                return Number(amount) + Number(item.outstanding_balance);
            },
            0);

        const sumCreditCard = values.credit_cards_data.reduce(
            function (amount, item) {
                return Number(amount) + Number(item.outstanding_balance);
            },

            0
        );

        setTotal(
            commaFormatted(
                Number(sum) +
                Number(sumMortgage) +
                Number(sumPersonaLoan) +
                Number(sumCreditCard)
            )
        );
    }, [values]);

    useEffect(() => {
        setValue('mortgages_data', mortgages);
        setValue('personal_loans_data', personalLoans);
        setValue('credit_cards_data', creditCards);
    }, [mortgages, personalLoans, creditCards]);

    /**
     * Form fields handlers
     */

    const setValue = (key, value) => {
        setFieldValue(SET_LIABILITIES_DATA, dispatch, key, value);
    };


    /**
     * Add individual values to dispatch
     */

    const setMortgageValue = (index, key, value) => {
        let mortgage = mortgages[index];

        mortgage[key] = value;

        if (key === 'mortgage_type' && value === '') {
            mortgage['mortgage_type'] = '';
            mortgage['institution'] = '';
            mortgage['year_account_opened'] = '';
            mortgage['outstanding_balance'] = '';
            mortgage['monthly_repayment'] = '';
        }

        setValue('mortgages_data', mortgages);
    };

    const setPersonalLoanValue = (index, key, value) => {
        let personalLoan = personalLoans[index];
        personalLoan[key] = value;

        if (key === 'is_secured_loan' && value === '') {
            personalLoan['institution'] = '';
            personalLoan['year_account_opened'] = '';
            personalLoan['outstanding_balance'] = '';
            personalLoan['monthly_repayment'] = '';
        }

        setValue('personal_loans_data', personalLoans);
    };

    const setCreditCardValue = (index, key, value) => {
        let creditCard = creditCards[index];
        creditCard[key] = value;

        setValue('credit_cards_data', creditCards);
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };

    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    const isYearValid = (year) => {
        const endYear = new Date().getFullYear();
        const startYear = Number(endYear - 50);

        if (year && year.length > 0 && year.length <= 4 && numericRegex.test(year)) {
            return !(startYear > Number(year) || endYear < Number(year));
        }else{
            return false;
        }
    }
    /**
     * Validation
     */
    const validateFormGroup = () => {
        let isValid = !!values.monthly_rent_board && !!values.haveMortgages && !!values.havePersonalLoans && !!values.haveCreditCards;

        if(isValid && values.haveMortgages === 'true'){
            mortgages.forEach(mortgage => {
                if(isValid) {
                    isValid = hasStringValue(mortgage.mortgage_type) && hasStringValue(mortgage.institution) && isYearValid(mortgage.year_account_opened) && hasStringValue(mortgage.outstanding_balance) && hasStringValue(mortgage.monthly_repayment);
                }
            })
        }

        if(isValid && values.havePersonalLoans === 'true'){
            personalLoans.forEach(personalLoan => {
                if(isValid) {
                    isValid = hasStringValue(personalLoan.is_secured_loan) && hasStringValue(personalLoan.institution) && isYearValid(personalLoan.year_account_opened) && hasStringValue(personalLoan.outstanding_balance) && hasStringValue(personalLoan.monthly_repayment);
                }
            })
        }

        if(isValid && values.haveCreditCards === 'true'){
            creditCards.forEach(creditCard => {
                if(isValid) {
                    isValid = hasStringValue(creditCard.institution) && isYearValid(creditCard.year_account_opened) && hasStringValue(creditCard.credit_card_limit) && hasStringValue(creditCard.outstanding_balance) && hasStringValue(creditCard.monthly_repayment);
                }
            })
        }

        return isValid;
    }

    /**
     * View
     */

    const handleRadioChange = (event, id) => {

        const value = event.target.value;
        let payload = {};

        switch (id) {
            case 'mortgages':
                payload.haveMortgages = value;
                if (value === 'false') {
                    payload.mortgages_data = [defaultMortgage];
                }
                break;
            case 'personalLoans':
                payload.havePersonalLoans = value;
                if (value === 'false') {
                    payload.personal_loans_data = [defaultPersonalLoans];
                }
                break;
            case 'creditCards':
                payload.haveCreditCards = value;
                if (value === 'false') {
                    payload.credit_cards_data = [defaultCreditCard];
                }
                break;
            default:
        }


        dispatch({
            type: SET_LIABILITIES_DATA,
            payload: payload
        });
    }


    return (
        <>
            <h4>
                Please provide details of your liabilities. Please take care to list all
                liabilities, including all credit cards and loans as discrepancies with
                the information we have for you may cause your application to take
                longer to assess.
            </h4>
            <h5>Monthly Rent/Board</h5>
            <PriceInput
                noFormTag={true}
                id="monthly_rent_board"
                title="Enter Amount"
                type="currency"
                value={values.monthly_rent_board}
                setValue={setValue}
                required={!values.monthly_rent_board}
            />

            <Liabilities id={'mortgages'}
                         haveItems={values.haveMortgages}
                         items={mortgages} item={MortgageType}
                         add={addNewBlock}
                         addTitle={"Add another mortgage +"}
                         delete={deleteBlock}
                         defaultItem={defaultMortgage}
                         setItem={setMortgages}
                         handleRadioChangeEvent={(event) => handleRadioChange(event, 'mortgages')}
                         question={"Do you have any mortgages?"}
                         setItemValue={setMortgageValue}/>

            <Liabilities id={'personal_loans'}
                         haveItems={values.havePersonalLoans}
                         items={personalLoans} item={PersonalLoans}
                         add={addNewBlock}
                         addTitle={"Add another personal loan +"}
                         delete={deleteBlock}
                         defaultItem={defaultPersonalLoans}
                         setItem={setPersonalLoans}
                         handleRadioChangeEvent={(event) => handleRadioChange(event, 'personalLoans')}
                         question={"Do you have any personal loans?"}
                         setItemValue={setPersonalLoanValue}/>

            <Liabilities id={'credit_cards'}
                         haveItems={values.haveCreditCards}
                         items={creditCards} item={CreditCards}
                         add={addNewBlock}
                         addTitle={"Add another credit card +"}
                         delete={deleteBlock}
                         defaultItem={defaultCreditCard}
                         setItem={setCreditCards}
                         handleRadioChangeEvent={(event) => handleRadioChange(event, 'creditCards')}
                         question={"Do you have any credit cards?"}
                         setItemValue={setCreditCardValue}/>

            <h5>Total Other Liabilities</h5>

            <PriceInput
                noFormTag={true}
                id="total_other_liabilities"
                title="Enter Amount"
                type="currency"
                value={values.total_other_liabilities}
                setValue={setValue}
            />

            {total !== 0 ? (
                <Label title={`Total Amount - $${total}`} textAlign="right"/>
            ) : null}
            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
}

export default FG_Liabilities;
