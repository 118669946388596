import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";
import {useFormGroupsStyles} from "../../FormGroups/styles";

const YesNo = ({title, value, id, onChange, yesComponent, noComponent}) => {
    const classes = useFormGroupsStyles();
    return (
        <>
            {title}
            {value !== undefined &&
            <>
                <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup
                        id={id}
                        aria-label="quiz"
                        name="quiz"
                        value={value}
                        onChange={onChange}
                    >
                        {!value && <h6 data-testid={`${id}_required`}>*Required</h6>}
                        <FormControlLabel
                            id={`${id}_yes`}
                            value="true"
                            control={<Radio/>}
                            label="Yes"
                            className={classes.option}
                        />
                        <FormControlLabel
                            value="false"
                            id={`${id}_no`}
                            control={<Radio/>}
                            label="No"
                            className={classes.option}
                        />
                    </RadioGroup>
                </FormControl>
                {(value === 'true' && yesComponent) ? yesComponent : <span/>}
                {(value === 'false' && noComponent) ? noComponent : <span/>}
            </>
            }
        </>
    )
};

export default YesNo;