import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_ASSETS_DATA} from '../../../../state/ducks/user/types';
import {addNewBlock, deleteBlock, setFieldValue} from '../utils';
import {commaFormatted} from '../../../../utilities';
import {useFormGroupsStyles} from '../styles';
import {StyledButtonGroup} from '../../../styled/Containers';
import Button from '@material-ui/core/Button';
import PriceInput from '../../FormElements/PriceInput';
import Label from '../../FormElements/Label';
import FormGroupButtons from '../../FormGroupButtons';
import PropertyType from './PropertyType';
import VehicleType from './VehicleType';
import YesNo from "../../FormElements/YesNo";
import {hasStringValue} from "../../../utils";
import {initialState} from "../../../../state/ducks/user/reducers";
import _ from "lodash";

function FG_Assets(props) {
    const userData = useSelector((store) => store.user);
    const [values, setValues] = React.useState({...userData.assets});
    const [property, setProperty] = React.useState(userData.assets.property_data);
    const [vehicle, setVehicle] = React.useState(userData.assets.vehicle_data);
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch();
    const classes = useFormGroupsStyles();

    const defaultProperty = {
        property_type: '',
        property_address: '',
        estimated_property_value: ''
    };
    const defaultVehicle = {
        year: '',
        estimated_vehicle_value: ''
    };

    useEffect(() => {
        let payload = initialState.assets;
        _.merge(payload, userData.assets);

        dispatch({
            type: SET_ASSETS_DATA,
            payload: payload
        });
    }, [0])

    useEffect(() => {
        setValues({...userData.assets});
        setProperty(userData.assets.property_data);
        setVehicle(userData.assets.vehicle_data);
    }, [userData]);


    useEffect(() => {
        let assetsUtilities = [
            'superannuation',
            'savings',
            'household_furniture',
            'shares',
            'all_other',
        ];
        let totalCalculation = [];

        assetsUtilities.forEach((item, index) => {
            totalCalculation.push(values[item]);
        });

        // calculate total
        const sum = totalCalculation.reduce(
            (result, number) => Number(result) + Number(number)
        );

        const sumProperty = values.property_data.reduce(function (amount, item) {
            return Number(amount) + Number(item.estimated_property_value);
        }, 0);

        const sumVehicle = values.vehicle_data.reduce(function (amount, item) {
            return Number(amount) + Number(item.estimated_vehicle_value);
        }, 0);

        setTotal(commaFormatted(sum + sumProperty + sumVehicle));
    }, [values]);

    useEffect(() => {
        setValue('property_data', property);
        setValue('vehicle_data', vehicle);
    }, [property, vehicle]);

    /**
     * Form fields handlers
     */

    const setValue = (key, value) => {
        setFieldValue(SET_ASSETS_DATA, dispatch, key, value);
    };

    /**
     * Add individual values to dispatch
     */

    const setPropertyValue = (index, key, value) => {
        let oneProperty = property[index];


        if (key === 'property_type' && value === '*') {
            oneProperty['property_type'] = '';
            oneProperty['property_address'] = '';
            oneProperty['estimated_property_value'] = '';

            return setValue('property_data', property);
        }

        oneProperty[key] = value;

        setValue('property_data', property);
    };

    const setVehicleValue = (index, key, value) => {
        let oneVehicle = vehicle[index];
        oneVehicle[key] = value;

        setValue('vehicle_data', vehicle);
    };

    /**
     * Navigation handlers
     */

    const onPrev = (e) => {
        if (typeof props.prev === 'function') {
            props.prev(e);
        }
    };

    const onNext = (e) => {
        if (validateFormGroup() && typeof props.next === 'function') {
            props.next(e);
        }
    };

    /**
     * Validation
     */

    const validateFormGroup = () => {
        let isValid = !!values.haveProperties && !!values.haveMotorVehicles;

        if (isValid) {
            if (values.haveMotorVehicles === 'true') {
                values.vehicle_data.forEach(vehicle => {
                    if(isValid) {
                        isValid = hasStringValue(vehicle.year) && hasStringValue(vehicle.estimated_vehicle_value)
                    }
                })
            }

            if (isValid) {
                if (values.haveProperties === 'true') {
                    values.property_data.forEach(property => {
                        if(isValid){
                            isValid = hasStringValue(property.property_type) && hasStringValue(property.property_address) && hasStringValue(property.estimated_property_value)
                        }
                    })
                }
            }
        }

        return isValid;
    }


    /**
     * View
     */

    const handlePropertyChange = (event) => {
        const value = event.target.value;
        let payload = {
            haveProperties: value
        };

        if (value === 'false') {
            payload.property_data = [defaultProperty]
        }

        dispatch({
            type: SET_ASSETS_DATA,
            payload: payload
        });
    }

    const handleMotorVehicleChange = (event) => {
        const value = event.target.value;
        let payload = {
            haveMotorVehicles: value
        };

        if (value === 'false') {
            payload.vehicle_data = [defaultVehicle]
        }

        dispatch({
            type: SET_ASSETS_DATA,
            payload: payload
        });
    }

    return (
        <>
            <h5>Please provide details of your assets.</h5>
            <YesNo title={<h5>Do you have any properties?</h5>} value={values.haveProperties}
                   onChange={handlePropertyChange} id={"properties"} yesComponent={<>
                {
                    values.property_data.map((property, i) => <PropertyType
                        key={`prop-${i}`}
                        itemKey={i}
                        componentData={property}
                        index={i}
                        setPropertyValue={setPropertyValue}
                        deleteBlock={deleteBlock}
                        items={property}
                        setItems={setProperty}
                    />)
                }
                <StyledButtonGroup style={{marginBottom: '1rem', marginTop: 0}}>
                    <div className="flex_left">
                        <Button
                            variant="contained"
                            className="next"
                            color="primary"
                            onClick={() => addNewBlock(property, defaultProperty, setProperty)}
                            id="newBlock"
                        >
                            Add another property +
                        </Button>
                    </div>
                </StyledButtonGroup>
            </>}/>


            <YesNo title={<h5>Do you have any motor vehicles?</h5>} value={values.haveMotorVehicles}
                   onChange={handleMotorVehicleChange} id={"motorVehicles"} yesComponent={<>
                {
                    values.vehicle_data.map((item, i) => <VehicleType
                        key={i}
                        itemKey={i}
                        componentData={item}
                        index={i}
                        setVehicleValue={setVehicleValue}
                        deleteBlock={deleteBlock}
                        items={vehicle}
                        setItems={setVehicle}
                    />)
                }
                <StyledButtonGroup style={{marginBottom: '1rem', marginTop: 0}}>
                    <div className="flex_left">
                        <Button
                            variant="contained"
                            className="next"
                            color="primary"
                            onClick={() => addNewBlock(vehicle, defaultVehicle, setVehicle)}
                            id="newVehicleBlock"
                        >
                            Add another vehicle +
                        </Button>
                    </div>
                </StyledButtonGroup>
            </>}/>

            <h5>Other Assets</h5>

            <PriceInput
                noFormTag={true}
                id="superannuation"
                title="Superannuation"
                type="currency"
                value={values.superannuation}
                setValue={setValue}
            />

            <PriceInput
                noFormTag={true}
                id="savings"
                title="Savings"
                type="currency"
                value={values.savings}
                setValue={setValue}
            />

            <PriceInput
                noFormTag={true}
                id="household_furniture"
                title="Household Furniture (Estimate)"
                type="currency"
                value={values.household_furniture}
                setValue={setValue}
            />

            <PriceInput
                noFormTag={true}
                id="shares"
                title="Shares (Estimate)"
                type="currency"
                value={values.shares}
                setValue={setValue}
            />

            <PriceInput
                noFormTag={true}
                id="all_other"
                title="All other specific assets"
                type="currency"
                value={values.all_other}
                setValue={setValue}
            />
            {total !== 0 ? (
                <Label title={`Total Amount - $${total}`} textAlign="right"/>
            ) : null}
            <FormGroupButtons onPrev={onPrev} onNext={onNext} isValid={validateFormGroup} {...props} />
        </>
    );
};

export default FG_Assets;
