import React from 'react';
import { useFormGroupsStyles } from '../styles';
import { StyledButtonGroup } from '../../../styled/Containers';
import TextInput from '../../FormElements/TextInput';
import PriceInput from '../../FormElements/PriceInput';
import Button from '@material-ui/core/Button';

function VehicleType(props) {
  const { componentData, index, setVehicleValue, deleteBlock, items, setItems} = props;

  const setValue = (key, value) => {
    setVehicleValue(index, key, value);
  };


  return (
    <>
        <TextInput
          noFormTag={true}
          title="Specify Make, Model & Year"
          id="year"
          setValue={setValue}
          value={componentData.year}
          required={!componentData.year}
        />
        <PriceInput
          noFormTag={true}
          id="estimated_vehicle_value"
          title="Estimated Value"
          type="currency"
          value={componentData.estimated_vehicle_value}
          setValue={setValue}
          required={!componentData.estimated_vehicle_value}
        />

        {index !== 0 ? (
            <StyledButtonGroup style={{marginTop:0, marginBottom:'1rem'}}>
            <div className="flex_left">
              <Button
                variant="contained"
                className="back"
                color="secondary"
                onClick={() => deleteBlock(items, index, setItems)}
                id="deleteVehicleBlock"
              >
                Delete
              </Button>
            </div>
          </StyledButtonGroup>
        ) : null}
    </>
  );
}

export default VehicleType;
