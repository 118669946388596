import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));


/**
 * @typedef {{name: string, value: boolean | undefined}} validate
 *
 * @param {object} props
 * @param {function} props.setValue
 * @param {string} props.id
 * @param {string} props.type
 * @param {string | number} props.value
 * @param {string} props.title
 * @param {boolean} props.noFormTag
 * @param {boolean} props.required
 * @param {function} props.classes
 */
const TextInput = (props) => {
  const classes = useStyles();
  const noFormTag = !!props.noFormTag;

  /**
   * Handles a form change
   *
   * @param {object} event React event
   * @param {object} event.target Form field
   * @param {string|number} event.target.value Form field value
   */
  const handleChange = ({ target: { value } }) => {
    props.setValue(props.id, value);
  };

  /**
   * View
   */

  const textInputContent = (
    <FormControl fullWidth className={props.classes ? props.classes : classes.root}>
      <TextField
        className={classes.number}
        value={props.value}
        label={props.title}
        type={props.type}
        onChange={handleChange}
        id={props.id}
      />

      {
        props.required && (
            <FormHelperText id="standard-helper-text" data-testid={props.id+"-standard-helper-text"}>*Required</FormHelperText>
        )
      }
    </FormControl>
  );

  return noFormTag ? (
    <div className={props.classes ? props.classes : classes.root}>{textInputContent}</div>
  ) : (
    <form className={props.classes ? props.classes : classes.root} noValidate autoComplete="off">
      {textInputContent}
    </form>
  );
};

export default TextInput;
