import AutoComplete from "../../AutoComplete";
import {foundSubDivision} from "../../FormGroups/utils";
import React from "react";

const DivisionAndSubdivision = ({
                                    divisionTitle,
                                    subDivisionTitle,
                                    divisions,
                                    subDivisions,
                                    division,
                                    subDivision,
                                    setValue,
                                    divisionReqFn,
                                    subDivisionReqFn
                                }) => {

    return (
        <>
           {divisionTitle}
            <AutoComplete
                data={divisions}
                id="division"
                title="Select division"
                setValue={setValue}
                value={division}
                required={divisionReqFn}
            />
            {subDivisions.length > 0 && (
                <>
                    {subDivisionTitle}
                    <AutoComplete
                        data={subDivisions}
                        id="sub_division"
                        title="Select subdivision"
                        setValue={setValue}
                        value={subDivision}
                        required={subDivisionReqFn}
                    />
                </>
            )}
        </>
    )
};

export default DivisionAndSubdivision;