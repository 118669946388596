import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import PrivateRoute from '../utils/hoc/PrivateRoute';
import VerifyEmail from '../pages/Authentication/VerifyEmail';
import faq from '../pages/FAQ';
import VerifyCode from '../pages/Authentication/VerifyCode';
import ApplicationForm from '../pages/ApplicationForm';
import Submit from '../pages/Submit';
import AlreadySubmitted from '../pages/AlreadySubmitted';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

function Router() {
  return (
    <MuiThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/" component={VerifyEmail} />
        <Route exact path="/authentication" component={VerifyEmail} />
        <Route exact path="/faq" component={faq} />
        <Route exact path="/verify_code" component={VerifyCode} />
        <PrivateRoute
          exact
          path="/application_form"
          component={ApplicationForm}
        />
        <PrivateRoute exact path="/submit" component={Submit} />
        <PrivateRoute exact path="/submitted" component={AlreadySubmitted} />
      </Switch>
    </MuiThemeProvider>
  );
}

export default Router;
