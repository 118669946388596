import React, {useEffect, useState} from 'react';
import ReactCodeInput from 'react-verification-code-input';

function CodeInput(props) {
  const [width, setWidth] = useState(0);
  const sendCode = (value) => {
    props.setValue(props.id, value);
  };

  useEffect(() => {
      const w = document.getElementById('form').clientWidth / props.fields;
      setWidth(w)
  },[0]);

  useEffect(() => {
      function handleResize() {
          const w = document.getElementById('form').clientWidth / props.fields;
          setWidth(w)
      }
      window.addEventListener('resize', handleResize)
  },[])

  return (
      <ReactCodeInput
        autoFocus={props.autoFocus}
        values={Array.isArray(props.value) ? props.value : [...props.value]}
        fields={props.fields}
        fieldWidth={width}
        onComplete={sendCode}
        className={'react-code-input'}
      />
  );
}

export default CodeInput;
